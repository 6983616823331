import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";

export const CondicionesContratacionEs = ({ to }) => {


    useEffect(() => {

        let timeoutId = null

        if (to) {

            timeoutId = setTimeout(() => {

                let hero = document.getElementById(`${to}`);

                hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });

            }, 300);
        }

        return () => clearTimeout(timeoutId);


    }, [to]);

    return (
        <>
            <h5 className="text-center mb-4">
                Condiciones generales de contratación productos
            </h5>

            <Accordion alwaysOpen>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1. Ámbito de aplicación y objeto
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                1.1. Objeto
                            </strong>
                        </p>
                        <p>
                            Los presentes términos y condiciones regularán la relación entre el Col·legi de l'Arquitectura Tècnica de Barcelona (Cateb), titular de esta Web, y los Clientes que compren o contraten los productos y servicios ofrecidos en www.testradon.es.

                        </p>
                        <p>
                            Estas condiciones establecen las normas de contratación de los productos y servicios disponibles en www.testradon.es, teniendo que ser leídas y aceptadas por el Cliente previamente a la contratación
                        </p>

                        <p>
                            <strong>
                                1.2. Destinatarios de nuestros productos y servicios
                            </strong>
                        </p>
                        <p>

                            Nuestros productos y servicios son de uso profesional, y están destinados a profesionales, empresas y/o agentes relacionados con las actividades de arquitectura y de construcción.

                        </p>

                        <p>
                            También hay productos para la ciudadanía, en las condiciones establecidas a cada producto.
                        </p>

                        <p>
                            En caso de que el comprador reúna las condiciones legales establecidas para tener la condición de consumidor y usuario, resultará de aplicación el Real decreto legislativo 1/2007, de 16 de noviembre, por el cual se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias. La definición de consumidor y usuario se encuentra en la cláusula 1.2. de las presentes condiciones.
                        </p>

                        <p>
                            <strong>
                                1.3. Modificación de las condiciones.
                            </strong>
                        </p>

                        <p>
                            El CATEB se reserva el derecho a modificar, total o parcialmente, estas condiciones generales, siendo de aplicación las nuevas que acuerde desde el momento de su publicación a la página. En cualquier caso, las mencionadas modificaciones no tendrán efectos retroactivos sobre los productos y servicios previamente adquiridos.
                        </p>




                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            2.- Idioma
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Toda la información de la web www.testradon.es, en las transacciones comerciales o telefónicas, atención al público y los servicios ofertados serán en catalán.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            3. Proceso de contratación
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                3.1. Alta como usuario
                            </strong>
                        </p>
                        <p>
                            Para realizar cualquier compra o contratación a nuestro sitio web el Cliente tendrá que registrarse previamente creando una cuenta de usuario.
                        </p>
                        <p>
                            Para el alta como usuario se solicitarán datos personales que se consideran necesarias y mínimas porque CATEB pueda tramitar las solicitudes de compra y/o contratación, respetando en todo momento las disposiciones establecidas en el Reglamento UE 2016/679 y en la Ley Orgánica 3/20218, de 5 de diciembre, de Protección de Datos Personales. Para mayor información sobre el tratamiento que realiza CATEB sobre tus datos personales, puedes consultar la nuestra Política de Privacidad .
                        </p>

                        <p>
                            <strong>
                                3.2. Aceptación de las condiciones
                            </strong>
                        </p>

                        <p>
                            La compra de los productos o servicios ofrecidos al web se realizará rellenando los formularios y procedimientos electrónicos previstos en el web. El proceso se iniciará con la elección del producto o servicio y el seguimiento de los pasos indicados a la web, que suponen la aceptación de las condiciones generales y particulares de la compra de los productos de la web.
                        </p>

                        <p>
                            La confirmación de la contratación de los servicios se realizará a través de la activación del botón «Acepto las condiciones de contratación», que irá acompañada de una concisa información sobre el tratamiento de los datos personales que llevará a cabo CATEB. El botón de aceptación de las condiciones aparecerá una vez el usuario haya seleccionado los servicios en los cuales esté interesado y con anterioridad al pago o confirmación de su intención de contratar.
                        </p>

                        <p>
                            El usuario tendrá que leerse las presentes condiciones antes de aceptarlas, quedando vinculado a su cumplimiento desde el momento de la aceptación.
                        </p>

                        <p>
                            Las presentes condiciones estarán disponibles para el usuario de manera permanente a nuestra página web.
                        </p>
                        <p>
                            Una vez realizada la compra, en un plazo no superior a 24 horas, el cliente recibirá un justificante de su pedido.
                        </p>
                        <p>
                            La compra de los productos y servicios implican la autorización por parte del cliente de la emisión y el envío de la factura en soporte electrónico.
                        </p>

                        <p>
                            <strong>
                                3.3. Requisitos de contratación
                            </strong>
                        </p>

                        <p>
                            Para realizar cualquier compra en nuestra web www.testradon.es será necesario que el Cliente sea mayor de 18 años.
                        </p>
                        <p>
                            La compra y contratación a nuestro sitio web requerirá la previa alta como usuario, conforme se indica en el apartado 3.1. de estas condiciones. El Cliente tendrá que introducir sus credenciales de acceso como método de identificación en las compras y contrataciones que realice. Las credenciales de acceso estarán constituidas por un documento identificativo y contraseña escogida por el Cliente.
                        </p>

                        <p>
                            Una vez realizado el pedido y confirmada la recepción de pago por parte de CATEB, el Cliente recibirá un correo electrónico donde se le confirmará la recepción del pedido, empezando en aquel momento el plazo de entrega en caso de que resultara aplicable. Si en un plazo de 24 horas no ha recibido el email de confirmación, el Cliente tendrá que ponerse en contacto inmediatamente con CATEB llamando al teléfono de contacto 93 240 20 60 o enviando un email a consultoriatecnica@cateb.cat.
                        </p>
                        <p>
                            Si durante el proceso de compra de cualquier de nuestros productos o servicios ha introducido erróneamente algún dato, se tendrá que poner en contacto inmediatamente con nosotros a través del teléfono de contacto 93 240 20 60 o enviando un email a consultoriatecnica@cateb.cat.
                        </p>

                        <p>
                            <strong>
                                3.4. Manifestaciones formales del Cliente
                            </strong>
                        </p>

                        <p>
                            Mediante la adquisición de los servicios, el Usuario y/o Cliente, declara:
                        </p>
                        <p>
                            – Que es una persona mayor de edad y con capacidad para contratar.
                        </p>
                        <p>
                            – Que ha leído, comprendido y acepta las y acepta las presentes Condiciones Generales de Contratación y el resto de normativa aplicable en la contratación de CATEB.
                        </p>


                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Información sobre los Productos y Servicios digitales
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Las características de los productos, descripción técnica y funcionalidades se encuentran a disposición de los clientes en www.testradon.es
                        </p>
                        <p>
                            CATEB se reserva el derecho a realizar modificaciones en su catálogo de productos respetando en todo momento las normas que resulten de aplicación.
                        </p>
                        <p>
                            CATEB pone a disposición del Usuario y/o Cliente los siguientes productos o servicios:
                        </p>
                        <ul>
                            <li>
                                Test Radón Básico
                            </li>
                            <li>
                                Test Radón Profesional.
                            </li>
                        </ul>

                        <p>
                            En nuestra web el Usuario y/o Cliente podrá encontrar las características y condiciones de cada producto y/o servicio en las cuales se lo informará de las modalidades de acceso, coste, duración, contenidos, requisitos técnicos o compatibilidad con otros software y más información que puede resultar de interés para el Usuario y/o Cliente.
                        </p>

                        <p>
                            Es posible que algunos de las nuestras productos requieran autenticación para utilizarlos, correspondiéndose con las credenciales del alta como usuario. Adicionalmente, algunas de nuestros productos piden la introducción otros datos personales -propios o de terceros- para prestar las funcionalidades requeridas. Usted tendrá que tener permiso de estos terceros para introducir sus datos.
                        </p>

                        <p>
                            <strong>
                                4.1. Requisitos técnicos de los productos y servicios digitales
                            </strong>
                        </p>

                        <p>
                            El Cliente tendrá que disponer de un programa informático compatible con la lectura y descarga de documentos con extensión .pdf (Adobe reader, Adobe profesional o similares), disponiendo de la información necesaria en las condiciones particulares y descripción de cada producto.
                        </p>

                        <p>
                            El Cliente será responsable de disponer de los requisitos técnicos aquí expresados, no constituyendo causa de falta de conformidad.
                        </p>

                        <p>
                            <strong>
                                4.2. Actualizaciones de los productos digitales de pago
                            </strong>
                        </p>

                        <p>
                            CATEB podrá realizar actualizaciones de conformidad de sus productos digitales, incluidas las de seguridad, para mantener la conformidad de los productos, y las de contenido, incluyendo los nuevos contenidos normativos publicados. Las actualizaciones de conformidad y de contenidos permanecerán vigentes siempre que la aplicación informática esté publicada y en funcionamiento.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Condiciones económicas
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                5.1. Precios y gastos
                            </strong>
                        </p>

                        <p>
                            El precio (Precio de Venta al Público o PVP) se encuentra disponible en la descripción de cada producto, está expresado en euros e incluye el IVA y/o cualquier otro impuesto o tasa que resultara de aplicación
                        </p>

                        <p>
                            <strong>
                                5.2. Formas de pago
                            </strong>
                        </p>

                        <p>

                            Les formas de pago permitidas son las siguientes:

                        </p>

                        <p>
                            (1) Tarjeta bancaria. El cargo se realiza en tiempo real, a través de la pasarela de pago segura Redsys y una vez se haya comprobado que los datos comunicados son correctos. Con el objetivo de dar la máxima seguridad al sistema de pago CATEB utiliza sistemas de pago seguro. En este sentido, los datos confidenciales son transmitidas directamente y de forma encriptada (SSL) a la entidad financiera correspondiente. El sistema de encriptación SSL que utilizamos (TLS 1.1 y TLS 1.2) confiere total seguridad a la transmisión de datos a través de la red. Sus datos disfrutan de total confidencialidad y protección. Los datos sobre las tarjetas de crédito no quedan registradas en ninguna base de datos nuestra. Están únicamente utilizados en el TPV (Terminal Punto de Venta) virtual de nuestra entidad financiera, a través de su Pasarela de Pago Seguro (Redsys). Las tarjetas de crédito estarán sujetos a comprobaciones y autorizaciones por parte de la entidad emisora de estas, pero si esta entidad no autorizara el pago, el contrato no quedará formalizado hasta que CATEB reciba el pago.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6. Garantía Legal
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            De conformidad con aquello dispuesto en el RDL 1/2007 de 16 de noviembre, por el cual se aprueba el "Texto Refundido de los Consumidores y Usuarios y otras leyes complementarias", el cliente, dispondrá de un periodo de dos años de garantía en los productos para manifestar su falta de conformidad, excepto cuando la falta de conformidad sea incompatible con la naturaleza del producto o la índole de la falta de conformidad.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7. Desistimiento, devoluciones e incidencias
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El cliente dispondrá de la facultad de dejación de su compra, notándolo al CATEB en el plazo de 14 días naturales des la compra, en las condiciones previstas a las condiciones particulares de cada producto, excepto en los casos previstos al "RDL 1/2007, de 16 de noviembre de la Ley General para la Defensa de los Consumidoras y Usuarios y otras leyes complementarias”. <a className="btn-link color-primary" href="/files/desistimiento/Formulari de desistiment CASTELLA rev 1 Laura.docx" target="_blank" rel="noopener noreferrer">Modelo de formulario de desistimiento.</a>
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Responsabilidades
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                8.1. Responsabilidad del Cliente y/o Usuario
                            </strong>
                        </p>

                        <p>

                            1. El Cliente tendrá que cumplir con las condiciones de contratación establecidas para cada producto.

                        </p>
                        <p>
                            2. El Cliente garantiza la veracidad y exactitud de los datos facilitados al rellenar los formularios de alta como usuario y de contratación, evitando la creación de perfiles o contrataciones que ocasionen perjuicios a CATEB como consecuencia de la incorrección de estos.
                        </p>

                        <p>
                            3. El Cliente tiene que abonar el precio indicado por avanzado utilizando las formas de pago indicadas en las presentes condiciones.
                        </p>

                        <p>
                            4. El Cliente tiene que hacer un uso diligente de los productos y servicios ofrecidos por CATEB obligándose a usar los productos y servicios ofrecidos al sitio web de forma lícita y sin contravenir la legislación vigente ni lesionar los derechos e intereses de terceras personas. Queda expresamente prohibido realizar copias de manera total o parcial de las publicaciones adquiridas, así como cualquier otra conducta que lesione los derechos de propiedad intelectual de CATEB o terceros.
                        </p>

                        <p>
                            5. Será responsable de cumplir con los requisitos mínimos técnicos de compatibilidad de los productos y servicios.
                        </p>

                        <p>
                            6. También será responsable de respetar las condiciones, forma o ejecución de los productos o servicios detallados en las condiciones particulares aplicables, si se tercia.
                        </p>

                        <p>

                            7. El Usuario también es responsable de hacer un buen uso de su perfil de Usuario, comprometiéndose a hacer un uso diligente y conservar las credenciales de acceso convenientemente para que no queden a disposición de terceros. En caso de pérdida, robo o posible acceso de un tercero no autorizado, el Cliente tendrá que comunicarlo inmediatamente a CATEB a fin de bloquear inmediatamente el perfil de usuario comprometido.

                        </p>

                        <p>
                            8. El Cliente indemnizará al CATEB por los gastos que tuviera que asumir a consecuencia de cualquier actuación la responsabilidad de la cual fuera atribuible al Cliente, incluidos los honorarios y gastos de los abogados, procuradores, peritos, tasas judiciales y cualesquiera otros procesales, incluso en el supuesto de una resolución judicial no definitiva.
                        </p>

                        <p>
                            El incumplimiento de cualquier de estas condiciones podrá dar lugar a la retirada o cancelación inmediata de los servicios por parte de CATEB sin que esto dé derecho al Usuario o Cliente a solicitar ninguna indemnización.
                        </p>

                        <p>
                            <strong>

                                8.2. Responsabilidades de CATEB
                            </strong>
                        </p>

                        <p>

                            (1) Prestar el servicio en la forma y según las características ofrecidas en  nuestro sitio web y el que dispone estas condiciones de contratación en atención a los productos o servicios contratados por el Cliente.

                        </p>

                        <p>
                            (2) Proteger la confidencialidad de los datos de sus Usuarios y/o Clientes según el que dispone este texto y resto de políticas legales aplicables.

                        </p>
                        <p>
                            (3) Cumplir con el que disponen las condiciones particulares de cada producto o servicio contratado.

                        </p>

                        <p>
                            <strong>
                                8.3. Límites a la responsabilidad de CATEB
                            </strong>
                        </p>

                        <p>

                            A continuación, se detallan los supuestos en los cuales CATEB no asumirá responsabilidad, constituyendo una lista no cerrada, por lo cual cualquier otra situación similar a las indicadas y que no esté contemplada, también será causa de exoneración de la responsabilidad de CATEB:

                        </p>

                        <p>
                            (1) Mal uso o abuso de los productos o servicios ofrecidos a nuestro sitio web, así como los posibles daños o perjuicios causados a terceros.
                        </p>

                        <p>
                            (2) De los daños o errores producidos por negligencia o mala fe del Usuario y/o Cliente.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Propiedad intelectual e industrial
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El titular de todo el software disponible y de sus actualizaciones de mejora, rendimiento o seguridad es CATEB, sus licenciatarios, empresas asociadas u otros proveedores que hayan podido proporcionar materiales para el desarrollo y diseño de los productos y servicios. De forma que CATEB y sus licenciatarios poseen todos los títulos y derechos de propiedad intelectual sobre las aplicaciones.
                        </p>

                        <p>
                            Se prohíbe expresamente, levadura de autorización por escrito y expresa de CATEB, la reproducción, transformación, distribución, comunicación pública, puesta a disposición al público, venta u otros usos diferentes a los expresamente autorizados por CATEB en las presentes condiciones, en las políticas y otros avisos publicados en su página web, así como a las condiciones particulares, si se procede, ya sea con fines comerciales o no, cuando comporten la explotación de los contenidos, productos o servicios ofrecidos CATEB las finalidades de los cuales sean diferentes a las previstas en estas condiciones. Los derechos otorgados al usuario en las presentes condiciones son otorgados únicamente por para uso personal.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            10. Atención al Cliente y Reclamaciones
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Los Clientes podrán contactar y presentar sus reclamaciones llamando al teléfono de contacto 93 240 20 60 o enviando un email a info@cateb.cat. El horario de atención al cliente será de Lunes a Viernes de 9:00 a 14:00.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            11. Hojas de Reclamación
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Se ponen hojas de reclamación a disposición del consumidor en: <a href="https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf" target="_blank" rel="noopener noreferrer">https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf</a>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                    <Accordion.Header>
                        <strong>
                            12. Legislación Aplicable y Jurisdicción competente
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Este contrato se rige por la Ley Española. Para los casos que la normativa prevea la posibilidad que las partes se sometan a un fuero, se someten a los Jueces y Tribunales de la Ciudad de Barcelona (España), con renuncia exprés de cualquier otro al cual pudiera corresponderlos.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

            <h5 className="text-center mb-4 mt-5">
                Condiciones particulares de contratación del Test Radón
            </h5>

            <Accordion defaultActiveKey={to} className="mb-4">

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1. Objeto del contrato
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El objeto de este contrato es la contratación de un servicio "en línea" entre el Cateb y los clientes de un test de medición de la concentración de Radón en espacios interiores durante el periodo de contratación del servicio.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            2. Características del producto y funcionamiento.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Con el método de medición “Test Radón” el cliente obtendrá los niveles de concentración de Radón en un espacio interior durante el periodo de exposición de los detectores que lo Cateb facilitará para llevar a cabo las mediciones.
                        </p>

                        <p>
                            El procedimiento de la recogida de los datos para la solicitud del servicio se realizará a través de una plataforma de acceso a través de www.tesradon.es, donde previamente el usuario tendrá que estar grabado para poder contratar su servicio.
                        </p>
                        <p>
                            Para poder tramitar la solicitud y contratar el servicio, el cliente será el encargado de facilitar los datos del edificio a mesurar para estimar el número de detectores mínimos necesarios colocar las mediciones siguiendo las instrucciones de colocación y puesta en marcha de los detectores.
                        </p>

                        <p>
                            NOTA: La medición se realizará mediante detectores pasivos de trazas alfa. Estos aparatos tienen dimensiones muy reducidas y autonomía durante todo el periodo de exposición, sin necesidad de fondo de alimentación (enchufes).
                        </p>

                        <p>
                            Estimado el número de detectores y hecho el pago, se enviarán los detectores por mensajería a la dirección indicada por el solicitante.
                        </p>

                        <p>
                            Una vez se reciban los detectores, habrá que descargar las instrucciones de colocación, que estarán disponibles dentro de su solicitud o en la web www.testradon.es.
                        </p>

                        <p>
                            Los detectores se mantendrán activos durante el periodo de medición contratada.
                        </p>

                        <p>
                            Finalizado el proceso, hará falta que el cliente haga el retorno de los detectores, siguiendo las instrucciones siguientes facilitadas.
                        </p>

                        <p>
                            Para que la prueba sea fiable tenéis que devolver tantos detectores como constan a la solicitud, por lo tanto hace falta que comprobáis que recibís el mismo número de detectores que hay a la solicitud. En caso contrario, tenéis que comunicar la incidencia antes de la proceder a la colocación de los detectores para realizar la medición. Se entiende que no ha habido ninguna incidencia si el usuario/cliente confirma que ha recibido los detectores
                        </p>

                        <p>
                            <strong>
                                Devolución de los detectores
                            </strong>
                        </p>

                        <p>
                            Finalizado el periodo de medición, el cliente es el encargado de gestionar y abonar los gastos de transporte del detector con el transportista de su elección.
                        </p>

                        <p>
                            La devolución se debe realizar en nuestro dentro de recepción situado en la siguiente dirección postal:
                        </p>

                        <p>
                            C/ Bon Pastor, núm. 5, planta 5ª
                        </p>

                        <p>
                            CP: 08021 Barcelona
                        </p>

                        <p>
                            <strong>
                                Instrucciones de devolución:
                            </strong>
                        </p>

                        <ul>
                            <li>
                                Para garantizar que los resultados su correctos, todos los detectores entregados, se tienen que devolver sin ningún tipo de envoltorio. En ninguno se tienen que depositar dentro de una bolsa de plástico precintada para enviarlos en el laboratorio.
                            </li>
                            <li>
                                Durante el periodo de medición, la carcasa de plástico del detector absorbe cantidades importantes de radón. Si el detector se envía envuelto y precintado en una bolsa de plástico, el aire contenido en el interior de la bolsa puede ser absorbido por el detector. En consecuencia, se puede obtener un resultado entre un 10 y un 20% diferente respecto a los niveles reales de radón. Esta diferencia es más importante cuando más corta sea la medida y menor sea el volumen de aire del interior de la bolsa de plástico.
                            </li>
                        </ul>

                        <p>
                            NOTA: Para que el resultado sea fiable, se deben retornar tantos detectores como consten en la solicitud.
                        </p>

                        <p>

                            Haga el seguimiento del estado de su solicitud hasta descargar el resultado del test una vez finalizada el proceso de análisis del laboratorio.

                        </p>

                        <p>
                            Una vez devueltos los detectores, el resultado del análisis estará disponible en pdf dentro de la solicitud del cliente a la plataforma de gestión de www.testradon.es
                        </p>

                        <p>
                            El resultado obtenido será la media de concentración de radón (Bq/m³) durante el periodo de exposición de los detectores.
                        </p>

                        <p>
                            <strong>
                                Modalidades de Test Radón
                            </strong>
                        </p>

                        <p>
                            El Test Radón se presenta en dos modalidades.
                        </p>

                        <p>
                            <strong>
                                A.- El Test Radón Básico.
                            </strong>
                        </p>

                        <p>
                            Pensado para cualquier persona que quiera conocer los niveles de Radón a los cuales está expuesto y, se puede adquirir en dos formatos:
                        </p>

                        <p>
                            <strong>Test de medición larga (recomendada)</strong>:  se podrá obtener una medida fiable de los niveles de concentración de radón en el edificio, durante el período de exposición de 3 meses.
                        </p>

                        <p>
                            <strong>Test de medición corta</strong>: para obtener una aproximación de los valores de concentración de radón en el edificio, obtenidos durante un período de exposición de entre 5-10 días.
                        </p>

                        <p>
                            <strong>
                                B.- El Test Radón Profesional-Justificación CTE
                            </strong>
                        </p>

                        <p>
                            Este producto está pensado para profesionales del sector, que como proyectista, dirección facultativa o entidad de control quieren mesurar la concentración de radón según las exigencias del CTE DB HS6.
                        </p>

                        <p>
                            Este test solo está disponible para mediciones de 3 meses, por lo tanto solo es posible la solicitud de Medición Larga.
                        </p>

                        <p>
                            Una vez seleccionado el producto se tendrán que introducir los datos que se indican a la plataforma de gestión del Test Radón para formalizar la compra del producto.
                        </p>

                        <p>
                            El servicio incluye:
                        </p>

                        <p>
                            -Acceso en cualquier momento del proceso a la plataforma de gestión de la solicitud de Test Radón.
                        </p>
                        <p>
                            -Envío de detectores (en un periodo aproximado de 48 horas). El número de detectores vendrá determinado en función de las zonas de muestreo del edificio y que se indicarán a la plataforma de gestión del Test.
                        </p>

                        <p>
                            -Instrucciones del colocación y devolución de los detectores.
                        </p>

                        <p>
                            -Información para el registro, complementación de datos y consulta del resultado del análisis. Servicio de apoyo a través de formulario de consulta y/o teléfono.
                        </p>

                        <p>
                            -Resultado del Test Radón en Pdf con el análisis de los valores de la concentración de radón mesurados, durante el periodo de exposición de los detectores, indicado por el usuario a su solicitud.
                        </p>

                        <p>
                            El retorno de los sensores a la dirección de devolución, se debe realizar finalizado el proceso de exposición para asegurar la fiabilidad de los resultados.
                        </p>

                        <p>
                            En caso contrario, el Cateb no se responsabilizará si la entidad de medida no emite los resultados.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            3. Precio.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            Los precios de los productos Test Radón están informados en la web antes de aceptar la compra y llevan el IVA incluido.

                        </p>
                        <p>

                            El envío de los detectores en las islas canarias tiene un coste adicional, el cual se indica antes de aceptar la compra.

                        </p>

                        <p>

                            En todo caso, los gastos de retorno de los detectores al Cateb por su lectura y redacción de informe, serán a cargo del cliente.

                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Proceso de compra
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            Para adquirir los productos ofrecidos en la web será requisito previo registrarse, siguiendo los pasos establecidos en la web y aceptando las condiciones generales y particulares de este contrato.
                        </p>

                        <p>

                            Una vez registrada se podrá realizar la selección del servicio que se quiere contratar hay que introducir los datos necesarios para realizar todo lo. Una vez confirmada el pago habrá iniciado la contratación del servicio.

                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Forma de pago
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            La forma de pago del Test Radón, se podrá hacer:
                        </p>

                        <p>

                            (1) Tarjeta de crédito o débito. Como modalidad de pago se ofrece pago mediante tarjeta de crédito o débito. Para efectuar el pago el cliente será dirigido a través de una conexión a la pasarela de pago donde tendrá que introducir los datos para realizar la compra.

                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6.- Término de ejecución del contrato.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            El plazo de ejecución del contrato es de 12 meses y 3 semanas desde la entrega de los detectores.
                        </p>

                        <p>

                            Una vez recibidos los detectores, el cliente dispone de 12 meses para volverlos al Cateb.
                        </p>
                        <p>
                            El Cateb enviará al cliente el resultado obtenidos de los detectores, en un plazo máximo de 3 semanas desde que reciba los detectores.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7. Atención postventa
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            Todas las dudas, consultas, información, incidencias o cualquier tipo de reclamación podrán realizarse mediante la dirección de correo electrónico consultoriatecnica@cateb.cat.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Desistimiento
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Se podrá cejar en la compra durante 14 días naturales des el momento de la compra. En todo caso, no se podrá ejercer este derecho si se ha manipulado el envoltorio de los detectores, atendido y que la medición se habrá iniciado.
                        </p>

                        <p>
                            La dejación de la compra comportará la devolución del importe abonado, sacado los gastos de devolución de los detectores. En caso de que estos no sean devueltos no se volverá ningún importe.
                        </p>

                        <p>
                            Para ejercer el derecho de desistimiento se deberá emitir un correo electrónico consultoriatecnica@cateb.cat, con el asunto "DESISTIMENTO", manifestando de forma expresa que desea desistir del contrato.
                        </p>
                        <p>
                            Una vez ejercido su derecho de devolución, CATEB le reembolsará el precio abonado y, si se procede, los gastos de envío que haya abonado, salvo que haya elegido expresamente una modalidad de entrega diferente a la menos costosa oferta por nosotros. El importe le será reembolsado en un plazo máximo de 14 días naturales desde que hayamos recibido el producto utilizando lo mismo medio que se utilizó para el pago. Si después de la recepción del producto detectamos una disminución del valor como resultado de una manipulación diferente a la necesaria para verificar sus características y funcionalidades, esta disminución no se le reembolsará.
                        </p>

                        <p>
                            Los Clientes que no reúnan la condición de consumidor y usuario, no tendrán derecho a desistir sobre ningún producto o servicio ofrecido en nuestra web.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Responsabilidades
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El cliente, manifiesta que es mayor de 18 años, tiene capacidad y reúne las condiciones definidas en cada uno de los perfiles descritos en la cláusula 2 de las condiciones particulares.
                        </p>

                        <p>
                            El cliente se obliga a realizar un uso lícito de los servicios, contravenir la legislación vigente, ni lesionar los derechos e intereses de terceras personas.
                        </p>

                        <p>
                            El cliente garantiza la veracidad y exactitud de los datos facilitados al rellenar los formularios de contratación evitando producir perjuicios al Cateb con motivo de la incorrección de estos.
                        </p>
                        <p>
                            El Cateb no tiene ninguna responsabilidad sobre la incorrecta utilización de los sensores, ni la prisa incorrecta de la medición por parte del cliente.
                        </p>

                        <p>
                            En caso de pérdida o deterioro los sensores, el cliente no tendrá derecho a pedir el retorno del importe o una compensación por no poder finalizar el objeto del contrato. Además, el cliente tendrá que abonar los gastos que el fabricante de los detectores le reclame al Cateb.
                        </p>

                        <p>
                            El incumplimiento de cualquier de las Condiciones de Contratación podrá dar lugar a la retirada o cancelación de los servicios por parte del Cateb, sin necesidad de preaviso al cliente y sin que esto dé derecho a indemnización.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10" id={to}>
                    <Accordion.Header>
                        <strong>
                            10- Protección de datos
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb) trata los datos personales que nos faciliten y aquellos que sean obtenidos de su relación con nosotros y, serán incorporados en un fichero o ficheros, responsabilidad del COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb). Sus datos serán dedicados exclusivamente a las finalidades relacionadas con la compra y la gestión de Test Radón. En este sentido, se comunica que se realizarán análisis de perfiles y de uso a partir del historial de servicios y actividades del interesado, para mejorar y dotar de valor añadido los servicios y actividades a disposición de los usuarios.
                        </p>
                        <p>
                            Los datos se conservarán durante todo el periodo en que se mantenga la relación con nosotros, así como durante el periodo de prescripción de las acciones de responsabilidad para el ejercicio y/o defensa de eventuales reclamaciones.
                        </p>

                        <p>
                            Los datos tratados están protegidas con los medios y sistemas técnicos necesarios para preservar su confidencialidad y evitar la pérdida, alteración y acceso no autorizado a los mismos, de acuerdo con los niveles de seguridad legalmente demandados.
                        </p>

                        <p>
                            Derechos de los afectados: en caso necesario, usted puede ejercer los derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición en el tratamiento de los datos, dirigiéndonos por escrito o por correo electrónico y acompañante copia del documento oficial que lo identifique, al COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb), con domicilio en la calle Bon Pastor, nº 5, Cp 08021, Barcelona, o enviado un e-mail a info@cateb.cat, indicando en el sobre o en el asunto del mensaje: Aviso legal y de privacidad. Usted también tiene derecho a formular reclamación delante de la autoridad de control y a revocar el consentimiento otorgado.
                        </p>

                        <p>
                            Otros datos de interés:
                        </p>

                        <p>
                            Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb)
                        </p>

                        <p>
                            CIF	Q0875009C
                        </p>

                        <p>
                            Dirección	C. Bon Pastor, 5. 08021 Barcelona. España
                        </p>

                        <p>
                            Contacte	Tel: 93 240 20 60; info@cateb.cat.
                        </p>

                        <p>
                            Delegado de Protección de Datos: Institut Qualitas d' Assessoria BCN, S.L.
                        </p>

                        <p>
                            Contacto del Delegado de Protección de Datos: dpd@cateb.cat.
                        </p>

                        <p>
                            Legitimación: la base legal es el consentimiento del interesado.
                        </p>

                        <p>
                            Destinatarios: Sus datos podrán ser comunicadas a los laboratorios encargados de detectar los niveles de radón y redacción del informe correspondiente, así como podrán ser comunicadas a terceros para el cumplimiento de las obligaciones legales y/o contractuales que puedan existir, por ejemplo, en la Administración Pública, colegio profesional o consejo de colegios profesionales que puedan ser competentes en cada caso. En este sentido, lo informamos que, en el caso de facilitar datos bancarios para el pago de los productos adquiridos, se procederá a su comunicación a nuestra entidad financiera al exclusivo efecto de gestionar su cobro.
                        </p>

                        <p>
                            <strong>DATOS DE TERCERAS PERSONAS.</strong>
                        </p>

                        <p>
                            Si usted nos facilita datos de terceros, o nos las facilita en el futuro, con carácter previo, usted tendrá que haber informado y obtenido el consentimiento del tercero, en relación con todo el expuesto anteriormente.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </>
    )
};
