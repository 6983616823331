export default class Solicitud {
  constructor(data) {
        this.tipo_solicitud = data?.tipo_solicitud || '';
        this.id = data?.id || '';
        this.estado = data?.estado || 'radon_estado_borrador';
        this.tipo_intervencion = data?.tipo_intervencion || '';
        this.tipo_medicio =  data?.tipo_medicio || '';
        this.tipo_persona = data?.tipo_persona || '';
        this.tipo_ampliacion = data?.tipo_ampliacion || '';
        this.detalle_zonas = data?.detalle_zonas || []; 
        this.motivo_medicio = data?.motivo_medicio || ''; 
        this.nif_cif = data?.nif_cif || '';
        this.razon_social = data?.razon_social || '';
        this.nombre = data?.nombre || '';
        this.apellido = data?.apellido || '';
        this.observacion = data?.observacion || '';
        this.edificio_direccion = data?.edificio?.direccion || '';
        this.edificio_numero = data?.edificio?.numero || '';
        this.edificio_piso = data?.edificio?.piso || '';
        this.edificio_puerta = data?.edificio?.puerta || '';
        this.edificio_codigo_postal = data?.edificio?.codigo_postal || '';
        this.edificio_municipio_id = data?.edificio?.municipio_id || '';
        this.edificio_zona = data?.edificio?.zona || '';
        this.edificio_anio_construccion = data?.edificio?.anio_construccion || '';
        this.edificio_reforma_previas = data?.edificio?.reforma_previas || '';
        this.edificio_reforma_indica = data?.edificio?.reforma_indica || '';
        this.edificio_tipo_edificio = data?.edificio?.tipo_edificio || '';
        this.edificio_superficie_util = data?.edificio?.superficie_util || '';
        this.edificio_provincia_id = data?.edificio?.provincia_id || '';
        this.edificio_otros = data?.edificio?.otros || '';

        this.direccion_envio_direccion = data?.direccion_envio?.direccion || '';
        this.direccion_envio_numero = data?.direccion_envio?.numero || '';
        this.direccion_envio_piso = data?.direccion_envio?.piso || '';
        this.direccion_envio_puerta = data?.direccion_envio?.puerta || '';
        this.direccion_envio_codigo_postal = data?.direccion_envio?.codigo_postal || '';
        this.direccion_envio_provincia = data?.direccion_envio?.provincia || '';
        this.direccion_envio_localidad = data?.direccion_envio?.localidad || '';
        this.direccion_envio_provincia_id = data?.direccion_envio?.provincia_id || '';
        this.direccion_envio_municipio_id = data?.direccion_envio?.municipio_id || '';
        this.direccion_envio_atencion_de = data?.direccion_envio?.observacion || '';

        this.acreditador_nif = data?.acreditador?.nif || '';
        this.acreditador_nombre = data?.acreditador?.nombre || '';
        this.acreditador_apellido = data?.acreditador?.apellido || '';
        this.acreditador_email = data?.acreditador?.email || '';
        this.acreditador_telefono = data?.acreditador?.telefono || '';
        this.acreditador_actua_como = data?.acreditador?.actua_como || '';
        this.acreditador_titulo = data?.acreditador?.titulo || '';
        this.acreditador_numero_colegiado = data?.acreditador?.numero_colegiado || '';
        this.acreditador_entidad = data?.acreditador?.entidad || '';
  }
}