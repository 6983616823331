import React, { useEffect, useState } from 'react'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader'
import { Button, Form, Modal } from 'react-bootstrap';
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { InputText } from '../../components/Inputs/InputText';
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { InputSelect } from '../../components/Inputs/InputSelect';
import { ModalStatus } from '../../components/ModalStatus/ModalStatus';
import { ModalShowStatus } from '../../components/ModalStatus/ModalShowStatus';
import { Footer } from '../../components/Footer/Footer';
import { getSelectByKeys } from '../../helpers/helpers';
import { TipoViasServices } from '../../controllers/services/statics.service';
import { useLang } from 'language';

export const MisDatos = () => {

    const locale = useLang()

    const [fetchMisDatos, fetchMisDatosData] = useFetch('GET', `/clientes/usuarios/me`)

    const [formMisDatosEditar, handleInputChangeMisDatosEditar, handleFormChangeMisDatosEditar] = useForm({
        nombre: '',
        apellidos: '',
        telefono: '',
        documento: '',
        email: '',
        password: '',
        // password_confirmation: '',
        // direccion: '',
        provincia: '',
        poblacion: '',
        codigo_postal: '',
        pais: '',
        usuario_guardado_en_crm: ''
    })

    useEffect(() => {

        fetchMisDatos()

    }, [])

    useEffect(() => {
        if (fetchMisDatosData.data) {

            const misDatos = fetchMisDatosData.data.data
            handleFormChangeMisDatosEditar({
                nombre: misDatos.usuario_nombre || '',
                apellidos: misDatos.usuario_apellidos || '',
                telefono: misDatos.usuario_telefono || '',
                documento: misDatos.usuario_documento || '',
                email: misDatos.usuario_email || '',
                // password: '',
                // password_confirmation: '',
                provincia: misDatos.datos_fact?.provincia || '',
                poblacion: misDatos.datos_fact?.poblacion || '',
                codigo_postal: misDatos.datos_fact?.codigo_postal || '',
                pais: misDatos.pais,
                guardado_en_crm: misDatos.usuario_guardado_en_crm,

                calle: misDatos.datos_fact?.calle || '',
                comarca: misDatos.datos_fact?.comarca || '',
                puerta: misDatos.datos_fact?.puerta || '',
                tipovia: misDatos.datos_fact?.tipovia || '',
                piso: misDatos.datos_fact?.piso || '',
                numero: misDatos.datos_fact?.numero || '',
                escalera: misDatos.datos_fact?.escalera || '',
                email_crm: misDatos.datos_fact?.email || '',
            })
        }
    }, [fetchMisDatosData])

    const [valuesValidMisDatosEditar, handleValidMisDatosEditar] = useValidator({
        nombre: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_NOMBRE')]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_APELLIDOS')]
            }
        },
        telefono: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_TELEFONO')]
            }
        },
        documento: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DOCUMENTO')]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_EMAIL')]
            }
        },
        // password: {
        //     required: {
        //         isOk: true,
        //         msgError: [locale('V_OBLIGATORIO_CONTRASENIA')]
        //     }
        // },
        // password_confirmation: {
        //     required: {
        //         isOk: true,
        //         msgError: [locale('V_OBLIGATORIO_CONTRASENIA')]
        //     }
        // },
        direccion: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DIRECCION')]
            }
        },
        provincia: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_PROVINCIA')]
            }
        },
        poblacion: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_POBLACION')]
            }
        },
        codigo_postal: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CODIGO_POSTAL')]
            }
        },
        pais: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_PAIS')]
            }
        }
    })

    const [fetchEditarDatos, fetchEditarDatosData] = useFetch('PUT', `/clientes/usuarios/me`)

    const handleSubmitEditarDatos = (e) => {

        e.preventDefault()

        if (handleValidMisDatosEditar(formMisDatosEditar)) {
            fetchEditarDatos({
                body: {
                    ...formMisDatosEditar
                }
            })
        }
    }

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)
    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formMisDatosEditar.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    codigo_postal: formMisDatosEditar.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangeMisDatosEditar({
                ...formMisDatosEditar,
                provincia: '',
                poblacion: '',
                pais: '',
            })
        }

    }, [formMisDatosEditar.codigo_postal])

    useEffect(() => {

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data.status === 'Success') {

                const poblaciones = fetchPoblacionesData.data.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeMisDatosEditar({
                    ...formMisDatosEditar,
                    provincia: '',
                    poblacion: '',
                    pais: ''
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formMisDatosEditar.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data]

            const resp = poblacionesTemp.find(item => item.poblacion === formMisDatosEditar.poblacion)

            console.log('resp', resp);

            handleFormChangeMisDatosEditar({
                ...formMisDatosEditar,
                provincia: resp?.provincia,
                poblacion: resp?.poblacion,
                pais: resp?.pais,
            })
        }

    }, [formMisDatosEditar.poblacion, fetchPoblacionesData.data])

    const staticTipoViasServices = TipoViasServices.GET()

    useEffect(() => {
      console.log(formMisDatosEditar);
    }, [formMisDatosEditar])
    

    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column mx-auto mt-4 mb-5 mx-4 col-11 col-xl-9">
                <h5 className='mt-2 mb-2 text-center mb-0'>{locale('DATOS_PERSONALES')}</h5>
                {
                    fetchMisDatosData.loading ? <Loader /> : <>
                        <div className="row">
                            {/* <div className="col-md-8">
                                <div className="d-flex flex-wrap">
                                    <h5 className='mx-3 my-2'>{formMisDatos.usuario_nombre}</h5>
                                    <h5 className='mx-3 my-2'>{formMisDatos.usuario_apellidos}</h5>
                                    <h5 className='mx-3 my-2'>{formMisDatos.usuario_documento}</h5>
                                    <h5 className='mx-3 my-2'>{formMisDatos.usuario_num_colegiado}</h5>
                                </div>
                                <div className="d-flex flex-wrap">
                                    <h5 className='fw-normal mx-3 my-2 h6'>{formMisDatos.usuario_email}</h5>
                                    <h5 className='fw-normal mx-3 my-2 h6'>{formMisDatos.usuario_telefono}</h5>
                                </div>
                                <div className="d-flex flex-wrap">
                                    <h5 className='fw-normal mx-3 my-2 h6'>{formMisDatos.datos_fact?.direccion}</h5>
                                    <h5 className='fw-normal mx-3 my-2 h6'>{formMisDatos.datos_fact?.codigo_postal}</h5>
                                    <h5 className='fw-normal mx-3 my-2 h6'>{formMisDatos.datos_fact?.poblacion}</h5>
                                </div>
                            </div> */}
                            <div className="col-12 text-center mb-3">
                                <h5 className='mx-3 my-2 fw-normal'>{formMisDatosEditar.documento}</h5>
                            </div>
                            <div className="col-12">
                                <Form className='row'>
                                    <Form.Group className="mb-3 col-lg-4">
                                        <InputText
                                            label={locale('NOMBRE')}
                                            className=''
                                            name='nombre'
                                            value={formMisDatosEditar.nombre}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="nombre"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-lg-4">
                                        <InputText
                                            label={locale('APELLIDOS')}
                                            className=''
                                            name='apellidos'
                                            value={formMisDatosEditar.apellidos}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="apellidos"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-md-6 col-lg-4">
                                        <InputText
                                            label={locale('MOVIL')}
                                            className=''
                                            name='telefono'
                                            value={formMisDatosEditar.telefono}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="telefono"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-lg-5">
                                        <InputText
                                            label='E-mail'
                                            className=''
                                            name='email'
                                            value={formMisDatosEditar.email_crm || formMisDatosEditar.email || ''}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="email"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <div className="col-12 mt-3">
                                        <h5 className='mb-4'>{locale('DIRECCION')}:</h5>
                                    </div>

                                    {/* <Form.Group className="mb-3 col-lg-7">
                                    <InputText
                                        label='Adreça*'
                                        className=''
                                        name='direccion'
                                        value={formMisDatosEditar.direccion}
                                        onChange={handleInputChangeMisDatosEditar}
                                        placeholder="direccion"
                                        validation={valuesValidMisDatosEditar}
                                        fetchData={fetchEditarDatosData}
                                    />
                                </Form.Group> */}

                                    <Form.Group className="mb-4 col-md-6 col-lg-3">
                                        {/* <InputText
                                        label='Tipus Via*'
                                        className=''
                                        name='tipovia'
                                        value={formMisDatosEditar.tipovia}
                                        onChange={handleInputChangeMisDatosEditar}
                                        placeholder="tipovia"
                                        // validation={valuesValidMisDatosEditar}
                                        fetchData={fetchEditarDatosData}
                                    /> */}
                                        <InputSelect
                                            label={locale('TIPO_VIA') + ': *'}
                                            className=''
                                            name='tipovia'
                                            values={getSelectByKeys(staticTipoViasServices)}
                                            value={formMisDatosEditar.tipovia}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="tipovia"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-lg-7">
                                        <InputText
                                            label={locale('DIRECCION') + ': *'}
                                            className=''
                                            name='calle'
                                            value={formMisDatosEditar.calle}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="calle"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <div className="col-12"></div>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label={locale('NUMERO') + ': *'}
                                            className=''
                                            name='numero'
                                            value={formMisDatosEditar.numero}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="numero"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label={locale('PISO') + ':'}
                                            className=''
                                            name='piso'
                                            value={formMisDatosEditar.piso}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="piso"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label={locale('PUERTA') + ':'}
                                            className=''
                                            name='puerta'
                                            value={formMisDatosEditar.puerta}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="puerta"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4 col-md-6 col-lg-2">
                                        <InputText
                                            label={locale('ESCALERA') + ':'}
                                            className=''
                                            name='escalera'
                                            value={formMisDatosEditar.escalera}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="escalera"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    {/* <div className="col-12"></div>

                                    <Form.Group className="mb-3 col-lg-7">
                                        <InputText
                                            label='Adreça'
                                            className=''
                                            name='calle'
                                            value={formMisDatosEditar.calle}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="calle"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group> */}

                                    <div className="col-12"></div>

                                    <Form.Group className="mb-3 col-sm-6 col-lg-3">
                                        <InputText
                                            label={locale('CODIGO_POSTAL') + ': *'}
                                            className=''
                                            name='codigo_postal'
                                            value={formMisDatosEditar.codigo_postal}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="codigo_postal"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                        <InputSelect
                                            label={locale('POBLACION') + ': *'}
                                            className=''
                                            name='poblacion'
                                            values={poblaciones}
                                            value={formMisDatosEditar.poblacion}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="poblacion"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                            disabled={!fetchPoblacionesData.data || formMisDatosEditar.codigo_postal?.length !== 5 || formMisDatosEditar.guardado_en_crm}
                                        />
                                    </Form.Group>

                                    <div className="col-12"></div>

                                    {/* <Form.Group className="mb-3 col-lg-4">
                                        <InputText
                                            label='Contrasenya'
                                            className=''
                                            name='password'
                                            type='password'
                                            value={formMisDatosEditar.password}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="password"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-lg-4">
                                        <InputText
                                            label='Confirmar contrasenya'
                                            className=''
                                            name='password_confirmation'
                                            type='password'
                                            value={formMisDatosEditar.password_confirmation}
                                            onChange={handleInputChangeMisDatosEditar}
                                            placeholder="password_confirmation"
                                            validation={valuesValidMisDatosEditar}
                                            fetchData={fetchEditarDatosData}
                                        />
                                    </Form.Group> */}

                                    <div className="text-center">
                                        <MsgInputServer obj={fetchEditarDatosData.data} />
                                    </div>

                                </Form>
                            </div>
                            <div className="border-top pt-3 mt-3 "></div>
                            {
                                formMisDatosEditar.guardado_en_crm ? <div className='text-end'>
                                    <small>{locale('P_MODIFICAR_DATOS_CONTACTO_CATEB')}</small>
                                </div> : <div className="col-12 text-end">
                                    <ButtonSpinner
                                        variant="primary" type="submit" className="ms-2"
                                        handleSubmit={handleSubmitEditarDatos} fetchDataLoading={[fetchEditarDatosData.loading]}
                                        value={locale('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                    />
                                </div>
                            }
                        </div>
                    </>
                }


            </div>
            {/* <div className="mt-auto">
                <Footer />
            </div> */}

            <ModalShowStatus fechData={fetchEditarDatosData} />
        </>
    )
}