import React, { useReducer, useEffect } from 'react'

import { AppRouter } from './routers/AppRouter'
import { AuthContext } from './auth/AuthContext'
import { authReducer } from './auth/authReducer'

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.dark.min.css'; // Include dark 
import './App.css';
import jwt from 'jwt-decode';
import { Redirect } from 'react-router-dom'

import { types } from './types/types';
// import ModalProteccionDatos from './components/Modal/ModalProteccionDatos';
// import { useFetch } from './hooks/useFetch';
import { ProteccionDatos } from './components/ProteccionDatos/ProteccionDatos';
import { findGetParameter, removeParam } from 'helpers/helpers';

const init = () => {

    const initialLanguage = String(findGetParameter('locale') || '').toUpperCase() || sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE') || process.env.REACT_APP_LANGUAGE_DEFAULT

    window.history.pushState({}, '', removeParam("locale", window.location.href))

    const obj = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))

    if (obj) {
        obj.lang = initialLanguage
    }

    return obj || {
        logged: false,
        lang: initialLanguage
    };
}

export const App = () => {

    const [user, dispatch] = useReducer(authReducer, {}, init);

    useEffect(() => {

        sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE, JSON.stringify(user));

        sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE', user.lang)

        if (user.token) {
            const exp = jwt(user.token).exp
            const hoy = Date.now()

            console.log(new Date(hoy), "--", new Date(exp * 1000))

            if (hoy >= exp * 1000) {
                console.log('token expirado')
                dispatch({
                    type: types.logout,
                    lang: user.lang
                });
                return <Redirect to='/login' />
            }
        }

    }, [user])

    console.log(process.env.REACT_APP_ENVIRONMENT)

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AppRouter />
            {user.token && <ProteccionDatos />}
        </AuthContext.Provider>
    )
}
