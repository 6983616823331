import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useLang } from "../../../language";
import './DropdownLanguage.css'

export const DropdownLanguage = ({ drop = 'down', className = '' }) => {

    const { user, dispatch } = useContext(AuthContext);

    const Lang = useLang()

    // Cambiar idioma

    const handdleChangeLanguage = (lang) => {

        dispatch({
            type: types.changeLanguage,
            payload: {
                ...user,
                lang
            }
        })

        if (user?.logged && user?.lang) {

            handleChangeIdioma(user.token, user.lang)
        }
    }

    const handleChangeIdioma = async (token, lang) => {

        const response = await fetch(process.env.REACT_APP_API_URL + "/clientes/usuarios/cambiar_idioma", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "locale": String(lang).toLowerCase()
            })
        });

        await response.json();

        window.location.reload()
    }

    return (
        <div className={`DropdownLanguage ${className}`}>
            <Dropdown drop={drop}>
                <Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-basic">
                    <FontAwesomeIcon icon={faGlobe} className='me-2' />
                    <span className='me-2'>{user.lang}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handdleChangeLanguage('ES')}>{Lang('ESPANIOL')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => handdleChangeLanguage('CA')}>{Lang('CATALAN')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
};
