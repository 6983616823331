import React, { useContext, useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

// import { LoginScreen } from '../components/login/LoginScreen';
import { Home } from 'pages/Home/Home'
import { DashboardRoutes } from './DashboardRoutes';
import { AvisLegal } from '../pages/AvisLegal/AvisLegal';
import { PoliticaPrivacitat } from '../pages/PoliticaPrivacitat/PoliticaPrivacitat';
import { PoliticaCookies } from '../pages/PoliticaCookies/PoliticaCookies';
import { CondicionesContratacion } from 'pages/CondicionesContratacion/CondicionesContratacion';
import { findGetParameter } from 'helpers/helpers';
import { Faqs } from 'pages/Faqs/Faqs';

export const AppRouter = () => {

    const { user } = useContext(AuthContext);

    console.log('AppRoute')

    const [openModal, setopenModal] = useState(String(findGetParameter('login') || false) === 'true');

    return (
        <Router
            basename={process.env.REACT_APP_SUBDOMAIN}
        // basename={"/"}
        >
            <div>
                <Switch>
                    <PublicRoute
                        exact
                        path="/inici"
                        component={() => <Home openModal={openModal} />}
                        isAuthenticated={user.logged}
                    />

                    {/*
                    <PublicRoute
                        exact
                        path="/login"
                        component={Login}
                        isAuthenticated={user.logged}
                    />
                    */}

                    <Route
                        exact
                        path="/aviso-legal"
                        component={AvisLegal}
                    />

                    <Route
                        exact
                        path="/condiciones-contratacion"
                        component={CondicionesContratacion}
                    />

                    <Route
                        exact
                        path="/faqs"
                        component={Faqs}
                    />

                    <PrivateRoute
                        path="/"
                        // render={() => <DashboardRoutes authed={true} />}
                        component={DashboardRoutes}
                        isAuthenticated={user.logged}
                    />
                </Switch>
            </div>
        </Router>
    )
}
