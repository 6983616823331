import React, { useEffect, useState } from 'react'
import { useFetch } from '../../hooks/useFetch'
import ModalProteccionDatos from '../Modal/ModalProteccionDatos'

export const ProteccionDatos = () => {

    //const [fetchUsuario, fetchUsuarioData] = useFetch('GET', `/clientes/usuarios/me`)

    // Comprobar proteccion de datos

    const [showModalProteccionDatos, setShowModalProteccionDatos] = useState(false)

    const handleCloseModalProteccionDatos = () => setShowModalProteccionDatos(false)
    const handleShowModalProteccionDatos = () => setShowModalProteccionDatos(true)

    const [fetchAceptarCondiciones, fetchAceptarCondicionesData] = useFetch('POST', `/clientes/usuarios/me/aceptar_condiciones`)

    /*useEffect(() => {
        console.log('fetchUsuario unico')
        fetchUsuario()

    }, [])

    useEffect(() => {
        if (fetchUsuarioData.data) {
            const userColegiado = fetchUsuarioData.data?.data?.usuario_condiciones_aceptadas || JSON.parse(sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_PROTECCION_DATOS'))
            setShowModalProteccionDatos(!userColegiado)
        }
    }, [fetchUsuarioData])
    */

    const submitProteccionDatos = () => {
        fetchAceptarCondiciones({
            body: {
                manifesto: true
            }
        })
    }

    useEffect(() => {

        if (fetchAceptarCondicionesData.data?.status === 'Success') {
            sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_PROTECCION_DATOS', true);
            handleCloseModalProteccionDatos()
        }

    }, [fetchAceptarCondicionesData])


    return (
        <ModalProteccionDatos
            showModalProteccionDatos={showModalProteccionDatos}
            setShowModalProteccionDatos={setShowModalProteccionDatos}
            handleCloseModalProteccionDatos={handleCloseModalProteccionDatos}
            handleSubmit={submitProteccionDatos}
            fetchDataLoading={fetchAceptarCondicionesData.loading}
            fixed
        />
    )
}
