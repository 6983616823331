import React from "react";
import { Accordion } from "react-bootstrap";

export const FaqsCa = () => {
    return (
        <>
            <h5 className="text-center mb-4">
                FAQ’S
            </h5>
            <Accordion alwaysOpen className="mb-5">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            Què cal fer abans de sol·licitar el Test Radó?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Cal llegir atentament les condicions de cada servei per saber quina sol·licitud necessitaré fer segons les meves necessitats.
                        </p>
                        <p>
                            Cal disposar de certes dades de l’edifici on vull mesurar:
                        </p>
                        <p>
                            •	Adreça i propietari
                        </p>
                        <p>
                            •	Superfície útil i alçada de cadascuna de les plantes.
                        </p>
                        <p>
                            •	L’adreça on es vol rebre els detectors.
                        </p>
                        <p>
                            •	Recomanable: any de construcció i informació de si hi ha hagut obres o reformes prèvies.
                        </p>
                        <p>
                            •	En el cas del Test Professional, s’ha d’identificar la persona que defineix les zones de mostreig i identificar, en cas que sigui un mesurament posterior a una intervenció, identificar les solucions constructives implantades per fer front el radó segons exigències CTE DB HS6.
                        </p>
                        <p>
                            Per a poder iniciar el procés de sol·licitud, és necessari registrar-se a Test Radó per a poder realitzar qualsevol de les accions dins el seu perfil d’usuari.
                        </p>
                        <p>
                            Es recomana, evitar mesuraments de curta durada o inferiors a 1 any, durant el període estival (juny-setembre) i/o en tot cas, tornar a repetir l’assaig durant el període d’hivern.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            Com puc saber quants detectors demanar per fer el Test Radó?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            A mesura que vagis omplint les dades de l’edifici, el programa et calcularà el nombre de detectors necessaris. El mínim serà sempre de 2 detectors.
                        </p>
                        <p>
                            Finalitzada la fase de determinar el nombre de detectors, el sol·licitant, pot escollir afegir detectors de forma addicional. Aquests detectors addicionals tenen un cost de 30 euros/detector.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            Que inclou el preu de la meva sol·licitud?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El servei de test radó s’ofereix a preus per al públic a partir de 115 euros (amb iva) i pels conveniats a partir de 100 euros (amb iva). Aquest preu s’ha calculat amb el servei mínim que inclou:
                        </p>
                        <p>
                            •	Mesurament llarg (mínim 3 mesos)
                        </p>
                        <p>
                            •	2 detectors
                        </p>

                        <p>
                            •	Enviament dels detectors
                        </p>
                        <p>
                            •	Accés a la plataforma de gestió del Test Radó
                        </p>
                        <p>

                            •	Informació i atenció a l’usuari en la gestió i el procés del Test Radó
                        </p>

                        <p>
                            •	Resultat del Test
                        </p>

                        <p>

                            •	Accés a la borsa de tècnics dels col·legis professionals de l’arquitectura tècnica perquè el puguin assessorar en la fase de mostreig i/o anàlisis de resultats per a la mitigació de radó.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            Que NO inclou el preu de la meva sol·licitud?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            •	Retorn dels detectors al centre de recepció (pot consultar la informació de devolució dels detectors).
                        </p>
                        <p>
                            •	Despeses generades per al desistiment, fora del termini i les condicions de la compra del servei.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            Com es fa el pagament del Test Radó?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El pagament de la sol·licitud de Test Radó, només es pot fer amb targeta bancària.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            En quant de temps rebré els detectors?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El període estimat és de 2-5 dies des que es fa el pagament.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            Quin és el procediment a seguir un cop rebi els detectors?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Cal entrar al perfil d’usuari i:
                        </p>
                        <p>
                            •	Descarregar les instruccions d’ús i col·locació.
                        </p>
                        <p>
                            •	Cal indicar de forma expressa que s’han rebut els detectors, en nombre exacte al qual consta a la seva sol·licitud. Si hi ha qualsevol incidència en la recepció dels detectors i no s’informa de forma expressa, el Cateb no es responsabilitza de la no emissió dels resultats del Test.
                        </p>
                        <p>
                            •	Finalitzat el mesurament i abans d’enviar els detectors, ompli totes les dades pendents de la sol·licitud i marqui l’opció “Enviar al laboratori”.
                        </p>
                        <p>
                            NOTA: Recordi que un cop es treguin els detectors del plàstic que els conté, haurà començat el mesurament.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            On haig de retornar els detectors un cop finalitzi el mesurament?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Finalitzat el període de mesurament, el client és l'encarregat de gestionar el retorn dels detectors al nostre centre de recepció. Pot fer-ho presencialment i/o gestionar el retorn amb el transportista de la seva elecció.
                        </p>
                        <p>
                            La devolució s'ha de fer al nostre centre de recepció situat a la següent adreça postal:
                        </p>
                        <p>
                            <strong>
                                C/Bon Pastor, núm. 5, planta 5ª
                            </strong>
                        </p>
                        <p>
                            <strong>
                                08021 Barcelona
                            </strong>
                        </p>
                        <p>
                            L'horari habitual del Cateb és de dilluns a dijous de 9:00h a 17:00h i divendres de 9:00h. a 14:30h.
                        </p>
                        <p>
                            Tots el detectors rebuts en la seva sol·licitud han de retornar-se en un mateix sobre o caixa. En aquest sobre o caixa, s'han d'indicar les següents dades: ID de la sol·licitud i les dades del sol·licitant (nom i cognoms/empresa).
                        </p>
                        <p>
                            Mai embolcar els detectors directament dins d'una bossa de plàstic precintada.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            Com obtindré els resultats?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Podrà fer el seguiment de l’estat de la seva sol·licitud fins a descarregar el resultat del test un cop finalitzat el procés d’anàlisis del laboratori.
                        </p>
                        <p>
                            El resultat de l’anàlisi estarà disponible en PDF dins de la sol·licitud del client a la plataforma de gestió de <a href="https://www.testrado.cat" className="color-primary" target="_blank" rel="noopener noreferrer">www.testrado.cat</a>
                        </p>
                        <p>
                            El resultat obtingut serà la mitja de concentració de radó (Bq/m<sup>3</sup>) durant el període d’exposició dels detectors.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            Què haig de fer sí els resultats del mesurament són elevats?
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            S’ha de tenir en compte que els nivells de radó varien en funció del dia, hàbits habituals dels usuaris, estació de l’any, per això es recomana realitzar en la mesura del possible, mesuraments en diferents períodes de l’any per obtenir un resultat del nivell mitjà anual.
                        </p>
                        <p>
                            Un nivell de referència (300 Bq/m<sup>3</sup>) no es tracta d’un límit no permès, sinó d’un valor que es recomana no superar, amb la finalitat de facilitar la supervisió i el control de l’exposició de la població en el seu conjunt. No existeix un nivell de radó per sota del qual no hi hagi un risc associat de contraure càncer de pulmó, es recomana als usuaris de l’edifici les següents mesures en funció del resultat obtingut.
                        </p>
                        <p>
                            En cas d’obtenir resultats que superin aquest nivell de referència, es recomana contactar amb un professional (arquitecte tècnic) perquè l’aconselli sobre les mesures de mitigació es poden prendre per a rebaixar el nivell mitjà de concentració de radó.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

        </>
    )
};
