import React, { useContext, useEffect } from 'react';

import { NavTopLegal } from '../../components/NavTopLegal/NavTopLegal';
import { AuthContext } from '../../auth/AuthContext';
import { CondicionesContratacionEs } from './CondicionesContratacionEs';
import { CondicionesContratacionCa } from './CondicionesContratacionCa';
import './CondicionesContratacion.css'

export const CondicionesContratacion = () => {


    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    useEffect(() => {
      
        window.scrollTo(0, 0)
        
    }, []);

    return (
        <div className="App bg-opacity-primary">
            <section className="App-header">
                <NavTopLegal />
                <section id="Main-legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                {
                                    lang === 'ES' && <CondicionesContratacionEs />
                                }

                                {
                                    lang === 'CA' && <CondicionesContratacionCa />
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
