import { useLang } from 'language';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ModalShowStatus = ({fechData}) => {

    const locale = useLang()

    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState({});

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    useEffect(() => {

        if (fechData.data?.status && fechData.data?.message) {
            setStatus({
                status: fechData.data.status, 
                message: fechData.data.message
            })
            handleShowStatus()
        }

    }, [fechData])

    return (
        <div>
            <Modal show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>{status.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    { status.message }
                </Modal.Body>
                <Modal.Footer className='border-0 '>
                    <Button variant="default" onClick={handleCloseStatus}>
                    {locale('SALIR')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}