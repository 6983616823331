import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useLang } from 'language'
import { useFetch } from 'hooks/useFetch'
import { InputSelect } from 'components/Inputs/InputSelect'
import { InputText } from 'components/Inputs/InputText'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useForm } from 'hooks/useForm'
import { useValidator } from 'hooks/useValidator'
import { InputTextNormal } from 'components/Inputs/InputTextNormal'
import { Loader } from 'components/Loader/Loader'
import {InputCheck} from 'components/Inputs/InputCheck.jsx'


export const ModalSensores = ({ data, handleClose, reloadSensor}) => {

    const Lang = useLang();

    const [fetchSensor, fetchSensorData] = useFetch('GET', `/clientes/solicitudes_radon_sensores`)
    const [fetchPlantas, fetchPlantasData] = useFetch('GET', `/clientes/solicitudes_radon`)
    const [fetchEditarSensor, fetchEditarSensorData] = useFetch('PATCH', `/clientes/solicitudes_radon_sensores`)

    const [formSensor, handleInputChangeSensor, formSensorData, resetFormSensor] = useForm({
        numero_detector: '',
        fecha_desde: '',
        fecha_hasta: '',
        nota: '',
        planta: '',
        no_utilizado: '',
        solicitud_radon_planta_id: ''
    })

    const [valuesValidSensor, handleVerifiedValuesSensor] = useValidator({
        numero_detector: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_NUMERO_DETECTOR')]
            }
        },
        /*fecha_desde: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        },
        fecha_hasta: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        },
        nota: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        },*/
        solicitud_radon_planta_id: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        }
    })

    useEffect(() => {
        if (data?.id) {
            fetchSensor({
                id: data?.id
            })
            fetchPlantas({
                id: data?.solicitud_id+'/obtener_plantas'
            })
        }
        resetFormSensor();

    }, [data.value])

     useEffect(() => {
        if (fetchSensorData.data?.data) {
            const sensor = fetchSensorData.data?.data
            formSensorData({...sensor})
        }
    }, [fetchSensorData.data])


    const handleSubmitSensor = (e)  => {
        e.preventDefault()
        if (handleVerifiedValuesSensor(formSensor)) {
            fetchEditarSensor({
                id: data.id+'/update',
                body: {
                    ...formSensor
                }
            })
        }
    }

    useEffect(() => {
        if(fetchEditarSensorData.data?.status === 'Success'){
            reloadSensor();
        }
    },[fetchEditarSensorData.data])

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='lg'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>
                       {Lang('TEST_RADON_SENSOR_FORM_TITULO')}
                    </Modal.Title>
                </Modal.Header>
                {data?.id && fetchSensorData.loading ? <Loader /> : <>
                <Modal.Body>
                    <div className="container">
                        <Form onSubmit={handleSubmitSensor} className='row'>
                           
                           <Form.Group className="col-12 col-md-6 col-lg-6 mb-4"> 
                            <InputSelect
                                label={Lang('TEST_RADON_SENSOR_FORM_PLANTAS')}
                                className=''
                                name='solicitud_radon_planta_id'
                                values={
                                fetchPlantasData.data?.data?.map((item, index) => {
                                      return {
                                         id: item.id,
                                         nombre: item.nombre
                                      }
                                  })
                                }
                                value={formSensor.solicitud_radon_planta_id}
                                onChange={handleInputChangeSensor}
                                placeholder="solicitud_radon_planta_id"
                                fetchData={fetchEditarSensorData}
                                validation={valuesValidSensor}
                              />
                            </Form.Group>  

                            <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                <InputText
                                    label={Lang('TEST_RADON_SENSOR_FORM_TIPO_PLANTA')}
                                    className=''
                                    name='tipo_planta_nombre'
                                    value={formSensor?.planta?.tipo_planta_nombre || ''}
                                    onChange={handleInputChangeSensor}
                                    placeholder="detector"
                                    disabled
                                    validation={valuesValidSensor}
                                    fetchData={fetchEditarSensorData}
                                    
                                />
                            </Form.Group>

                            <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                <InputText
                                    label={Lang('TEST_RADON_SENSOR_FORM_NUMERO_DETECTOR')}
                                    className=''
                                    name='numero_detector'
                                    value={formSensor.numero_detector}
                                    onChange={handleInputChangeSensor}
                                    placeholder="detector"
                                    validation={valuesValidSensor}
                                    fetchData={fetchEditarSensorData}
                                />
                            </Form.Group>

                            <Form.Group className="col-12 col-md-6 col-lg-3 mb-4">
                                <InputText
                                    label={Lang('TEST_RADON_SENSOR_FORM_FECHA_INICIO')}
                                    className=''
                                    type="date"
                                    name='fecha_desde'
                                    value={formSensor.fecha_desde}
                                    onChange={handleInputChangeSensor}
                                    placeholder="fechainicio"
                                    validation={valuesValidSensor}
                                    fetchData={fetchEditarSensorData}
                                />
                            </Form.Group>

                            <Form.Group className="col-12 col-md-6 col-lg-3 mb-4">
                                <InputText
                                    label={Lang('TEST_RADON_SENSOR_FORM_FECHA_FIN')}
                                    className=''
                                    type="date"
                                    name='fecha_hasta'
                                    value={formSensor.fecha_hasta}
                                    onChange={handleInputChangeSensor}
                                    placeholder="fechafin"
                                    validation={valuesValidSensor}
                                    fetchData={fetchEditarSensorData}
                                />
                            </Form.Group>

                            <Form.Group className="col-12 mb-3">
                                <InputTextNormal
                                    label={Lang('TEST_RADON_SENSOR_FORM_UBICACION')}
                                    className=''
                                    name='nota'
                                    value={formSensor.nota}
                                    onChange={handleInputChangeSensor}
                                    validation={valuesValidSensor}
                                    fetchData={fetchEditarSensorData}
                                    as="textarea"
                                    rows={2}
                                />
                            </Form.Group>
                           <Form.Group className="col-6 mb-3">
                             <InputCheck 
                             label={Lang('TEST_RADON_SENSOR_FORM_NO_UTILIZADO')} 
                             name="no_utilizado"
                             value={formSensor.no_utilizado}
                             onChange={(e) => handleInputChangeSensor({
                                    target:{
                                        name: 'no_utilizado',
                                        value: e.target.checked
                                    }
                             })}
                             />
                            </Form.Group>

                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="default" onClick={handleClose}>
                        {Lang('TEST_RADON_MODAL_BTN_CERRAR')}
                    </Button>

                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitSensor} fetchDataLoading={[fetchEditarSensorData.loading]} value={Lang('TEST_RADON_SENSOR_BTN_EDITAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                    />
                </Modal.Footer>
                 </>
             }
            </Modal>
        </>
    )
}
