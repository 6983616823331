import React, { useContext, useEffect } from 'react';

import './AvisLegal.css'

import { NavTopLegal } from '../../components/NavTopLegal/NavTopLegal';
import { AvisoLegalEs } from './AvisoLegalEs';
import { AuthContext } from '../../auth/AuthContext';
import { AvisoLegalCa } from './AvisoLegalCa';

export const AvisLegal = () => {


    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    useEffect(() => {

        window.scrollTo(0, 0)

    }, []);

    return (
        <div className="App bg-opacity-primary">
            <section className="App-header">
                <NavTopLegal />
                <section id="Main-legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                {
                                    lang === 'ES' && <AvisoLegalEs />
                                }

                                {
                                    lang === 'CA' && <AvisoLegalCa />
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
