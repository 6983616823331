import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <>
  <App />
  <ToastContainer theme="colored"/>
  </>,
  document.getElementById('root')
);

// if(module.hot){
//   module.hot.accept()
// }