import React from 'react';
import './Footer.css'
import { useLang } from 'language';
import { NavLink } from 'react-router-dom';

export const Footer = ({ style }) => {

  const locale = useLang()

  return (
    <section id='Footer' className='d-flex flex-column align-items-center flex-sm-row justify-content-between px-3' style={style}>
      <div className='pt-2 py-sm-2'>
        © {(new Date()).getFullYear()} Cateb
      </div>
      <div className='d-flex flex-column align-items-center flex-sm-row '>
        <span className='ms-sm-2 py-2'>

          <NavLink
            className="d-flex nav-link px-2"
            exact
            to='/aviso-legal'
          >
            {locale('AVISO_LEGAL')}
          </NavLink>

        </span>

        <span className='ms-sm-2 py-2'>

          <NavLink
            className="d-flex nav-link px-2"
            exact
            to='/condiciones-contratacion'
          >
            {locale('CONDICIONES_CONTRATACION')}
          </NavLink>
        </span>

        <span className='ms-sm-2 py-2'>

          <NavLink
            className="d-flex nav-link px-2"
            exact
            to='/faqs'
          >
            FAQ's
          </NavLink>
        </span>

      </div>
    </section>
  );
};
