import { useState } from 'react';

export const useForm = ( initialState = {} ) => {
    
    const [values, setValues] = useState(initialState);

    const reset = () => {
        setValues( initialState );
    }

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        if (name.includes('.')) {
          const [objName, fieldName] = name.split('.');
          setValues((prevForm) => ({
            ...values,
            [objName]: { ...prevForm[objName], [fieldName]: value },
          }));
        }else {
             setValues({
                ...values,
                [name]: value
            });
        }        
    }

    const handleFormChange = (obj) => {
        setValues(
            {...obj}
        )
    }

    return [ values, handleInputChange, handleFormChange, reset ];
}