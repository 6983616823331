import { useLang } from "language";
import React from "react";

export const ModalBasicoForm = () => {

    return (
        <>
            <ModalBasicoForm1 />
            <ModalBasicoForm2 />
            <ModalBasicoForm3 />
        </>
    )
};

export const ModalBasicoForm1 = () => {

    const locale = useLang();

    return (
        <div className="row">
            {/* <p>
                {locale('HOME_RADON_BASICO_TEXT')}
            </p> */}
            <p>
                {locale('HOME_RADON_BASICO_TEXT_INFO1')}
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_BOX2_TITLE')}</span>: {locale('HOME_RADON_BASICO_TEXT_INFO22')}
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_TEXT_SPAN1')}</span> {locale('HOME_RADON_BASICO_TEXT_INFO2')}
            </p>

        </div>
    )
};


export const ModalBasicoForm2 = () => {

    const locale = useLang();

    return (
        <div className="row d-flex align-items-center justify-content-center ">
            <h4 className="text-center mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_BASICO_TARIFA')}</h4>

            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center">
                    <span className="section-text-header-general">{locale('HOME_RADON_BASICO_BOX2_TITLE')}</span>
                    <div className="p-2">
                        <p className="text-center">
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX2_INFO') }}></div>
                            <br />
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX2_INFO2') }}></div>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center">
                    <span className="section-text-header-general">{locale('HOME_RADON_BASICO_BOX1_TITLE')}</span>
                    <div className="p-2">
                        <p className="text-center">
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX1_INFO') }}></div>
                            <br />
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX1_INFO2') }}></div>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <p className="mb-0 text-center">
                    <small>

                    {locale('CONSULTA_COLEGIOS_CONVENIO')} <span className="link-primary color-primary cursor-pointer text-lowercase">{locale('EN_ESTE_ENLACE')}</span> 
                    </small>
                </p>
            </div>

        </div>
    )
};

export const ModalBasicoForm3 = () => {

    const locale = useLang();

    return (
        <div className="row">
            <h6 className="text-start mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_BASICO_CONDICIONES')}</h6>
            <div className="col-12 col-md-12">
                <ol class="my-list">
                    <li><small>{locale('HOME_RADON_BASICO_CONDICIONES_INFO1')}</small></li>
                    <li><small>{locale('HOME_RADON_BASICO_CONDICIONES_INFO2')}</small></li>
                </ol>
            </div>

            <h6 className="text-start mt-3 mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_BASICO_SERVICIO')}</h6>
            <div className="col-12 col-md-12">
                <ol class="my-list">
                    <li><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO1')}</small></li>
                    <li><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO2')}</small></li>
                    <li><small>{locale('P_RESULTADO_TEST_1')}</small></li>
                    <li><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO5')}</small></li>
                </ol>
            </div>
        </div>
    )
};
