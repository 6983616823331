import React, {useContext, useEffect} from "react";
import { useLang } from "../../../../language";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { InputText } from "../../../../components/Inputs/InputText";
import { InputSelect } from "../../../../components/Inputs/InputSelect";
import Accordion from 'react-bootstrap/Accordion';
import { useFetch } from "hooks/useFetch";
import { AuthContext } from "auth/AuthContext";

export const TestRadonFilter = ({handleSearch, formValuesSearch, handleInputChangeSearch, parametros}) => {

    const Lang = useLang()
    const [fetchProvincias, fetchProvinciasData] = useFetch("GET", `/parametros/provincias`);
    const [fetchMunicipioEdificio, fetchMunicipioEdificioData] = useFetch("GET", `/parametros/municipios`);
    const [fetchZonasEdificio, fetchZonasEdificioData] = useFetch("GET", `/parametros/zonas`);

    useEffect(() =>{
        fetchProvincias();
    }, [])

    useEffect(() => {
        if(formValuesSearch?.provincia_id){
            fetchMunicipioEdificio({
                params: {
                    provincia_id: formValuesSearch?.provincia_id,
                },
            });
        }
    }, [formValuesSearch?.provincia_id])

    useEffect(() => {
        if (formValuesSearch?.municipio_id) {
            fetchZonasEdificio({
                id: formValuesSearch?.municipio_id,
            });
        }
    }, [formValuesSearch.municipio_id]);

    return (

            <div className='mb-4'>
                <Form className='row' onSubmit={handleSearch}>

                  {/*
                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                          label={Lang('TEST_RADON_FILTRO_TIPO_TEST')}
                          className=''
                          name='tipo_test'
                          disabled={parametros.loading}
                          value={formValuesSearch.tipo_test}
                          onChange={handleInputChangeSearch}
                          values={
                                [
                                    {
                                        id: 'personal',
                                        nombre: 'Bàsico'
                                    },
                                    {
                                        id: 'profesional',
                                        nombre:'CTE'
                                    },
                                ]
                            }
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                          label={Lang('TEST_RADON_FILTRO_TIPO_EDIFICIO')}
                          className=''
                          name='tipo_edificio'
                          disabled={parametros.loading}
                          value={formValuesSearch.tipo_edificio}
                          onChange={handleInputChangeSearch}
                          values={
                            parametros.data?.data?.tipo_edificio?.map((item, index) => {
                                return {
                                   id: item.param_id,
                                   nombre: item.texto
                                }
                            })
                          }
                        />
                    </Form.Group>

                     <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                          label={Lang('TEST_RADON_FILTRO_PROVINCIA')}
                          className=''
                          name='provincia_id'
                          value={formValuesSearch.provincia_id}
                          onChange={handleInputChangeSearch}
                          disabled={fetchProvinciasData.loading}
                          values={
                            fetchProvinciasData.data?.data?.map((item, index) => {
                                return {
                                   id: item.id_provincia,
                                   nombre: item.provincia
                                }
                            })
                          }
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                          label={Lang('TEST_RADON_FILTRO_MUNICIPIO')}
                          className=''
                          name='municipio_id'
                          disabled={parametros.loading}
                          value={formValuesSearch.municipio_id}
                          onChange={handleInputChangeSearch}
                          values={fetchMunicipioEdificioData?.data?.data}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                          label={Lang('TEST_RADON_FILTRO_ZONA')}
                          className=''
                          name='zona'
                          disabled={parametros.loading}
                          value={formValuesSearch.zona}
                          onChange={handleInputChangeSearch}
                          values={fetchZonasEdificioData?.data?.data?.zonas?.map((item, index) => {
                                return {
                                    id: item.zona,
                                    nombre: item.zona,
                                };
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputText
                            label={Lang('TEST_RADON_FILTRO_NUMERO_DETECTOR')}
                            className=''
                            type="number"
                            name='total_detector'
                            disabled={parametros.loading}
                            value={formValuesSearch.total_detector}
                            onChange={handleInputChangeSearch}
                            placeholder="muestra_id"
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputText
                            label={Lang('TEST_RADON_FILTRO_NUMERO_SOLICITUD')}
                            className=''
                            type="number"
                            disabled={parametros.loading}
                            name='solicitud_id'
                            value={formValuesSearch.solicitud_id}
                            onChange={handleInputChangeSearch}
                            placeholder="solicitud_id"
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputSelect
                            label={Lang('TEST_RADON_FILTRO_ESTADO')}
                            className=''
                            name='estado'
                            disabled={parametros.loading}
                            values={
                                parametros.data?.data?.estados_radon.map((item, index) => {
                                      return {
                                         id: item.param_id,
                                         nombre: item.texto
                                      }
                                  })
                                }
                            // values={fetchParametersData.data?.data?.estados_solicitud}
                            value={formValuesSearch.estado}
                            onChange={handleInputChangeSearch}
                            placeholder="estado_id"
                        />
                    </Form.Group>
                    */}

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputText
                            label={Lang('TEST_RADON_FILTRO_DESDE')}
                            className=''
                            name='fecha_desde'
                            disabled={parametros.loading}
                            value={formValuesSearch.fecha_desde}
                            onChange={handleInputChangeSearch}
                            placeholder="fecha_desde"
                            type="date"
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputText
                            label={Lang('TEST_RADON_FILTRO_HASTA')}
                            className=''
                            name='fecha_hasta'
                            disabled={parametros.loading}
                            value={formValuesSearch.fecha_hasta}
                            onChange={handleInputChangeSearch}
                            placeholder="fecha_hasta"
                            type="date"
                        />
                    </Form.Group>

                    {/*
                    <Form.Group className="col-6 col-md-4 mb-3">
                        <InputText
                            label={Lang('TEST_RADON_FILTRO_PERSONA')}
                            className=''
                            name='persona'
                            disabled={parametros.loading}
                            value={formValuesSearch.persona}
                            onChange={handleInputChangeSearch}
                            placeholder="muestra_id"
                        />
                    </Form.Group>
                    */}

                    <div className='col mb-0 d-flex align-items-end'>
                        <div className=' d-flex align-items-end ms-auto'>
                            <Button disabled={parametros.loading} variant='primary' type='submit' className='px-3 float-end' onClick={handleSearch}>
                                <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
    )
};
