import React from 'react';
import Select from 'react-select';
import './InputSelectAutocomplete.css'

const InputSelectAutocomplete = (props) => {
  const {options, name, value, handleInputForm, placeholder} = props

  const handleInput = (e) => {
     handleInputForm({
        target:{
          value:e.value?? '',
          name
        }
     });
  } 

  const valueFromId = (opts, id) => opts?.find(o => o.value === id);

  return (
      <Select
        className="select-autocomplete"
        id={name}
        options={options}
        name={name}
        value={valueFromId(options, value)}
        onChange={handleInput}
        placeholder={placeholder}
        autosize={true}
        clearable={true}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
        }}
      />
  );
};

export default InputSelectAutocomplete;
