import React, { useContext } from 'react'
import { NavLinksInner } from '../NavLinksInner/NavLinksInner';
import { AuthContext } from '../../auth/AuthContext';
import { useLang } from '../../language';
import { types } from '../../types/types';

import './NavStartInner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFolder, faHome, faUser } from '@fortawesome/free-solid-svg-icons';

export const NavStartInner = ({ setShow, show }) => {

    const { user, dispatch } = useContext(AuthContext);

    // Cambiar idioma
    const Lang = useLang();
    const handdleChangeLanguage = (lang) => {

        dispatch({
            type: types.login,
            payload: {
                ...user,
                lang
            }
        })
    }

    const listMenu = [
        {   //Lang
            name: Lang('SIDEBAR_MENU1'),
            link: '/test-radon/nuevo',
            icon: <FontAwesomeIcon icon={faFileAlt} />
        },
        /*{
            name: 'Test aluminós',
            link: '/test-aluminos',
            icon: <FontAwesomeIcon icon={faFileAlt} />
        },*/
        {
            name: Lang('SIDEBAR_MENU2'),
            link: '/test-radon',
            icon: <FontAwesomeIcon icon={faFileAlt} />
        },
        {
            name: Lang('SIDEBAR_MENU3'),
            link: '/les-meves-dades',
            icon: <FontAwesomeIcon icon={faUser} />
        }

    ]

    return (
        <section className={`NavStartInner position-fixed d-flex flex-column ${show ? 'toggle-menu' : ''}`} >
            
            <NavLinksInner 
                list={listMenu}
            />

            <footer className="px-3 mb-3 mt-auto">
                <small>
                    {/* <p className='mb-1'>Tel. 932 40 20 60</p>
                    <p className='mb-1'>consultoriatecnica@cateb.cat</p> */}
                </small>
            </footer>
        </section>
    )
}
