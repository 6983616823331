import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useFetch } from 'hooks/useFetch'
import { useLang } from 'language'

export const ModalEliminarPlanta = ({ showModalEliminar, handleCloseModal, planta_id, actualizarLista }) => {

    const history = useHistory()
    const locale = useLang()

    const [fetchEliminarPlanta, fetchEliminarPlantaData] = useFetch('DELETE', `/clientes/solicitudes_radon_planta`)

    const handleEliminarSolicitud = () => {
        fetchEliminarPlanta({
            id: planta_id+'/delete'
        })
    }

    useEffect(() => {
        if (fetchEliminarPlantaData.data?.status === 'Success') {
            handleCloseModal();
            actualizarLista();
        }

    }, [fetchEliminarPlantaData.data])

    return (
        <>
            <Modal show={showModalEliminar} onHide={handleCloseModal} size='md' id='ModalEliminarPlanta'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h5 className='text-center'>segur que voleu eliminar la planta?</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModal}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary-aluminosis" type="submit" className="ms-2"
                        handleSubmit={handleEliminarSolicitud} fetchDataLoading={[fetchEliminarPlantaData.loading]}
                        value={locale('SI_ELIMINAR')}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
