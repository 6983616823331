import React, { useEffect, useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { InputText } from 'components/Inputs/InputText'
import { InputSelect } from 'components/Inputs/InputSelect'
import { useFetch } from 'hooks/useFetch'
import { useForm } from 'hooks/useForm'
import { useValidator } from 'hooks/useValidator'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { getSelectByKeys } from 'helpers/helpers'
import { TipoViasServices } from 'controllers/services/statics.service'
import { useLang } from 'language';

export const ModalCambiarPagador = ({ fetchSolicitudesMuestras, setMuestras, showModalNuevoPagador,
    handleCloseModalNuevoPagador, fetchMisDatos, solicitud_id, documento, pagador }) => {

    const locale = useLang();

    // Modal cambiar pagador a una persona o empresa

    const [fetchCambiarPagador, fetchCambiarPagadorData] = useFetch('POST', `/clientes/solicitudes_radon`)

    const [formPagador, handleInputChangePagador, handleFormChangePagador] = useForm({
        documento: '',
        nombre: '',
        apellidos: '',
        direccion: '',
        provincia: '',
        poblacion: '',
        codigo_postal: '',
        pais: '',
        email: '',
        comarca: '',
        tipovia: '',
        poblacion: '',
    })

    const [valuesValidPagador, handleVerifiedValuesPagador] = useValidator({

        documento: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        nombre: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        calle: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        provincia: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        poblacion: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        codigo_postal: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        pais: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_EMAIL')]
            }
        },

    })

    useEffect(() => {

        if (fetchCambiarPagadorData.data?.status === 'Success') {
            
            fetchMisDatos()
            handleCloseModalNuevoPagador()
        }

    }, [fetchCambiarPagadorData])


    const handleNuevoPagador = () => {

        if (handleVerifiedValuesPagador(formPagador)) {

            const bodyTemp = {
                ...formPagador
            }

            // if(!bodyTemp.comarca) delete bodyTemp.comarca
            // if(!bodyTemp.apellidos) delete bodyTemp.apellidos

            if(!bodyTemp.comarca) bodyTemp.comarca = 'default'
            if(!bodyTemp.apellidos) bodyTemp.apellidos = '.'

            fetchCambiarPagador({
                id: `${solicitud_id}/cambiar_pagador`,
                body: bodyTemp
            })
        }
    }

    useEffect(() => {
        if (showModalNuevoPagador) {
            handleFormChangePagador({
                ...formPagador,
                documento
            })
        }
    }, [showModalNuevoPagador])


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)
    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formPagador.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    codigo_postal: formPagador.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangePagador({
                ...formPagador,
                provincia: '',
                poblacion: '',
                pais: '',
            })
        }

    }, [formPagador.codigo_postal])

    useEffect(() => {

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data.status === 'Success') {

                const poblaciones = fetchPoblacionesData.data.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangePagador({
                    ...formPagador,
                    provincia: '',
                    poblacion: '',
                    pais: ''
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formPagador.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data]

            const resp = poblacionesTemp.find(item => item.poblacion === formPagador.poblacion)

            handleFormChangePagador({
                ...formPagador,
                provincia: resp?.provincia,
                pais: resp?.pais,
            })
        }

    }, [formPagador.poblacion, fetchPoblacionesData.data])

    const staticTipoViasServices = TipoViasServices.GET()

    return (
        <>
            <Modal show={showModalNuevoPagador} onHide={handleCloseModalNuevoPagador} size='lg'>
                <Modal.Header closeButton className='border-0 pb-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <h5 className='text-center'>{locale('RADON_MODAL_CAMBIAR_PAGADOR_TITLE')}</h5>
                            </div>

                            <Form.Group className="mb-4 col-lg-3">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PAGADOR')}
                                    className=''
                                    name='documento'
                                    value={formPagador.documento}
                                    onChange={handleInputChangePagador}
                                    placeholder="documento"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                    disabled
                                />
                            </Form.Group>

                            {/* <Form.Group className="mb-4 col-lg-9">
                                <InputText
                                    label={pagador === 3 ? 'Nom complet *' : 'Raó social *'}
                                    className=''
                                    name='nombre_completo'
                                    value={formPagador.nombre_completo}
                                    onChange={handleInputChangePagador}
                                    placeholder="nombre_completo"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group> */}

                            <Form.Group className="mb-3 col-lg-4">
                                <InputText
                                    label={pagador === 3 ? `${locale('NOMBRE')}: *` : `${locale('RAZON_SOCIAL')}: *`}
                                    className=''
                                    name='nombre'
                                    value={formPagador.nombre}
                                    onChange={handleInputChangePagador}
                                    placeholder="nombre"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            {
                                pagador === 3 &&
                                <Form.Group className="mb-3 col-lg-5">
                                    <InputText
                                        label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_APELLIDO')}
                                        className=''
                                        name='apellidos'
                                        value={formPagador.apellidos}
                                        onChange={handleInputChangePagador}
                                        placeholder="apellidos"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchCambiarPagadorData}
                                    />
                                </Form.Group>
                            }

                            <div className="col-12">
                                <h5 className='mb-4'>{locale('DATOS_DIRECCION')}</h5>
                            </div>

                            <Form.Group className="mb-4 col-md-6 col-lg-3">

                                <InputSelect
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_TIPO_VIA')}
                                    className=''
                                    name='tipovia'
                                    values={getSelectByKeys(staticTipoViasServices)}
                                    value={formPagador.tipovia}
                                    onChange={handleInputChangePagador}
                                    placeholder="tipovia"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-7">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_DIRECCION')}
                                    className=''
                                    name='calle'
                                    value={formPagador.calle}
                                    onChange={handleInputChangePagador}
                                    placeholder="calle"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <div className="col-12"></div>

                            <Form.Group className="mb-4 col-md-6 col-lg-2">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_NUMERO')}
                                    className=''
                                    name='numero'
                                    value={formPagador.numero}
                                    onChange={handleInputChangePagador}
                                    placeholder="numero"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4 col-md-6 col-lg-2">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PISO')}
                                    className=''
                                    name='piso'
                                    value={formPagador.piso}
                                    onChange={handleInputChangePagador}
                                    placeholder="piso"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4 col-md-6 col-lg-2">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PUERTA')}
                                    className=''
                                    name='puerta'
                                    value={formPagador.puerta}
                                    onChange={handleInputChangePagador}
                                    placeholder="puerta"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4 col-md-6 col-lg-2">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_ESCALERA')}
                                    className=''
                                    name='escalera'
                                    value={formPagador.escalera}
                                    onChange={handleInputChangePagador}
                                    placeholder="escalera"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <div className="col-12"></div>

                            <Form.Group className="mb-4 col-sm-6 col-lg-4">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_CODIGO_POSTAL')}
                                    className=''
                                    name='codigo_postal'
                                    value={formPagador.codigo_postal}
                                    onChange={handleInputChangePagador}
                                    placeholder="codigo_postal"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />

                            </Form.Group>

                            <Form.Group className="mb-4 col-sm-6 col-lg-5">
                                <InputSelect
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_POBLACION')}
                                    className=''
                                    name='poblacion'
                                    values={poblaciones}
                                    value={formPagador.poblacion}
                                    onChange={handleInputChangePagador}
                                    placeholder="poblacion"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                    disabled={!fetchPoblacionesData.data || formPagador.codigo_postal?.length !== 5}
                                />
                            </Form.Group>

                            {/* <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                <InputText
                                    label='Comarca*'
                                    className=''
                                    name='comarca'
                                    value={formPagador.comarca}
                                    onChange={handleInputChangePagador}
                                    placeholder="comarca"
                                    // validation={valuesValidRegisterExterno}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group> */}

                            <Form.Group className="mb-0 col-lg-6">
                                <InputText
                                    label={locale('RADON_MODAL_CAMBIAR_PAGADOR_INPUT_EMAIL')}
                                    className=''
                                    name='email'
                                    value={formPagador.email}
                                    onChange={handleInputChangePagador}
                                    placeholder="email"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />

                            </Form.Group>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalNuevoPagador}>
                        {locale('RADON_MODAL_CAMBIAR_PAGADOR_SALIR')}
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="px-4 ms-2"
                        handleSubmit={handleNuevoPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                        value={locale('RADON_MODAL_CAMBIAR_PAGADOR_ACEPTAR')}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
