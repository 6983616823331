import React from "react";
import { Accordion } from "react-bootstrap";

export const AvisoLegalCa = () => {
    return (
        <>
            <h5 className="text-center mb-4">
                Avis legal i condicions d´ús del web
            </h5>
            <Accordion alwaysOpen>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1.	Avís legal
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El prestador de serveis i titular d'aquest lloc web és el COL·LEGI D'ARQUITECTURA TÈCNICA DE BARCELONA (CATEB en endavant), amb NIF Q0875009C, amb domicili a C. Bon Pastor 5 – CP: 08021 Barcelona, trobant-se inscrit al registre de Col·legis Professionals de la Generalitat de Catalunya amb número d'inscripció ATiAP. Si vols contactar amb nosaltres pots fer-ho mitjançant el telèfon 93 240 20 60 o correu electrònic escrivint a consultoriatecnica@cateb.cat.

                        </p>
                        <p>
                            Aquest lloc web té per objecte promocionar i oferir serveis relacionats amb l'àmbit d'actuació de CATEB a través de la posada a disposició d'aplicacions informàtiques, serveis, programari i formació a través de la nostra aula virtual.
                            L' idioma de les presents condicions és l' català, per la qual cosa, en cas de discrepància en la interpretació de les condicions, el català serà l' idioma de prevalença. Qualsevol traducció que es realitzi sobre aquestes no afectarà la prevalença indicada.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>2. Funcionament del nostre lloc web</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>2.1.¿Com és el nostre lloc web?</strong>
                        </p>
                        <p>
                            Es podrà navegar pel nostre lloc web sense necessitat d'estar registrat com a usuari. Podrà conèixer els productes i serveis que oferim i contactar amb nosaltres.
                        </p>
                        <p>
                            La contractació d’alguns dels nostres productes o serveis pot requerir un registre previ com a usuari en el qual se li sol·licitarà un document identificatiu, un correu electrònic i contrasenya com a credencials d'accés al seu perfil d'usuari. El registre li permetrà accedir a determinades aplicacions informàtiques de pagament que precisen la identificació d' un usuari per al correcte funcionament.
                        </p>
                        <p>
                            En aquests casos, per registrar-se caldrà que l' usuari, amb caràcter previ, es llegeixi, comprengui i accepti les presents condicions i la Política de Privacitat.
                        </p>
                        <p>
                            Les contractacions de productes o serveis es regiran per les estipulacions recollides en les Condicions generals i particulars de Contractació, que hauran de ser llegides, compreses i acceptades abans de realitzar la contractació.
                        </p>
                        <p>
                            En la resta dels casos, l'usuari podrà navegar i utilitzar lliurement els recursos i continguts oferts en el nostre lloc web, respectant i complint amb les obligacions i limitacions establertes en les presents condicions.
                        </p>
                        <p>
                            Aquestes Condicions seran d' aplicació juntament amb la resta de les condicions que poguessin resultar d' aplicació com la Política de Privacitat.
                        </p>

                        <p>
                            <strong>2.2.Tipus d' usuari del nostre lloc web</strong>
                        </p>
                        <p>
                            Usuaris.
                        </p>
                        <p>
                            Seran aquells usuaris que accedeixin i facin ús del nostre lloc web sense realitzar compres ni registrar-se.
                        </p>
                        <p>

                            Usuaris registrats.

                        </p>
                        <p>
                            Seran aquells usuaris que s'hagin donat d'alta al nostre lloc web mitjançant la creació d'un perfil d'usuari.
                        </p>
                        <p>
                            Podran accedir a registrar-se al web www.testrado.cat, els col·legiats del Cateb, així com qualsevol usuari que vulgui sol·licitar la contractació del nostre producte Bàsic. En cas de pertànyer a un altre col·legi professional o comunitat autònoma, si voleu adquirir els nostres productes haureu d’accedir al registre des de la web www.testradon.es.
                        </p>
                        <p>
                            El Registre és el pas previ per adquirir els productes oferts a la Web, en les condicions que regulen cada producte que hauran de ser acceptades de forma expressa en el moment de la seva adquisició.
                        </p>
                        <p>
                            Aquest registre no atorga cap dret sobre la web ni sobre els seus productes llevat que s'adquireixi algun d'ells de forma expressa.
                        </p>
                        <p>
                            El registre és personal i no es pot donar d' alta a terceres persones, ni compartir les claus d' accés als productes.
                        </p>
                        <p>
                            El registre, sense compra, podrà ser eliminat pel Cateb, en qualsevol moment sense previ avís.
                        </p>
                        <p>
                            Usuaris/Clients.
                        </p>
                        <p>
                            El constituiran les persones que hagin realitzat alguna compra o hagin estat donats d'alta al nostre lloc web, havent d'haver llegit, comprès i acceptat prèviament les presents condicions, les Condicions generals de contractació i la Política de privacitat.
                        </p>
                        <p>
                            <strong>2.3. Seguretat del nostre lloc web  </strong>
                        </p>
                        <p>
                            El nostre lloc web utilitza el protocol segur de transferència d'hipertext (Hypertext Transfer Protocol Secure, HTTPS) garantint la confidencialitat de les comunicacions.
                        </p>
                        <p>
                            El protocol HTTPS proveeix protecció i garantia en l'enviament d'informació a través d'internet, de manera que les dades i la informació enviada des del nostre lloc web al servidor no pot ser llegida ni interceptada per tercers, ja que la informació viatja xifrada.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>3. Condicions d'ús del nostre lloc web per a tots els usuaris web</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>3.1. Obligacions de l'Usuari</strong>
                        </p>
                        <p>
                            L' usuari assumeix les obligacions de:
                        </p>
                        <p>
                            (1) Realitzar un ús responsable i raonable del nostre lloc web d'acord amb aquestes condicions d'ús i la legalitat vigent;
                        </p>

                        <p>
                            (2) No realitzar activitats contràries a la legislació espanyola, especialment pel que fa a actuacions constitutives de delictes segons el Codi Penal. En concret, l'usuari no haurà d'accedir a informació de tercers que no hagi estat autoritzada, no suplantarà la identitat de tercers i serà responsable de proporcionar dades exactes i veraces.
                        </p>
                        <p>
                            <strong>3.2. Usos permesos</strong>
                        </p>
                        <p>


                            L'Usuari podrà fer un ús responsable del nostre lloc web conforme als usos i costums, bona fe, moral i ordre públic. Tota aquella conducta aliena a aquests principis estarà prohibida i perseguida conforme la normativa vigent.

                        </p>

                        <p>
                            <strong>3.3. Usos prohibits</strong>
                        </p>

                        <p>

                            Queda prohibit l'accés o la utilització dels productes oferts al nostre lloc web amb finalitats il·legals o no autoritzades. En concret, a títol enunciatiu però no limitatiu, queda prohibit:
                        </p>
                        <p>
                            – Usar el servei amb finalitats il·lícites o realitzar activitats contràries a la bona fe i a l'ordre públic.
                        </p>
                        <p>
                            – Difondre continguts o propaganda de caràcter racista, xenòfob, pornogràfic-il·legal, d'apologia del terrorisme o atemptatotori contra els drets humans.
                        </p>
                        <p>
                            – Usar qualsevol dels serveis del lloc web de manera que es pugui danyar, sobrecarregar o perjudicar el servei.
                        </p>
                        <p>

                            – Utilitzar o introduir en el servei malware que pugui produir danys o alteracions en els nostres sistemes, en els continguts o en el sistema informàtic d'altres usuaris i/o en els seus terminals mòbils.
                        </p>
                        <p>
                            – L'ús de recursos tècnics, lògics o tecnològics en virtut dels quals algú, usuari o no del servei, pugui beneficiar-se, directament o indirectament, amb o sense lucre, dels perfils i dels continguts del lloc web.
                        </p>
                        <p>
                            En definitiva, queden prohibits els usos de la WEB que siguin contraris a la normativa de Propietat Intel·lectual i Industrial, Protecció de Dades, Llei de Competència Deslleial, Codi Civil i Mercantil, així com qualsevol altra que pogués resultar aplicable.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Règim de responsabilitats
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                4.1. Responsabilitat de l'Usuari
                            </strong>
                        </p>
                        <p>
                            L' usuari serà responsable de:
                        </p>
                        <p>


                            (1) Els mals usos realitzats sobre el contingut o serveis disponibles a través del nostre lloc web d'acord amb el que s'indica en les presents condicions.

                        </p>
                        <p>
                            (2) Dels danys i perjudicis causats a CATEB, inclosos els danys emergents i lucre cessant, així com les despeses judicials i honoraris d'advocat i procurador als quals es veiés sotmès com a conseqüència de l'actuació dolosa o negligent de l'usuari.
                        </p>
                        <p>
                            <strong>
                                4.2. Limitació de la responsabilitat del titular
                            </strong>
                        </p>
                        <p>


                            CATEB no serà responsable de:

                        </p>
                        <p>
                            – La inexistència de possibles errors o faltes d'actualització en el lloc web, reservant-se el dret a efectuar en qualsevol moment i sense necessitat d'avís previ, modificacions i actualitzacions sobre la informació continguda en el seu portal, configuració o presentació.
                        </p>
                        <p>
                            – Les fallades i incidències que poguessin produir-se en les comunicacions, esborrat o transmissions incompletes atès que poden produir-se caigudes de la xarxa o un altre tipus de situacions de les quals CATEB no és directament responsable i no podrà assumir responsabilitat per això ni garantir que els serveis del lloc web estiguin constantment operatius, quedant eximida de qualsevol dany que això pogués provocar.
                        </p>

                        <p>
                            – Els errors o danys produïts per la negligència o mala fe en l'ús del servei per part de l'Usuari i/o Client.
                        </p>
                        <p>
                            – La producció de qualsevol tipus de dany que el Client o tercers poguessin ocasionar al lloc Web.
                        </p>
                        <p>
                            – Sens perjudici del que es disposa en matèria de protecció de dades personals, el Cateb no garanteix la privacitat i seguretat de la utilització de la Web i dels Serveis i, en particular, que tercers no autoritzats no puguin tenir coneixement del tipus, les condicions, les característiques i les circumstàncies de l'ús que els Usuaris facin de la Web i dels Serveis.
                        </p>

                        <p>
                            CATEB es reserva el dret a suspendre l'accés sense previ avís quan es detecti qualsevol situació en què l'Usuari i/o Client no hagi actuat diligentment i/o s'hagi aprofitat de les funcionalitats de la pàgina web. La suspensió del servei podrà tenir caràcter temporal o definitiu i CATEB no serà responsable dels perjudicis que la suspensió pogués ocasionar-li a l'usuari/client.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Política de continguts
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                5.1. Continguts del nostre lloc web
                            </strong>
                        </p>
                        <p>


                            El nostre lloc web ofereix un ampli contingut tècnic amb recursos de diversa índole. Tot el contingut és propietat de CATEB o dels seus llicenciataris, per la qual cosa l' usuari no tindrà cap dret de titularitat sobre els mateixos.

                        </p>
                        <p>
                            CATEB realitza treballs constants per mantenir actualitzat el seu contingut, però no garanteix que puguin existir errors o fallades, no assumint cap responsabilitat per això.
                        </p>
                        <p>
                            <strong>
                                5.2. Limitacions a l'ús dels continguts
                            </strong>
                        </p>

                        <p>

                            – Els continguts només podran ser utilitzats amb finalitats personals, quedant expressament prohibit l'ús amb finalitats comercials o lucratives, resultant d'aplicació les estipulacions contingudes en la clàusula 6.1. de les presents condicions.

                        </p>
                        <p>
                            – L'usuari haurà de respectar i complir amb les obligacions i limitacions establertes en la clàusula 6 de les presents condicions en relació amb l'ús que faci dels diferents tipus de continguts disponibles al nostre lloc web.
                        </p>


                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6. Drets de Propietat Intel·lectual
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Tots els drets sobre els continguts publicats i oferts per CATEB al lloc web estan protegits pels drets de propietat intel·lectual i industrial. CATEB ha desenvolupat i disposa de les llicències i autoritzacions necessàries sobre els continguts del lloc web, els recursos, les eines, així com el codi de programació i disseny dels productes i serveis digitals oferts.
                        </p>
                        <p>
                            Els articles, continguts, imatges o logotips del Col·legi són titularitat de CATEB o de les empreses llicenciatàries que han permès el seu ús i publicació expressament.
                        </p>
                        <p>
                            Es prohibeix expressament, llevat d' autorització per escrit i expressa de CATEB, la reproducció, transformació, distribució, comunicació pública, posada a disposició al públic, venda o altres usos diferents als expressament autoritzats per CATEB en les presents condicions, en les polítiques i altres avisos publicats a la seva pàgina web, així com a les condicions particulars, si s' escau,  ja sigui amb finalitats comercials o no, quan comportin l' explotació dels continguts, productes o serveis oferts CATEB les finalitats dels quals siguin diferents a les previstes en aquestes condicions.
                        </p>
                        <p>
                            <strong>
                                6.1. Drets de propietat intel·lectual
                            </strong>
                        </p>
                        <p>


                            L'Usuari reconeix expressament, i a tots els efectes, que la informació, signes, imatges, dissenys, logotips, animacions, vídeos, textos, així com els altres elements i continguts, inclosos els serveis i aplicacions informàtiques del nostre lloc web, estan subjectes a drets de propietat industrial i intel·lectual de CATEB i/o dels tercers titulars que hagin autoritzat degudament la seva inclusió al web,  manifestant-se expressament que no es concedirà cap tipus de dret sobre cap contingut de cap tipus, llevat dels que expressament es reconeguin.


                        </p>
                        <p>
                            Conseqüentment, tots els drets estan reservats, quedant expressament prohibides la reproducció, la distribució i la comunicació pública, inclosa la seva modalitat de posada a disposició, de la totalitat o part dels continguts d' aquesta pàgina web, amb finalitats comercials, en qualsevol suport i per qualsevol mitjà tècnic, sense l' autorització del titular.
                        </p>

                        <p>
                            Els usuaris:
                        </p>

                        <p>
                            (1) hauran d'abstenir-se de suprimir, alterar, eludir o manipular qualsevol dispositiu de protecció o sistema de seguretat que estigués instal·lat a les pàgines del titular.
                        </p>
                        <p>
                            (2) podran visualitzar els elements del lloc web, imprimir-los, realitzar còpies o descàrregues de continguts sempre que aquestes accions estiguin dirigides exclusivament al seu ús personal i privat.
                        </p>
                        <p>
                            (3) No podran realitzar enllaços a aquest lloc web sense el consentiment del seu titular i, si s'escau, només es permetran enllaços a la pàgina principal.
                        </p>
                        <p>
                            (4) No es permet l'enllaç a "pàgines finals", actes de "frame" o qualsevol altra manipulació similar a aquesta pàgina sense consentiment exprés i per escrit per part de CATEB llevat que es concedeixi autorització expressa.
                        </p>
                        <p>
                            En cas que el titular detecti un ús il·lícit de qualsevol dels continguts disponibles al seu lloc web, emprendrà les accions judicials corresponents de conformitat amb l'article 140 de la Llei de Propietat Intel·lectual i l'article 270 del Codi Penal en cas que els actes siguin delictius.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7.- Preus dels productes i serveis
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            L'ús de la Web és gratuït, però si es vol adquirir algun dels seus productes, el preu vindrà marcat en cadascun d'ells.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Durada i finalització
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            La prestació del servei de la WEB i d' altres Serveis té, en principi, una durada indefinida. No obstant això, el Cateb està autoritzat per finalitzar o suspendre la prestació del servei Web i/o dels Serveis en qualsevol moment, sense perjudici del que s'hagués disposat pel que fa al tema de les condicions particulars corresponents. Quan això sigui raonablement possible, el Cateb advertirà prèvia a la finalització o suspensió de la prestació del servei de la Web i d'altres Serveis.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Política de cookies
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                9.1.- Definició i funció de les cookies
                            </strong>
                        </p>

                        <p>
                            Què són les cookies? Una cookie o galeta és un arxiu que es descarrega al seu dispositiu en accedir a determinades pàgines web. Les cookies permeten en una pàgina web, entre altres coses, emmagatzemar i recuperar informació sobre els hàbits de navegació d'un usuari o del seu equip i, depenent de la informació que continguin i de la forma en què utilitzi el seu equip, es poden utilitzar per reconèixer l'usuari.
                        </p>

                        <p>
                            <strong>
                                9.2- Quins tipus de cookies utilitza aquesta pàgina web?
                            </strong>
                        </p>

                        <p>
                            • Cookies tècniques i de personalització: necessàries o convenients per a la prestació de determinats serveis. Les primeres permeten a l'usuari la navegació a través de la pàgina web i la utilització dels diferents serveis que hi ha, i les segones permeten accedir a l'usuari al servei amb algunes característiques de caràcter general predefinides. S'utilitzen cookies per l'idioma, pel gestor de continguts i pel manteniment de sessió quan és necessari. Si es desactiven aquestes Cookies es podria no rebre correctament els continguts del lloc web.
                        </p>

                        <p>
                            • Cookies analítiques, ja sigui perquè són tractades per nosaltres o per tercers, permeten fer el seguiment i anàlisi estadística del comportament del conjunt dels usuaris. Si es desactiven aquestes galetes, el lloc web podrà seguir funcionant, sense perjudici que la informació captada per aquestes cookies sobre l'ús del nostre web i sobre el seu contingut permet millorar els nostres serveis.
                        </p>

                        <p>
                            • Cookies de xarxes socials externes, que s'utilitzen perquè els visitants puguin interactuar amb el contingut de diferents plataformes socials (Facebook, YouTube, Twitter, LinkedIn, etc.)
                        </p>

                        <p>
                            • Cookies de publicitat: aquest tipus de "Cookies" permeten ampliar la informació dels anuncis mostrats a cada usuari anònim. Poden ser tractades per nosaltres o per tercers i ens permeten gestionar de la forma més eficaç possible l'oferta d'espais publicitaris que hi ha al web, adequant el contingut de cada anunci al contingut del servei sol·licitat o a l'ús que realitzi de la nostra pàgina web pel qual podrem analitzar els seus hàbits de navegació i podrem mostrar-li publicitat relacionada amb el seu perfil de navegació. Entre d'altres, s'emmagatzema la durada o freqüència de visualització de posicions publicitàries, la interacció amb aquestes, o els patrons de navegació i/o comportaments de l'usuari, ja que ajuden a conformar un perfil d'interès publicitari.
                        </p>

                        <p>
                            <strong>
                                9.3. Forma de desactivar o eliminar les cookies
                            </strong>
                        </p>

                        <p>
                            Vostè pot permetre, bloquejar o eliminar les cookies instal·lades en el seu dispositiu mitjançant la configuració de les opcions del navegador en el vostre dispositiu.
                        </p>

                        <p>
                            •	Configuració d' Internet Explorer
                        </p>
                        <p>
                            •	Configuració de Mozilla Firefox
                        </p>
                        <p>
                            •	Configuració de Google Chrome
                        </p>
                        <p>
                            •	Configuració de Safari
                        </p>

                        <p>
                            En cas que no accepti les cookies és possible que la pàgina web no funcioni correctament.
                        </p>

                        <p>
                            <strong>
                                9.4. Informació sobre la identificació de qui utilitza les cookies
                            </strong>
                        </p>

                        <p>


                            La informació obtinguda per les cookies és tractada pel COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA en què s'ha contractat la prestació del servei d'analítica amb Google Analytics on seguint el link podrà accedir a les seves condicions legals.

                        </p>

                        <p>


                            També pot ser que hi hagi complements d' altres proveïdors en què seguint els següents vincles podrà comprovar les condicions legals respecte a les cookies de cadascun d' ells:

                        </p>

                        <p>
                            Més informació:
                        </p>
                        <p>
                            YouTube
                        </p>
                        <p>
                            Twitter
                        </p>
                        <p>
                            LinkedIn
                        </p>

                        <p>
                            <strong>
                                9.5. Actualització de la política de cookies
                            </strong>
                        </p>

                        <p>
                            És possible que, per noves interpretacions realitzades per l'organisme regulador competent, jutges o Tribunals, aquesta política de cookies pateixi alguna modificació pel qual es prega a l'usuari que comprovi de forma regular el seu contingut.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            10.- JURISDICCIÓ I COMPETÈNCIA
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            En cas que es produeixi qualsevol discrepància quant a l'aplicació o interpretació de les presents condicions, resultarà aplicable el Dret Espanyol i seran competents per conèixer la controvèrsia els Jutjats i Tribunals de Barcelona capital (Espanya).
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            11. PROTECCIÓ DE DADES
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Introducció: Amb la informació que li proporcionarem a continuació el COL. LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (en endavant el COL. LEGI), volem que vostè conegui la política de privacitat aplicada respecte a les seves dades personals.
                        </p>
                        <p>
                            Compliment de la normativa de protecció de dades personals. El Col·legi compleix tots els requisits establerts per la normativa vigent en matèria de protecció de dades personals, i totes les dades sota la nostra responsabilitat són tractades de conformitat amb les exigències legals.
                        </p>
                        <p>
                            Mesures de seguretat: S' aplicaran les corresponents mesures de seguretat, tant tècniques com organitzatives, que garanteixin la confidencialitat de les dades.
                        </p>
                        <p>
                            Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA.
                        </p>
                        <p>
                            CIF	Q0875009C
                        </p>
                        <p>
                            Adreça:	C. Bon Pastor, 5. 08021 Barcelona. Espanya
                        </p>
                        <p>
                            Contacte	Tel: 93 240 20 60; info@cateb.cat.
                        </p>
                        <p>
                            Delegat de Protecció de Dates: Institut Qualitas d’Assessoria BCN SL
                        </p>
                        <p>
                            Contacte del Delegat de Protecció de Dades: dpd@cateb.cat
                        </p>
                        <p>
                            Finalitat: gestió de la relació entre el responsable i l' interessat pel que fa a la gestió de la informació referent als serveis oferts en aquest web.
                        </p>

                        <p>
                            Es comunica que es realitzaran anàlisis de perfils i d' ús a partir de l' historial de serveis i activitats de l' interessat amb la finalitat de millorar i dotar de valor afegit els serveis i activitats a disposició dels usuaris.
                        </p>
                        <p>
                            Legitimació: consentiment de l' interessat.
                        </p>

                        <p>
                            Destinataris: les seves dades només podran ser comunicades a tercers per al compliment de les obligacions legals i contractuals que puguin existir o bé amb el consentiment exprés de l' interessat.
                        </p>

                        <p>
                            Drets dels afectats: En cas necessari, pot exercir els seus drets d' accés, rectificació, supressió i limitació, portabilitat i oposició al tracte de les dades, dirigint-se per escrit o per correu electrònic i acompanyant còpia del document oficial que l' identifiqui al COL. LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA, amb domicili al carrer Bon Pastor, nº 5, Cp 08021, Barcelona, o enviant un e-mail a info@cateb.cat, indicant en el sobre en l'assumpte del missatge: Avís legal i de privacitat. També té dret a formular una reclamació davant l' autoritat de control. Vostè pot revocar el seu consentiment en qualsevol moment.
                        </p>

                        <p>
                            Dades de tercers: si vostè ens facilita dades personals d' un tercer, tindrà, amb caràcter previ, que haver-lo informat i demanat el seu consentiment sobre els extrems aquí exposats.
                        </p>

                        <p>
                            Les dades es conservaran durant tot el període de temps en què es mantingui la seva relació amb nosaltres i posteriorment durant tot el període de prescripció de les accions de responsabilitat.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

        </>
    )
};
