import React from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faFolder, faUser } from '@fortawesome/free-solid-svg-icons'

export const NavLinksInner = ({ list }) => {

    return (
        <div className="py-4">
            {
                list?.map((item, index) => {
                    return (
                        <NavLink
                            activeClassName="active"
                            className="d-flex my-3 nav-link"
                            key={index}
                            exact
                            to={item.link}
                        >
                            <div style={{ 'width': '1.5rem' }} className="me-2">
                                {item.icon}
                            </div>
                            {item.name}
                        </NavLink>
                    )
                })
            }

            {/* <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/historial-resultats"
            >
                <div style={{ 'width': '1.5rem' }} className="me-2">
                    <FontAwesomeIcon icon={faFolder} />
                </div>
                Historial de resultats
            </NavLink>
            <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/les-meves-dades"
            >
                <div style={{ 'width': '1.5rem' }} className="me-2">
                    <FontAwesomeIcon icon={faUser} />
                </div>
                Les meves dades
            </NavLink> */}

        </div>
    )
}
