import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import { images } from '../../helpers/images';

export const NavTopLegal = () => {
    return (
        <section id="NavTop-legal">
            <div className="container d-flex align-items-center justify-content-between flex-column flex-lg-row">
                <Link to="/">
                    <img src='/logo_test rado_CAST.png' className="card-img my-2" alt="logo" id="logo" height='35px' />
                </Link>
                
            </div>
        </section>
    )
}
