import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useLang } from 'language';
import { AuthContext } from '../../../../../auth/AuthContext';
import { CondicionesContratacionEs } from 'pages/CondicionesContratacion/CondicionesContratacionEs';
import { CondicionesContratacionCa } from 'pages/CondicionesContratacion/CondicionesContratacionCa';

export const ModalCondicionesContratacion = ({ showModal, handleCloseModal, setCheckedManifiesto, checkedManifiesto }) => {

    const locale = useLang();

    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    const handleAccionManifiesto = (action) => {

        setCheckedManifiesto(() => {
            return {
                ...checkedManifiesto,
                [showModal.key]: action
            }
        })
        handleCloseModal()
    }

    return (
        <>
            <Modal show={showModal.value} onHide={handleCloseModal} size='lg' scrollable={false}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4' style={{ height: 'auto', overflowY: 'auto' }}>
                    {
                        lang === 'ES' && <CondicionesContratacionEs to={showModal.to} />
                    }

                    {
                        lang === 'CA' && <CondicionesContratacionCa to={showModal.to} />
                    }

                </Modal.Body>

                {/* <Modal.Footer className='d-flex justify-content-between'>
                    <ButtonSpinner
                        variant="secondary" type="submit" className="ms-3 px-3"
                        handleSubmit={() => handleAccionManifiesto(false)}
                        value={locale('RECHAZAR')}
                    />
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3 px-3"
                        handleSubmit={() => handleAccionManifiesto(true)}
                        value={locale('ACEPTAR')}
                    />
                </Modal.Footer> */}

            </Modal>
        </>
    )
}
