import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'react-bootstrap'
import Wizard from "components/Wizard/Wizard";
import { useForm } from "hooks/useForm";
import Solicitud from 'models/solicitudRadon'
import { FormHome } from './FormHome'
import { DatosSolicitud } from './DatosSolicitud'
import { DeterminacionDetectores } from "./DeterminacionDetectores/DeterminacionDetectores";
import { Sensores } from "./Sensores/Sendores";
import { Resultado } from "./Resultado/Resultado";
import { Facturacion } from "./Facturacion/Facturacion";
import { useFetch } from "hooks/useFetch";
import { useLang } from 'language'
import { ModalEliminarSolicitud } from './ModalEliminarSolicitud';
import { toast } from 'react-toastify';
import { useValidator } from 'hooks/useValidator';

export const TestRadonForm = () => {

	const Lang = useLang();
	const [tab, setTab] = useState(false)
	const [indexFocusWizard, setIndexFocusWizard] = useState(0);
	const [direccionType, setdireccionType] = useState(false);
	const [fetchSolicitudRadonGeneral, fetchSolicitudRadonGeneralData, fetchDataNew] = useFetch('GET', `/clientes/solicitudes_radon`)
	const [fetchSend, fetchSentData] = useFetch('POST', `/clientes/solicitudes_radon`)
	const [formTestRadon, handleInputFormTestRadon, handleFormChangeEditar, resetFormEdit] = useForm(new Solicitud())

	const [validDireccionEnvio, handlevalidDireccionEnvio] = useValidator({
		direccion_envio_direccion: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		},
		direccion_envio_numero: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		},
		direccion_envio_piso: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO_NA')]
			}
		},
		direccion_envio_codigo_postal: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		},
		direccion_envio_provincia_id: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		},
		direccion_envio_municipio_id: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		},
		direccion_envio_atencion_de: {
			required: {
				isOk: true,
				msgError: [Lang('V_OBLIGATORIO_CAMPO')]
			}
		}
	})

	const getById = (id) => {
		fetchSolicitudRadonGeneral({
			id: id + '/edit'
		});
	}

	const handleClickGuardarBorrador = (e, tab) => {
		if (e) {
			e.preventDefault();
		}

		if (handlevalidDireccionEnvio(formTestRadon)) {

			fetchSend({
				body: {
					'valid': 'Save',
					tab: tab,
					...formTestRadon
				}
			})
		}
	}

	const hanleClickCambiarSiguiente = (e, tab) => {
		if (e) {
			e.preventDefault();
		}

		if (handlevalidDireccionEnvio(formTestRadon)) {

			fetchSend({
				body: {
					'valid': 'Next',
					tab: tab,
					...formTestRadon
				}
			})
		}

	}

	const { id } = useParams()

	useEffect(() => {
		setTab(false);
		if (Number(id)) {
			setTab(true);
			getById(id);
		}
	}, [id])

	let history = useHistory();

	const navigateTo = (url) => {
		history.push(url)
	}

	const changeTab = (test) => {
		handleFormChangeEditar({
			...formTestRadon,
			tipo_solicitud: test
		});
		setTab(true);
	}

	const listWizard = [
		Lang('TEST_RADON_WIZAR_NIVEL1'),
		Lang('TEST_RADON_WIZAR_NIVEL2'),
		Lang('TEST_RADON_WIZAR_NIVEL3'),
		Lang('MEDICION'),
		Lang('RESULTADO'),
	];

	useEffect(() => {
		if (fetchSolicitudRadonGeneralData.data?.data) {

			const estado = fetchSolicitudRadonGeneralData.data?.data?.estado;

			handleFormChangeEditar(new Solicitud(fetchSolicitudRadonGeneralData.data?.data))

			if (estado === 'radon_estado_finalizado') {

				setIndexFocusWizard(4);

			} else if (estado !== 'radon_estado_borrador') {

				setIndexFocusWizard(3);

			} else {

				setIndexFocusWizard(0);
			}
		}
	}, [fetchSolicitudRadonGeneralData.data])

	useEffect(() => {
		if (fetchSentData.data?.status === 'Success') {
			toast.info(fetchSentData.data?.message);
			const misDatos = fetchSentData.data.data;

			if (!formTestRadon.id) {
				handleFormChangeEditar({
					...formTestRadon,
					id: misDatos.data.id
				})
			}
			window.history.replaceState(null, null, `/test-radon/${misDatos.data.id}`);
			if (misDatos.valid === 'Next') {
				setIndexFocusWizard(indexFocusWizard + 1);
			}
		}

	}, [fetchSentData.data])

	useEffect(() => {

		window.scrollTo({ top: 0, behavior: "smooth" })

	}, [indexFocusWizard]);

	const [showModalEliminarSolicitud, setShowModalEliminarSolicitud] = useState(false);

	const handleCloseModalEliminarSolicitud = () => setShowModalEliminarSolicitud(false);

	const handleShowModalEliminarSolicitud = () => {
		setShowModalEliminarSolicitud(true)
	}

	return (
		<>
			{!tab &&
				<FormHome
					changeTab={changeTab}
				/>
			}
			{tab &&
				<div className="MainInner-wizard shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto mt-4 mb-5 mx-4">
					<h5 className='mt-2 p-3 pb-1 text-center'>{Lang('SOLICITUD_TEST_RADON')}</h5>

					<Wizard>
						<Wizard.Header
							listWizard={listWizard}
							indexFocusWizard={indexFocusWizard}
							setIndexFocusWizard={setIndexFocusWizard}
							enableBack={true}
						/>
						<Wizard.Body indexFocusWizard={indexFocusWizard}>
							<section>
								<DatosSolicitud
									fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
									formTestRadon={formTestRadon}
									handleInputFormTestRadon={handleInputFormTestRadon}
									handleFormChangeEditar={handleFormChangeEditar}
									fetchSentData={fetchSentData}
									handleClickGuardarBorrador={handleClickGuardarBorrador}
									hanleClickCambiarSiguiente={hanleClickCambiarSiguiente}
									setIndexFocusWizard={setIndexFocusWizard}
									validDireccionEnvio={validDireccionEnvio}
								/>
							</section>
							<section>
								<DeterminacionDetectores
									fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
									formTestRadon={formTestRadon}
									handleInputFormTestRadon={handleInputFormTestRadon}
									handleFormChangeEditar={handleFormChangeEditar}
									fetchSentData={fetchSentData}
									handleClickGuardarBorrador={handleClickGuardarBorrador}
									hanleClickCambiarSiguiente={hanleClickCambiarSiguiente}
									setIndexFocusWizard={setIndexFocusWizard}
								/>
							</section>
							<section>
								<Facturacion
									formTestRadon={formTestRadon}
									setIndexFocusWizard={setIndexFocusWizard}
								/>
							</section>
							<section>
								<Sensores
									dataradon={formTestRadon}
									setIndexFocusWizard={setIndexFocusWizard}
									handleFormChangeEditar={handleFormChangeEditar}
								/>
							</section>
							<section>
								<Resultado
									dataradon={formTestRadon}
									setIndexFocusWizard={setIndexFocusWizard}
								/>
							</section>
						</Wizard.Body>
					</Wizard>

					{formTestRadon.id && formTestRadon.estado === 'radon_estado_borrador' && <>

						<div className='p-3 border-top'>
							<Button variant="danger" size='sm' className='ms-3 px-3' onClick={handleShowModalEliminarSolicitud}>
								<FontAwesomeIcon icon={faTrash} className='me-2' /> {Lang('TEST_RADON_ELIMINAR')}
							</Button>
						</div>

						<ModalEliminarSolicitud
							showModalEliminarSolicitud={showModalEliminarSolicitud}
							handleCloseModalEliminarSolicitud={handleCloseModalEliminarSolicitud}
							solicitud_id={formTestRadon.id} />

					</>
					}
				</div>
			}
		</>
	)
}