import React, {useEffect} from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch';
import { useForm } from 'hooks/useForm';
import { useValidator } from 'hooks/useValidator';
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { MsgInputServer } from 'components/MsgInput/MsgInputServer';
import { InputText } from 'components/Inputs/InputText';
import { useLang } from 'language';
import { toast } from 'react-toastify';
import ValidateSpanishID from 'helpers/validateSpanishID';

export const ModalPassword = (props) => {
	const locale = useLang();
	const {data, handleClose} = props;
	const [fetchReset, fetchResetData] = useFetch('POST', `/clientes/auth/reiniciar_password`)

	const [formReset, handleInputChangeReset] = useForm({
        nif: '',
    })

    const [valuesValid, handleVerifiedValues] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DOCUMENTO')]
            }
        },
    })

    const handleSubmitPassword = (e) => {
        e.preventDefault()
        if (handleVerifiedValues(formReset)) {
            fetchReset({
                body: formReset
            })
        }
    }

    const verificarDocumento = (event) => {
        const inputValue = event.target.value.toUpperCase().replace(/\s/, '');
        handleInputChangeReset({target:{
            name: 'nif',
            value: inputValue
        }})
    }

    const analizarDocumento = (e) => {
        const documento = e.target.value;
        const validationResult = ValidateSpanishID(documento);

        if(!validationResult.valid){
            handleInputChangeReset({target:{
                name: 'nif',
                value: ''
            }})
            toast.error(locale('DOCUMENTO_INVALIDO'));
        }else{
            toast.success(locale('DOCUMENTO_CORRECTO'));
        }
    }

    useEffect(() => {
        if (fetchResetData.data?.status === 'Success') {
            toast.success(fetchResetData.data.message);
            handleClose();
        }
    }, [fetchResetData.data])


	return (
		<Modal show={data.value} onHide={handleClose} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{locale('HOME_PASSWORD_TITLE')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3">
                            <InputText
                                label='NIF / NIE'
                                className=''
                                name='nif'
                                value={formReset.nif}
                                onChange={(e) => {
                                    //handleInputFormTestRadon(e)
                                    verificarDocumento(e)
                                }}
                                onBlur={analizarDocumento}
                                placeholder="nif"
                                validation={valuesValid}
                                fetchData={fetchResetData}
                            />
                        </Form.Group>

                        <div className="text-center mb-2">
                            <MsgInputServer obj={fetchResetData.data} />
                        </div>

                        <div className="d-grid gap-2 mt-3 p-2"> 
                         <ButtonSpinner
	                        variant="primary" type="submit" className="ms-2"
	                        handleSubmit={handleSubmitPassword} fetchDataLoading={[fetchResetData.loading]}
	                        value='Recuperar'
	                    />
	                    </div>

                        <div className="text-center">
                            <small>{locale('HOME_PASSWORD_INFO')} <a href="https://caateebcn.my.site.com/SiteLogin" className="text-decoration-none" target="_blank">{locale('HOME_PASSWORD_ENLACE')}</a></small>
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        {locale('TEST_RADON_MODAL_BTN_CERRAR')}
                    </Button>
                </Modal.Footer>
            </Modal>
	);
}