import { faDownload, faEdit, faFile, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useModalData } from "hooks/useModalData";
import { useFetch } from "hooks/useFetch";
import { Loader } from "components/Loader/Loader";
import { useLang } from 'language';
import { ModalDocumento } from "./ModalDocumento";
import { AuthContext } from "../../../../../auth/AuthContext";
import axios from 'axios'
import { getDataFile } from "helpers/helpers";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";

export const Documentos = ({ idSolicitud }) => {

    const lng = useLang();

    const [showStatus, setshowStatus] = useState(false);

    const [dataModal_Documento, handleOpenModal_Documento, handleCloseModal_Documento] = useModalData()

    const [fetchPlantas, fetchPlantasData] = useFetch('GET', `/planos/${idSolicitud}`);

    useEffect(() => {

        fetchPlantas()

    }, [])

    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState({ data: null, loading: null, error: null, status: null });

    const handleFetchFile = (url) => {

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        let config = { responseType: 'blob' };

        config = {
            ...config,
            headers: { Authorization: `Bearer ${token}` }
        }

        axios.get(url, config).then((response) => {
            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    useEffect(() => {

        if (fetchDocumentoData.data) {

            getDataFile(
                fetchDocumentoData,
                setFetchDocumentoData({ data: null, loading: true, error: null, status: null }),
                'File',
                'pdf'
            )

            setshowStatus(false)
        }

    }, [fetchDocumentoData])


    return (
        <>

            {/* <fieldset className="border p-2"> */}
            {/* <legend className="w-auto text-left">{lng('PLANOS')}</legend> */}

            <div className="col-12 mb-3">

                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{lng('PLANOS')}</h5>

                    <Button variant="outline-primary-radon" size='sm' onClick={() => {
                        handleOpenModal_Documento({
                            action: 'CREATE',
                            idSolicitud,
                        })
                    }}>
                        <FontAwesomeIcon icon={faPlus} className='me-1' /> {lng('AGREGAR_PLANO')}
                    </Button>
                </div>

            </div>

            {
                fetchPlantasData.loading ?
                    <Loader />
                    :
                    <>
                        <Table responsive className='tableMain'>
                            <thead>
                                <tr>
                                    <th>{lng('NOMBRE')}</th>
                                    <th className="text-center">{lng('ACCIONES')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fetchPlantasData.data?.data?.map(item => {

                                        return (
                                            <tr key={item.id}>
                                                <td valign="middle">{item.name}</td>
                                                <td valign="middle" style={{ width: '1px' }}>

                                                    <div className="d-flex">
                                                        <Button variant="outline-secondary" size='sm' className='me-3 text-nowrap'
                                                            onClick={
                                                                () => {
                                                                    handleFetchFile(item.link)
                                                                    setshowStatus(true)
                                                                }
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} /> {lng('DESCARGAR')}
                                                        </Button>

                                                        <Button variant="secondary" size='sm' className=''
                                                            onClick={
                                                                () => handleOpenModal_Documento({
                                                                    action: 'EDIT',
                                                                    id: item.id
                                                                })
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    fetchPlantasData.data?.data?.length === 0 &&
                                    <>
                                        <tr className='text-center'>
                                            <td className='py-5 ' colSpan={7}>
                                                <h5 className='fw-normal'>{lng('TEST_RADON_NO_RESULTADO')}</h5>
                                            </td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </Table>
                    </>
            }


            {/* </fieldset> */}

            {dataModal_Documento.value &&
                <ModalDocumento
                    data={dataModal_Documento}
                    handleClose={handleCloseModal_Documento}
                    // parametros={parametros}
                    // dataradon={formTestRadon}
                    fetchSuccess={fetchPlantas}
                />
            }

            <Modal show={showStatus} onHide={() => setshowStatus(false)}>
                <Modal.Header closeButton className='border-0'>
                    {/* <Modal.Title className='h5'>{title}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <p className="my-4 text-center">
                        {lng('P_DESCARGANDO_DOCUMENTO')}
                    </p>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    <Button variant="default" onClick={() => setshowStatus(false)}>
                        {lng('SALIR')}
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="button" className="ms-2" disabled
                        // handleSubmit={handleEliminarMuestra} 
                        fetchDataLoading={[true]}
                        value={lng('DESCARGAR')}
                    />
                </Modal.Footer>
            </Modal>

        </>


    )
};
