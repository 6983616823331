import React, { useEffect, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useLang } from 'language';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ModalInfoRadon = (props) => {
    const locale = useLang();
    const { data, handleClose } = props;

    const history = useHistory()

    return (
        <Modal show={data.value} onHide={handleClose} bsPrefix="modal" className="animate__animated animate__fadeIn">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{locale('HOME_RADON_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <b className='mb-2'>
                            {locale('HOME_RADON_INFO')}
                        </b>

                        <p className="text-dark">
                            {locale('HOME_HEADER_TEXTO_1')}<br /><br />

                            {locale('HOME_HEADER_TEXTO_')} <span className="text-dark"> {locale('HOME_HEADER_TEXTO_SPAN1')}</span> {locale('HOME_HEADER_TEXTO__')} <span className="text-dark"> {locale('HOME_HEADER_TEXTO_SPAN2')},</span> {locale('HOME_HEADER_TEXTO___')}
                        </p>

                        <b>{locale('HOME_RADON_TEXT1')}</b>
                        <p>
                            {locale('HOME_RADON_DESCRIPCION1')}
                        </p>
                        <br /><br />
                        <b>{locale('HOME_RADON_TEXT2')}</b>
                        <p>
                            <span>{locale('HOME_RADON_DESCRIPCION2')}</span> (Bq/m<sup>3</sup>) <span>{locale('HOME_RADON_DESCRIPCION3')}</span>
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <Button variant="default" onClick={handleClose}>
                    {locale('TEST_RADON_MODAL_BTN_CERRAR')}
                </Button>
                <Button variant="primary" onClick={() => history.push('/faqs')}>
                    {locale('PREGUNTAS_FRECUENTES')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}