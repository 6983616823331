import { faEdit, faPlus, faArrowLeft, faArrowRight, faEye, faFile, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { Button, Table, Alert } from "react-bootstrap";
import { useModalData } from "hooks/useModalData";
import { useFetch } from "hooks/useFetch";
import { Loader } from "components/Loader/Loader";
import { ModalSensores } from "./ModalSensores"
import { useLang } from 'language'
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { ModalConfirmarEstado } from "../ModalConfirmarEstado"
import { useForm } from "hooks/useForm";
import { ModalShowStatus } from 'components/ModalStatus/ModalShowStatus'
import { AuthContext } from 'auth/AuthContext';
import { Documentos } from "../Documentos/Documentos";

export const Sensores = (props) => {
    const { user: { lang }, dispatch } = useContext(AuthContext);
    const locale = useLang();
    const { dataradon, setIndexFocusWizard, handleFormChangeEditar } = props;
    const [dataModal_Sensor, handleOpenModal_Sensor, handleCloseModal_Sensor] = useModalData()
    const [fetchSensores, fetchSensoresData] = useFetch('GET', `/clientes/solicitudes_radon_sensores`);
    const [dataModal_ResumenRadon, handleOpenModal_ResumenRadon, handleCloseModal_ResumenRadon] = useModalData()
    const [fetchEstadoSolicitud, fetchEstadoSolicitudData] = useFetch('PATCH', `/clientes/solicitudes_radon`)
    const [formStatus, handleInputChangeStatus, handleFormChangeStatus, resetForm] = useForm({
        estado: '',
    })
    const [showModalConfirm, setShowModalConfirm] = useState(false);

    useEffect(() => {
        fetchSensores({
            params: {
                solicitud_id: dataradon.id
            }
        })
    }, [])

    const reloadSensor = () => {
        handleCloseModal_Sensor();
        fetchSensores({
            params: {
                solicitud_id: dataradon.id
            }
        })
    }

    const handleShowModalConfirm = (data) => {
        handleFormChangeStatus({
            estado: data
        })
        setShowModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        resetForm();
        setShowModalConfirm(false)
    }

    const handleSubmitEstado = (e) => {
        if (e) {
            e.preventDefault();
        }

        fetchEstadoSolicitud({
            id: `${dataradon.id}/cambiar_estado`,
            body: formStatus
        })
    }

    useEffect(() => {
        if (fetchEstadoSolicitudData.data?.status === 'Success') {
            handleFormChangeEditar({
                ...dataradon,
                estado: fetchEstadoSolicitudData.data.data.estado
            });
            handleCloseModalConfirm();
        } else if (fetchEstadoSolicitudData.data?.status === 'Error') {
            handleCloseModalConfirm();
        }

    }, [fetchEstadoSolicitudData.data])

    return (
        <>
            <div className='container pb-0 mt-3'>
                <div className="row">

                <div className="col-12">
                    <h5 className='mb-2 pb-4 text-center'>{locale('MEDICION')}</h5>
                </div>

                    <div className="col-12 mb-1">
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_MEDICION_TITULO')}</legend>
                            {fetchSensoresData.loading ?
                                <Loader />
                                :
                                <>
                                    <Table responsive className='tableMain'>
                                        <thead>
                                            <tr>
                                                {
                                                    ['#', locale('TEST_RADON_MEDICION_TABLE_PLANTA'), locale('N_DETECTOR'), locale('TEST_RADON_MEDICION_TABLE_FECHA_DESDE'), locale('TEST_RADON_MEDICION_TABLE_FECHA_HASTA'), locale('UBICACION_DETECTOR'), ''].map((value, index) => {
                                                        return (
                                                            <th key={index} className="text-center">{value}</th>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {
                                                fetchSensoresData.data?.data?.map(item => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td valign="middle">{item.id}</td>
                                                            <td valign="middle">{item?.planta?.nombre || ''}</td>
                                                            <td valign="middle">{item.numero_detector}</td>
                                                            <td valign="middle">{item.fecha_desde}</td>
                                                            <td valign="middle">{item.fecha_hasta}</td>
                                                            <td valign="middle">{item.nota}</td>
                                                            <td valign="middle">
                                                                <Button disabled={dataradon.estado === 'radon_estado_medicion' ? false : true} variant="secondary" size='sm' className='ms-3 my-0'
                                                                    onClick={
                                                                        () => handleOpenModal_Sensor({
                                                                            action: 'EDIT',
                                                                            id: item.id,
                                                                            solicitud_id: dataradon.id
                                                                        })
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="col-12 mt-2 text-center">
                                        {
                                            dataradon.estado === 'radon_estado_pagado' &&
                                            <ButtonSpinner
                                                variant="warning" type="submit" className="mx-auto px-3"
                                                handleSubmit={() => {
                                                    handleShowModalConfirm('radon_estado_solicitado')
                                                }} fetchDataLoading={[false]}
                                                value={locale('REGISTRAR_INFORMACION')} icon={<FontAwesomeIcon icon={faFile} className="me-2" />}
                                            />
                                        }

                                        {
                                            dataradon.estado === 'radon_estado_solicitado' &&
                                            <>

                                                <div className="mx-auto">
                                                    <div className="d-inline-block me-3">
                                                        <ShowInstruccionesMedidas
                                                            tipo_solicitud={dataradon.tipo_solicitud}
                                                        />
                                                    </div>
                                                    <ButtonSpinner
                                                        variant="primary-radon" type="submit" className="px-3"
                                                        handleSubmit={() => {
                                                            handleShowModalConfirm('radon_estado_medicion')
                                                        }} fetchDataLoading={[false]}
                                                        value={locale('TEST_RADON_RECIBIDO_DETECTORES')} icon={<FontAwesomeIcon icon={faSync} className="me-1" />}
                                                    />
                                                </div>

                                            </>
                                        }

                                        {
                                            dataradon.estado === 'radon_estado_medicion' &&
                                            <>
                                                <div className="mx-auto mb-3">
                                                    <div className="d-inline-block me-3">
                                                        <ShowInstruccionesMedidas
                                                            tipo_solicitud={dataradon.tipo_solicitud}
                                                        />
                                                    </div>
                                                    <ButtonSpinner
                                                        variant="primary" type="submit" className="px-3"
                                                        handleSubmit={() => {
                                                            handleShowModalConfirm('radon_estado_pendiente_analisis')
                                                        }} fetchDataLoading={[false]}
                                                        value={locale('TEST_RADON_ENVIO_LABORATORIO')} icon={<FontAwesomeIcon icon={faSync} className="me-1" />}
                                                    />
                                                </div>
                                            </>
                                        }

                                        {
                                            dataradon.estado === 'radon_estado_pendiente_analisis' &&
                                            <div className="mx-auto">
                                                <Alert variant='info' className='py-1'>
                                                    <small>
                                                        {locale('TEST_RADON_PENDIENTE_ANALISIS')}
                                                    </small>
                                                </Alert>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {dataModal_Sensor.value &&
                                <ModalSensores
                                    data={dataModal_Sensor}
                                    handleClose={handleCloseModal_Sensor}
                                    reloadSensor={reloadSensor}
                                />
                            }
                        </fieldset>

                        {
                            dataradon.tipo_solicitud === 'profesional' &&
                            <Documentos
                                idSolicitud={dataradon.id}
                            />
                        }

                    </div>

                    <div className="col-12 mt-2">
                        <div className="d-flex flex-nowrap justify-content-between align-items-center">
                            <div className='m-0'>
                                <ButtonSpinner
                                    variant="outline-primary-radon" type="submit" className="px-3"
                                    handleSubmit={() => setIndexFocusWizard(2)} fetchDataLoading={[false]}
                                    value={locale('TEST_RADON_VOLVER')} icon={<FontAwesomeIcon icon={faArrowLeft} className="me-1" />}
                                />

                            </div>
                            <div className='m-0'>
                                {dataradon.estado === 'radon_estado_finalizado' &&
                                    <ButtonSpinner
                                        variant="primary" type="submit" className="ms-3 px-3"
                                        handleSubmit={() => setIndexFocusWizard(4)} fetchDataLoading={[false]}
                                        value={locale('TEST_RADON_CONTINUAR')} iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalConfirmarEstado
                showModalConfirm={showModalConfirm}
                handleCloseModal={handleCloseModalConfirm}
                handleSubmitEstado={handleSubmitEstado}
                loadingData={fetchEstadoSolicitudData.loading}
            />

            <ModalShowStatus fechData={fetchEstadoSolicitudData} />
        </>
    )
};

const ShowInstruccionesMedidas = ({ tipo_solicitud }) => {

    // console.log('tipo_solicitud', tipo_solicitud);

    const { user: { lang }, dispatch } = useContext(AuthContext);

    const locale = useLang();

    if (tipo_solicitud === 'profesional') {

        if (lang === 'CA') {

            return (
                <>
                    <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/InstruccionesMedidaRadon Profesionales CAT.pdf'>{locale('DESCARGAR_INSTRUCCIONES')}</a>
                    {/* <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/DccHE-Anejo B Zonas climáticas-adjuntar al profesional CAT-CAST.pdf'>{locale('DESCARGAR_ANEXO')}</a> */}
                </>
            )
        } else {

            return (
                <>
                    <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/InstruccionesMedidaRadon Profesionales CAST.pdf'>{locale('DESCARGAR_INSTRUCCIONES')}</a>
                    {/* <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/DccHE-Anejo B Zonas climáticas-adjuntar al profesional CAT-CAST.pdf'>{locale('DESCARGAR_ANEXO')}</a> */}
                </>
            )
        }
    } else if (tipo_solicitud === 'personal') {

        if (lang === 'CA') {

            return (
                <>
                    <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/InstruccionesMedidaRadon Usuarios CAT.pdf'>{locale('DESCARGAR_INSTRUCCIONES')}</a>
                </>
            )
        } else {

            return (
                <>
                    <a download className="btn btn-secondary mx-2" href='/files/instrucciones-medida/InstruccionesMedidaRadon Usuarios CAST.pdf'>{locale('DESCARGAR_INSTRUCCIONES')}</a>
                </>
            )
        }
    }

}