import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch'
import { useForm } from 'hooks/useForm'
import { useValidator } from 'hooks/useValidator'
import { InputText } from 'components/Inputs/InputText'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useLang } from 'language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Loader } from 'components/Loader/Loader'

export const ModalSensores = ({ data, handleClose, dataradon, reload, setTotaSensores}) => {

    const locale = useLang()

    const [formSensor, handleInputChangeSensor, form, resetForm] = useForm({
        solicitud_id: dataradon?.id,
        total_sensores: ''
    })

    const RULES = {
        total_sensores: {
            required: {
                isOk: true,
                msgError: [locale('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
    }

    const [fetchGuardarTotal, fetchGuardarTotalData] = useFetch('POST', `/clientes/solicitudes_radon_sensores`)

    const [valuesValidSensores, handleVerifiedValuesSensores] = useValidator(RULES)

    const handleSubmitAgregarSensor = (e) => {
        e.preventDefault()
        if (handleVerifiedValuesSensores(formSensor)) {
            fetchGuardarTotal({
                body: {
                    ...formSensor
                }
            })
        }
    }

    useEffect(() => {
        if (fetchGuardarTotalData.data?.status === 'Success') {
            resetForm()
            handleClose();
            setTotaSensores(fetchGuardarTotalData.data.total)
            reload()
        }
    }, [fetchGuardarTotalData.data])

    const handleCloseModal = (e) => {
        resetForm();
        handleClose()
    }

    return (
        <>
            <Modal show={data.value} onHide={handleCloseModal} size='sm'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>
                        {'Agregar Detectores'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Form onSubmit={handleSubmitAgregarSensor} className='row'>
                            <Form.Group className="col-12 col-md-12 col-lg-12 mb-3">
                                <InputText
                                    label='Cant. sensores'
                                    className=''
                                    type="number"
                                    numbered="true"
                                    name='total_sensores'
                                    value={formSensor.total_sensores}
                                    onChange={handleInputChangeSensor}
                                    placeholder="planta"
                                    validation={valuesValidSensores}
                                    fetchData={fetchGuardarTotalData}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="default" onClick={handleCloseModal}>
                    {locale('SALIR')}
                    </Button>

                    <ButtonSpinner
                            variant="primary-radon" type="submit" className="ms-2"
                            handleSubmit={handleSubmitAgregarSensor} fetchDataLoading={[fetchGuardarTotalData.loading]}
                            value={locale('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                        />
                </Modal.Footer>
            </Modal>
        </>
    )
}
