import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { InputSelect } from "components/Inputs/InputSelect";
import { InputText } from "components/Inputs/InputText";
import { useLang } from 'language';

export const TipoEdificio = (props) => {

    const locale = useLang();
    const { formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData, parametros, fetchSentData} = props;

    return (
        <>
        <fieldset className="border p-2">
            <legend className="w-auto text-left">{locale('TEST_RADON_DATOS_EDIFICIO')}</legend>
            <div className="row">   
                <Form.Group className="mb-3 col-md-6 col-lg-5">
                    <InputSelect
                      label={locale('TEST_RADON_TIPO_EDIFICIO')}
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      className=''
                      name='edificio_tipo_edificio'
                      values={
                        parametros.data?.data?.tipo_edificio?.map((item, index) => {
                            return {
                               id: item.param_id,
                               nombre: item.texto
                            }
                        })
                      }
                      value={formTestRadon.edificio_tipo_edificio}
                      onChange={handleInputFormTestRadon}
                      placeholder="edificio_tipo_edificio"
                      fetchData={fetchSentData}
                    />
                  </Form.Group>
                  {
                    formTestRadon.edificio_tipo_edificio === 'otros' && <>
                      <Form.Group className="mb-3 col-md-6 col-lg-7">
                        <InputText
                          label={locale('TEST_RADON_OTROS')}
                          disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                          className=''
                          name='edificio_otros'
                          value={formTestRadon.edificio_otros}
                          onChange={handleInputFormTestRadon}
                          placeholder="otros"
                          fetchData={fetchSentData}
                        />
                      </Form.Group>
                    </>
                  }
             </div>   
          </fieldset>   
        </>
    );
};
