import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { LayoutNavs } from '../components/LayoutNavs/LayoutNavs';
import { useLang } from '../language';
import { MisDatos } from '../pages/MisDatos/MisDatos';
import { HistorialResultats } from '../pages/HistorialResultats/HistorialResultats';
import { Sollicitud } from '../pages/Sollicitud/Sollicitud';
import ModalProteccionDatos from '../components/Modal/ModalProteccionDatos';
import { useFetch } from '../hooks/useFetch';
import { TestRadon } from '../pages/Solicitudes/TestRadon/TestRadon';
//import { TestRadonDetalle } from '../pages/Solicitudes/TestRadon/TestRadonDetalle/TestRadonDetalle';

import {TestRadonForm} from 'pages/Solicitudes/TestRadon/Form/FormDetalle'

import { Inicio } from '../pages/Inicio/Inicio';
import { Footer } from 'components/Footer/Footer';

export const DashboardRoutes = ({ authed }) => {

    // const { user } = useContext(AuthContext);

    const Lang = useLang();

    return (
        <>
            <LayoutNavs />

            <div className="Side-end container-fluid">
                <Switch>

                    {/*
                    <Route
                        exact
                        path="/panel"
                        component={() => <TestRadonForm />}
                    />
                    */}

                    <Route
                        exact
                        path="/test-radon"
                        component={() => <TestRadon namePage={Lang('COLEGIOS')} />}
                    />

                    <Route
                        exact
                        path="/test-radon/:id"
                        component={() => <TestRadonForm />}
                    />

                    <Route
                        exact
                        path="/les-meves-dades"
                        component={() => <MisDatos />}
                    />

                    <Redirect to="/test-radon/nuevo" />

                </Switch>
                <div className="mt-auto">
                    <Footer />
                </div>
            </div>
        </>
    )
}