import React, { useEffect, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch';
import { useForm } from 'hooks/useForm';
import { useValidator } from 'hooks/useValidator';
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { MsgInputServer } from 'components/MsgInput/MsgInputServer';
import { InputText } from 'components/Inputs/InputText';
import { useLang } from 'language';
import { AuthContext } from '../../auth/AuthContext';
import { ModalStatusServer } from 'components/ModalStatus/ModalStatusServer';

export const ModalContacto = ({ data, handleClose }) => {

    const locale = useLang();

    const [formContacto, handleInputContacto] = useForm({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    })

    const { user } = useContext(AuthContext)

    const [valuesValid, handleVerifiedValues] = useValidator({
        name: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        },
    })

    const [valuesValidAuth, handleVerifiedValuesAuth] = useValidator({
        subject: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CAMPO')]
            }
        }
    })

    const [fetchContactoPOST, fetchContactoPOSTData] = useFetch('POST', `/email_contacto`)

    const handleSubmitContacto = (e) => {

        e.preventDefault()

        if (user.logged) {

            if (handleVerifiedValuesAuth(formContacto)) {

                fetchContactoPOST({
                    body: formContacto
                })

            }
        } else {

            if (handleVerifiedValues(formContacto) && handleVerifiedValuesAuth(formContacto)) {

                fetchContactoPOST({
                    body: formContacto
                })

            }
        }

    }

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size="md" dialogClassName='modal-medium'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{locale('HOME_CONTACTO_TITLE')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <p>
                    {locale('P_CONTACTAR_CON_NOSOTROS')}: <a className='color-primary' href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>
                </p> */}
                    <div className="row justify-content-center">

                        <div className="col-lg-12">

                            <Form className="row">

                                {
                                    !user?.logged &&
                                    <>
                                        <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                            <InputText
                                                label={locale('NOMBRE_APELLIDOS') + ' *'}
                                                className=''
                                                name='name'
                                                value={formContacto.nombreapellidos}
                                                onChange={handleInputContacto}
                                                placeholder="detector"
                                                validation={valuesValid}
                                            //fetchData={fetchEditarSensorData}

                                            />
                                        </Form.Group>

                                        <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                            <InputText
                                                label={locale('EMAIL') + ' *'}
                                                className=''
                                                name='email'
                                                value={formContacto.email}
                                                onChange={handleInputContacto}
                                                placeholder="email"
                                                validation={valuesValid}
                                            //fetchData={fetchEditarSensorData}

                                            />
                                        </Form.Group>
                                    </>
                                }

                                <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                    <InputText
                                        label={locale('TELEFONO')}
                                        className=''
                                        name='phone'
                                        value={formContacto.phone}
                                        onChange={handleInputContacto}
                                        placeholder="phone"
                                        validation={valuesValid}
                                    //fetchData={fetchEditarSensorData}
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputText
                                        label={locale('ASUNTO') + ' *'}
                                        className=''
                                        name='subject'
                                        value={formContacto.subject}
                                        onChange={handleInputContacto}
                                        placeholder="subject"
                                        validation={valuesValidAuth}
                                    //fetchData={fetchEditarSensorData}

                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-md-12 col-lg-12 mb-0">
                                    <InputText
                                        label={locale('MENSAJE')}
                                        className=''
                                        name='message'
                                        as="textarea"
                                        rows="3"
                                        value={formContacto.message}
                                        onChange={handleInputContacto}
                                        placeholder="asunto"
                                        style={{ height: '150px' }}
                                        validation={valuesValid}
                                    //fetchData={fetchEditarSensorData}

                                    />
                                </Form.Group>

                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="default" onClick={handleClose}>
                        {locale('TEST_RADON_MODAL_BTN_CERRAR')}
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitContacto} fetchDataLoading={[fetchContactoPOSTData.loading]}
                        value={locale('HOME_CONTACTO_BTN')}
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatusServer 
                fetchData={fetchContactoPOSTData} 
                message2={fetchContactoPOSTData?.data?.data?.message || ''}
            />
        </>
    );
}