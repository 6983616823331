import React, { useEffect, useState } from 'react'
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useFetch } from 'hooks/useFetch'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useLang } from 'language';
import { MsgInputServer } from 'components/MsgInput/MsgInputServer';

export const ModalInfoPagador = ({ showModal, handleCloseModal, handleChangePagador, dataradon, handleChangeDocumento, 
    handleShowModalNuevoPagador, formMisDatos, pagador, documento, fetchMisDatos }) => {

    const locale = useLang();

    const [fetchCambiarPagador, fetchCambiarPagadorData] = useFetch('POST', `/clientes/solicitudes_radon`)

    const handleChangeAMisDatosFacturacion = () => {
        fetchCambiarPagador({
            id: `${dataradon.id}/cambiar_pagador`,
            body: {
                // documento: formMisDatos.datos_fact_documento,
                mis_datos: true
            }
        })
    }

    const handleCambiarPagador = () => {
        fetchCambiarPagador({
            id: `${dataradon.id}/cambiar_pagador`,
            body: {
                documento: documento,
            }
        })
    }

    useEffect(() => {

        // console.log(fetchCambiarPagadorData);
        if (fetchCambiarPagadorData.data?.status === 'Error') {

            if (fetchCambiarPagadorData.data?.message !== 'form_error') {
                handleCloseModal(false)
                handleShowModalNuevoPagador();
            }

        } else if (fetchCambiarPagadorData.data?.status === 'Success') {
            fetchMisDatos()
            handleCloseModal(false)
        }

    }, [fetchCambiarPagadorData])

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} size=''>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <div className="container">
                        <div className="row justify-content-center flex-column align-items-center">
                            <div className="col-12 mb-4">
                                <h5 className='text-center'>{locale('RADON_MODAL_PAGADOR_TITLE')}</h5>
                            </div>

                            <div className="col-lg-10 mb-4">
                                <FloatingLabel
                                    controlId="floatingSelect"
                                    label={locale('RADON_MODAL_PAGADOR_INPUT_PAGADOR')}
                                    onChange={handleChangePagador}
                                >
                                    <Form.Select aria-label="Floating label select example">
                                        <option></option>
                                        <option value="1">{locale('RADON_MODAL_PAGADOR_OPTION1')}</option>
                                        <option value="2">{locale('RADON_MODAL_PAGADOR_OPTION2')}</option>
                                        <option value="3">{locale('RADON_MODAL_PAGADOR_OPTION3')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            {
                                pagador === 1 && <>
                                    <div className="mb-1 col-lg-10 mt-0">
                                        <div className="d-flex align-items-center">
                                            <ButtonSpinner
                                                variant="primary" type="submit" className="mx-auto px-3"
                                                handleSubmit={handleChangeAMisDatosFacturacion} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value={locale('RADON_MODAL_PAGADOR_GUARDAR_PAGADOR')}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                pagador === 2 && <>
                                    <div className="mb-4 col-lg-10 mt-2">
                                        <div className="d-flex align-items-center">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="CIF *"
                                                className='flex-grow-1'
                                            >
                                                <Form.Control
                                                    type="text"
                                                    name='nif'
                                                    onChange={handleChangeDocumento}
                                                    placeholder="nif"
                                                />
                                            </FloatingLabel>


                                            <ButtonSpinner
                                                variant="primary" type="submit" className="ms-3 px-3"
                                                handleSubmit={handleCambiarPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value='' icon={<FontAwesomeIcon icon={faSearch} />}
                                            />
                                        </div>
                                        <MsgInputServer obj={fetchCambiarPagadorData.data} name='documento' />

                                    </div>
                                </>
                            }
                            {
                                pagador === 3 && <>
                                    <div className="mb-4 col-lg-10 mt-2">
                                        <div className="d-flex align-items-center">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="NIF *"
                                                className='flex-grow-1'
                                            >
                                                <Form.Control
                                                    type="text"
                                                    name='nif'
                                                    placeholder="nif"
                                                    onChange={handleChangeDocumento}
                                                />
                                            </FloatingLabel>

                                            <ButtonSpinner
                                                variant="primary" type="submit" className="ms-3 px-3"
                                                handleSubmit={handleCambiarPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value='' icon={<FontAwesomeIcon icon={faSearch} />}
                                            />
                                        </div>
                                        <MsgInputServer obj={fetchCambiarPagadorData.data} name='documento' />

                                    </div>
                                </>
                            }

                        </div>

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}
