import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useLang } from 'language';
import { ModalBasicoForm } from './ModalBasicoForm';

export const ModalBasico = (props) => {
    const locale = useLang();

    const { data, handleClose } = props;

    return (
        <Modal show={data.value} onHide={handleClose} size="lg" bsPrefix="modal" className="animate__animated animate__fadeIn">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{locale('HOME_RADON_BASICO_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <ModalBasicoForm />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    {locale('TEST_RADON_MODAL_BTN_CERRAR')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}