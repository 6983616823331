import { types } from '../types/types';

// const state = {
//     name: 'Daniel',
//     logged: true
// }


export const authReducer = (state = {}, action) => {

    console.log(action)

    const lang = action.lang

    switch (action.type) {

        case types.login:
            return {
                lang,
                ...action.payload,
                logged: true
            }

        case types.logout:
            sessionStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE + '_PROTECCION_DATOS');
            return {
                lang,
                logged: false
            }

        case types.changeLanguage:

            const newObj = {
                lang,
                ...action.payload,
            }

            sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE, JSON.stringify(newObj));
            sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE', action.payload?.lang);
            
            return newObj

        default:
            return state;
    }

}