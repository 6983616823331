import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useFetch } from 'hooks/useFetch'
import { useLang } from 'language'

export const ModalEliminarSolicitud = ({ showModalEliminarSolicitud, handleCloseModalEliminarSolicitud, solicitud_id }) => {
    const locale = useLang();
    const history = useHistory()

    const [fetchEliminarSolicitud, fetchEliminarSolicitudData] = useFetch('DELETE', `/clientes/solicitudes_radon`)

    const handleEliminarSolicitud = () => {
        fetchEliminarSolicitud({
            id: solicitud_id+'/delete'
        })
    }

    useEffect(() => {
        if (fetchEliminarSolicitudData.data?.status === 'Success') {
            history.push('/test-radon')
        }
    }, [fetchEliminarSolicitudData.data])

    return (
        <>
            <Modal show={showModalEliminarSolicitud} onHide={handleCloseModalEliminarSolicitud} size='md' id='ModalEliminarSolicitud'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h5 className='text-center'>{locale('TEST_RADON_CONFIRMAR_BORRADOR')}</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalEliminarSolicitud}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary-aluminosis" type="submit" className="ms-2"
                        handleSubmit={handleEliminarSolicitud} fetchDataLoading={[fetchEliminarSolicitudData.loading]}
                        value={locale('TEST_RADON_BORRADOR_BTN')}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
