import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useFetch } from "hooks/useFetch";
import { useValidator } from 'hooks/useValidator';
import { useForm } from "hooks/useForm";
import { InputSelect } from "components/Inputs/InputSelect";
import { Loader } from "components/Loader/Loader";
import InputSelectAutocomplete from "components/Inputs/InputSelectAutocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useLang } from 'language';

export default function ModalZonas(props){

    const locale = useLang()

	const {data, handleClose, dataradon, provinciaId, municipioId, handleUpdateZona, handleZonaNoDefinida} = props;
	const [formZona, handleInputChangeZona, form, resetFormZona] = useForm({
	 	 zona_id: '',
	 	 municipio_id: municipioId,
	})
	const RULES = {
        zona_id: {
            required: {
                isOk: true,
                msgError: [locale('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
    };
	const [valuesValidZona, handleVerifiedValuesZona] = useValidator(RULES)
	const [fetchMunicipios, fetchMunicipiosData] = useFetch('GET', `/parametros/municipio_zonas`);
    const [fetchZona, fetchZonaData] = useFetch('POST', `/parametros/municipio_zonas_asignar`);
	const handleCloseModal = (e) => {
        handleClose()
    }
    const handleSubmitData = (e) => {
        if(e){
            e.preventDefault();
        }

        if (handleVerifiedValuesZona(formZona)) {
            fetchZona({
                body: {
                    ...formZona
                }
            })
        }
    } 

    const handleAddZonaNoDefinida = (e) => {
        if(e){
            e.preventDefault();
        }
        handleClose()
        handleZonaNoDefinida()
    }

    useEffect(() => {
      fetchMunicipios({
        	id: provinciaId
       });	
    },[])

    useEffect(() => {
      if (fetchZonaData.data?.status === 'Success') {
            handleUpdateZona(fetchZonaData.data.data);
            handleClose();
      }
  }, [fetchZonaData.data])

	return (
		<>
			<Modal show={data.value} onHide={handleCloseModal}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>
                        Buscar Municipio
                    </Modal.Title>
                </Modal.Header>
                {fetchMunicipiosData.loading ? <Loader /> : <>
                <Modal.Body>
                    <div className="container">
                    	<div className='row'>
                            <Form.Group className="mb-3">
                                <InputSelectAutocomplete 
                                 options={fetchMunicipiosData.data?.data}
                                 name="zona_id"
                                 value={formZona.zona_id}
                                 handleInputForm={handleInputChangeZona}
                                 placeholder="Seleccione un municipio"
                                />	
                            </Form.Group>
                        </div>    
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="default" onClick={handleCloseModal}>
                    {locale('SALIR')}
                    </Button>
                    <Button variant="danger" onClick={handleAddZonaNoDefinida}>
                        Zona no definida
                    </Button>

                    <ButtonSpinner
                            variant="primary-radon" type="submit" className="ms-2"
                            handleSubmit={handleSubmitData} fetchDataLoading={[fetchZonaData.loading]}
                            value={locale('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                        />
                </Modal.Footer>
                </>
             }
            </Modal>
		</>
	);
}