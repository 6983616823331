import React, { useEffect, useState } from "react";
import _ from "lodash";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { InputText } from "../../components/Inputs/InputText";
import { ListAutocomplete } from "../../components/ListAutocomplete/ListAutocomplete";

export const InputAutocomplete = ({ title = 'Adreça*', name, value, handleInputForm, fetchSentData,
  obtenerData, disabled = false, validation }) => {

  const { ref, placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_GEO_API_KEY,
    options: {
      types: ['address'],
    }
  });

  const handleResetPredictions = () => {
    getPlacePredictions({ input: '' });
  }

  const getPlaceDetails = (placeId) => {

    return new Promise(function (resolve, reject) {

      placesService.getDetails({
        placeId,
      },
        (place) => {
          resolve(place);
        }
      );
    });
  };

  const handleSelectOption = async (place) => {
    const placeDetails = await getPlaceDetails(place.place_id)
    const location = _.groupBy(placeDetails?.address_components, 'types')
    obtenerData(location);
    handleResetPredictions()
  }

  const [isHoverAutocomplete, setisHoverAutocomplete] = useState(false);

  return (
    <>
      <div className="form-floating">
        <InputText
          disabled={disabled}
          label={title}
          className=''
          name={name}
          value={value}
          onChange={(evt) => {
            getPlacePredictions({ input: evt.target.value });
            handleInputForm(evt);
          }}
          placeholder="via"
          fetchData={fetchSentData}
          onBlur={(e) => { isHoverAutocomplete ? e.preventDefault() : handleResetPredictions() }}
          validation={validation}
        />
        <ListAutocomplete
          list={placePredictions}
          handleClick={handleSelectOption}
          nameTitle='description'
          onMouseOver={() => setisHoverAutocomplete(true)}
          onMouseOut={() => setisHoverAutocomplete(false)}
        />
      </div>
    </>

  );
};
