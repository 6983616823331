import React, { useState, useContext, useEffect } from "react";
import { InputRadios } from "components/Inputs/InputRadios";
import { Form } from "react-bootstrap";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSave } from "@fortawesome/free-solid-svg-icons";
import { TipoTest } from "./Components/TipoTest";
import { TipoEdificio } from "./Components/TipoEdificio";
import { DatosPromotor } from "./Components/DatosPromotor";
import { DatosEdificio } from "./Components/DatosEdificio";
import { DatosEnvioSensores } from './Components/DatosEnvioSensores'
import { AuthContext } from "auth/AuthContext";
import { useFetch } from "hooks/useFetch";
import { Loader } from "components/Loader/Loader";
import { useLang } from 'language';

export const DatosSolicitud = ({ formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData, handleFormChangeEditar,
    fetchSentData, handleClickGuardarBorrador, hanleClickCambiarSiguiente, setIndexFocusWizard, validDireccionEnvio }) => {

    const locale = useLang();

    const {
        user: { lang },
    } = useContext(AuthContext);
    const [fetchParametros, fetchParametrosData] = useFetch("POST", `/parametros_generales`);

    const params = [
        {
            param: "tipo_intervencion",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "tipo_medicion",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "tipo_ampliacion",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "tipo_edificio",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "tipo_persona_juridica",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "soluciones_edificio_nuevo_zona_i",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "soluciones_edificio_nuevo_zona_ii",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "soluciones_edificio_existente_zona_i",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "soluciones_edificio_existente_zona_ii",
            locale: String(lang).toLowerCase(),
            active: true,
        },
        {
            param: "motivo_mediacion",
            locale: String(lang).toLowerCase(),
            active: true,
        },
    ];

    const [fetchProvincias, fetchProvinciasData] = useFetch("GET", `/parametros/provincias`);

    const handleChange = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            handleFormChangeEditar({
                ...formTestRadon,
                detalle_zonas: [...formTestRadon.detalle_zonas, { param_id: value }],
            });
        } else {
            const items = formTestRadon.detalle_zonas.filter((item) => item.param_id !== value);
            handleFormChangeEditar({
                ...formTestRadon,
                detalle_zonas: [...items],
            });
        }
    };

    useEffect(() => {
        fetchParametros({
            body: params,
        });

        fetchProvincias()
    }, []);

    const renderTipoEdificio = () => {

        if (formTestRadon.tipo_intervencion === "edificio_existente" && formTestRadon.tipo_ampliacion) {
            return (
                <TipoEdificio
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                />
            )
        } else if (formTestRadon.tipo_intervencion === 'edificio_nuevo') {
            return (
                <TipoEdificio
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                />
            )
        } else if (formTestRadon.tipo_medicio) {
            return (
                <TipoEdificio
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                />
            )
        }
    }

    const renderPromotor = () => {

        if (formTestRadon.edificio_tipo_edificio) {
            return (
                <>
                    <DatosPromotor
                        formTestRadon={formTestRadon}
                        handleInputFormTestRadon={handleInputFormTestRadon}
                        fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                        parametros={fetchParametrosData}
                        fetchSentData={fetchSentData}
                        handleFormChangeEditar={handleFormChangeEditar}
                    />

                    <DatosEdificio
                        formTestRadon={formTestRadon}
                        handleInputFormTestRadon={handleInputFormTestRadon}
                        fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                        parametros={fetchParametrosData}
                        fetchSentData={fetchSentData}
                        handleFormChangeEditar={handleFormChangeEditar}
                        fetchProvinciasData={fetchProvinciasData}
                    />
                </>
            )
        }
    }

    const renderDatosEnvio = () => {

        if (formTestRadon.tipo_intervencion === "edificio_existente" && formTestRadon.tipo_ampliacion) {
            return (
                <DatosEnvioSensores
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                    fetchProvinciasData={fetchProvinciasData}
                    validDireccionEnvio={validDireccionEnvio}
                />

            )
        } else if (formTestRadon.tipo_intervencion === 'edificio_nuevo') {
            return (
                <DatosEnvioSensores
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                    fetchProvinciasData={fetchProvinciasData}
                    validDireccionEnvio={validDireccionEnvio}
                />

            )
        } else if (formTestRadon.tipo_medicio) {
            return (
                <DatosEnvioSensores
                    formTestRadon={formTestRadon}
                    handleInputFormTestRadon={handleInputFormTestRadon}
                    fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                    parametros={fetchParametrosData}
                    fetchSentData={fetchSentData}
                    handleFormChangeEditar={handleFormChangeEditar}
                    fetchProvinciasData={fetchProvinciasData}
                    validDireccionEnvio={validDireccionEnvio}
                />

            )
        }
    }

    const [active, setActive] = useState(-1);


    return (
        <>
            {fetchSolicitudRadonGeneralData.loading || fetchParametrosData.loading ? (
                <Loader />
            ) : (
                <>
                    <div className="col-12 mt-3">
                        <h5 className='mb-3 pb-4 text-center'>{locale('TEST_RADON_WIZAR_NIVEL1')}</h5>
                    </div>
                    <TipoTest
                        formTestRadon={formTestRadon}
                        handleInputFormTestRadon={handleInputFormTestRadon}
                        fetchSolicitudRadonGeneralData={fetchSolicitudRadonGeneralData}
                        parametros={fetchParametrosData}
                        fetchSentData={fetchSentData}
                    />

                    {
                        renderTipoEdificio()
                    }

                    {
                        renderPromotor()
                    }

                    {formTestRadon.tipo_intervencion === "edificio_nuevo" && formTestRadon.edificio_zona === "Zona I" && (
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_SOLUCIONES_TITULO')}</legend>
                            <div className="row">
                                {fetchParametrosData.data?.data?.soluciones_edificio_nuevo_zona_i?.map((check, index) => (
                                    <div key={index} className="mb-3">
                                        <Form.Check // prettier-ignore
                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                            type="checkbox"
                                            id={`${check.param_id}`}
                                            label={check.texto}
                                            value={check.param_id}
                                            checked={formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id)}
                                            onChange={handleChange}
                                        />

                                        {check.es_input && formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id) && (
                                            <Form.Control
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                size="sm"
                                                value={formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id)?.valor || ""}
                                                type="text"
                                                placeholder="Ingrese un valor"
                                                onChange={(e) => {
                                                    const item = formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id);
                                                    if (item) {
                                                        item.valor = e.target.value;
                                                        handleFormChangeEditar({
                                                            ...formTestRadon,
                                                            detalle_zonas: [...formTestRadon.detalle_zonas],
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    )}

                    {formTestRadon.tipo_intervencion === "edificio_nuevo" && formTestRadon.edificio_zona === "Zona II" && (
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_SOLUCIONES_TITULO')}</legend>
                            <div className="row">
                                {fetchParametrosData.data?.data?.soluciones_edificio_nuevo_zona_ii?.map((check, index) => (
                                    <div key={index} className="mb-3">
                                        <Form.Check // prettier-ignore
                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                            type="checkbox"
                                            id={`${check.param_id}`}
                                            label={check.texto}
                                            value={check.param_id}
                                            checked={formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id)}
                                            onChange={handleChange}
                                        />

                                        {check.es_input && formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id) && (
                                            <Form.Control
                                                size="sm"
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                value={formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id)?.valor || ""}
                                                type="text"
                                                placeholder="Ingrese un valor"
                                                onChange={(e) => {
                                                    const item = formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id);
                                                    if (item) {
                                                        item.valor = e.target.value;
                                                        handleFormChangeEditar({
                                                            ...formTestRadon,
                                                            detalle_zonas: [...formTestRadon.detalle_zonas],
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    )}

                    {['Zona I', 'Zona II'].includes(formTestRadon.edificio_zona) && formTestRadon.tipo_intervencion === "edificio_existente" && (
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_MOTIVO_MEDIACION')}</legend>
                            <div className="row">
                                <InputRadios
                                    disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                    name="motivo_medicio"
                                    onChange={handleInputFormTestRadon}
                                    //validation={type === 'borrador' ? valuesValidBorrador : valuesValidNext}
                                    fetchData={fetchSentData}
                                    values={fetchParametrosData.data?.data?.motivo_mediacion?.map((item, index) => {
                                        return {
                                            id: item.param_id,
                                            nombre: item.texto,
                                        };
                                    })}
                                    value={formTestRadon.motivo_medicio}
                                />
                            </div>
                        </fieldset>
                    )}

                    {formTestRadon.motivo_medicio === "intervencion_b" && formTestRadon.tipo_intervencion === "edificio_existente" && formTestRadon.edificio_zona === "Zona I" && (
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_SOLUCIONES_TITULO')}</legend>
                            <div className="row">
                                {fetchParametrosData.data?.data?.soluciones_edificio_existente_zona_i?.map((check, index) => (
                                    <div key={index} className="mb-3">
                                        <Form.Check // prettier-ignore
                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                            type="checkbox"
                                            id={`${check.param_id}`}
                                            label={check.texto}
                                            value={check.param_id}
                                            checked={formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id)}
                                            onChange={handleChange}
                                        />

                                        {check.es_input && formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id) && (
                                            <Form.Control
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                size="sm"
                                                value={formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id)?.valor || ""}
                                                type="text"
                                                placeholder="Ingrese un valor"
                                                onChange={(e) => {
                                                    const item = formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id);
                                                    if (item) {
                                                        item.valor = e.target.value;
                                                        handleFormChangeEditar({
                                                            ...formTestRadon,
                                                            detalle_zonas: [...formTestRadon.detalle_zonas],
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    )}

                    {formTestRadon.motivo_medicio === "intervencion_b" && formTestRadon.tipo_intervencion === "edificio_existente" && formTestRadon.edificio_zona === "Zona II" && (
                        <fieldset className="border p-2">
                            <legend className="w-auto text-left">{locale('TEST_RADON_SOLUCIONES_TITULO')}</legend>
                            <div className="row">
                                {fetchParametrosData.data?.data?.soluciones_edificio_existente_zona_ii?.map((check, index) => (
                                    <div key={index} className="mb-3">
                                        <Form.Check // prettier-ignore
                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                            type="checkbox"
                                            id={`${check.param_id}`}
                                            label={check.texto}
                                            value={check.param_id}
                                            checked={formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id)}
                                            onChange={handleChange}
                                        />

                                        {check.es_input && formTestRadon.detalle_zonas.some((item) => item.param_id === check.param_id) && (
                                            <Form.Control
                                                size="sm"
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                value={formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id)?.valor || ""}
                                                type="text"
                                                placeholder="Ingrese un valor"
                                                onChange={(e) => {
                                                    const item = formTestRadon.detalle_zonas.find((item) => item.param_id === check.param_id);
                                                    if (item) {
                                                        item.valor = e.target.value;
                                                        handleFormChangeEditar({
                                                            ...formTestRadon,
                                                            detalle_zonas: [...formTestRadon.detalle_zonas],
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    )}

                    {
                        renderDatosEnvio()
                    }

                    <div className="col-12 mt-2">
                        <div className="d-flex flex-nowrap justify-content-between align-items-center">
                            <div className='m-0'>
                                <small className='mb-0'>
                                </small>
                            </div>
                            <div className='m-0'>

                                {formTestRadon.estado === 'radon_estado_borrador' &&

                                    <ButtonSpinner
                                        variant="primary" type="submit" className="ms-3 px-3"
                                        handleSubmit={(e) => {
                                            setActive(0)
                                            handleClickGuardarBorrador(e, 0)
                                        }} fetchDataLoading={[fetchSentData.loading && active === 0]}
                                        value={locale('TEST_RADON_BORRADOR')} icon={<FontAwesomeIcon icon={faSave} />}
                                    />
                                }

                                {formTestRadon.estado === 'radon_estado_borrador' &&
                                    <ButtonSpinner
                                        variant="outline-primary-radon" type="submit" className="ms-3 px-3"
                                        handleSubmit={(e) => {
                                            setActive(1)
                                            hanleClickCambiarSiguiente(e, 0)
                                        }} fetchDataLoading={[fetchSentData.loading && active === 1]}
                                        value={locale('TEST_RADON_CONTINUAR')} iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                    />
                                }

                                {formTestRadon.estado !== 'radon_estado_borrador' &&
                                    <ButtonSpinner
                                        variant="success" type="submit" className="ms-3 px-3"
                                        handleSubmit={(e) => { setIndexFocusWizard(1) }} fetchDataLoading={[fetchSentData.loading]}
                                        value={locale('TEST_RADON_CONTINUAR')} iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
