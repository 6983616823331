import React, { useState, useEffect } from 'react'
import { Form } from "react-bootstrap";
import { InputSelect } from "components/Inputs/InputSelect";
import { InputText } from "components/Inputs/InputText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { InputAutocomplete } from "components/Inputs/InputAutocomplete";
import ListGroup from "react-bootstrap/ListGroup";
import { useFetch } from "hooks/useFetch";
import { useLang } from 'language';

export const DatosEnvioSensores = ({ formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData, parametros,
    fetchSentData, handleFormChangeEditar, fetchProvinciasData, validDireccionEnvio }) => {

    const locale = useLang();

    const [fetchMunicipioEdificio, fetchMunicipioEdificioData] = useFetch("GET", `/parametros/municipios`);

    const [fetchPeticionario, fetchDataPeticionario] = useFetch('GET', `/clientes/solicitudes_radon/datos_peticionario`);

    const [direccionType, setdireccionType] = useState(false);

    const [indexAdress, setindexAdress] = useState(-1);

    const handleVeryTypeAddress = (index) => {

        // console.log('formTestRadon', formTestRadon);

        setindexAdress(index)

        if (index === 0) {

            fetchPeticionario();

        } else if (index === 1) {

            handleFormChangeEditar({
                ...formTestRadon,
                //direccion_envio_codigo_postal: formTestRadon.edificio_codigo_postal,
                direccion_envio_direccion: formTestRadon.edificio_direccion,
                direccion_envio_numero: formTestRadon.edificio_numero,
                direccion_envio_puerta: formTestRadon.edificio_puerta,
                direccion_envio_piso: formTestRadon.edificio_piso,

                direccion_envio_codigo_postal: formTestRadon.edificio_codigo_postal,
                direccion_envio_provincia_id: formTestRadon.edificio_provincia_id,
                direccion_envio_municipio_id: formTestRadon.edificio_municipio_id,
            });

            setdireccionType(true);

        } else if (index === 2) {

            handleFormChangeEditar({
                ...formTestRadon,
                direccion_envio_direccion: '',
                direccion_envio_numero: '',
                direccion_envio_puerta: '',
                direccion_envio_piso: '',

                direccion_envio_codigo_postal: '',
                direccion_envio_provincia_id: '',
                direccion_envio_municipio_id: '',
            });

            setdireccionType(true);
        }

    };

    useEffect(() => {

        if (fetchDataPeticionario.data?.data) {

            const datos = fetchDataPeticionario.data.data.datos_facturacion;

            handleFormChangeEditar({
                ...formTestRadon,
                direccion_envio_direccion: datos.datos_fact_calle,
                direccion_envio_numero: datos.datos_fact_numero,
                direccion_envio_puerta: datos.datos_fact_puerta,
                direccion_envio_piso: datos.datos_fact_piso,

                direccion_envio_codigo_postal: datos.datos_fact_codigo_postal,
                direccion_envio_provincia_id: '',
                direccion_envio_municipio_id: '',
            });
        }

    }, [fetchDataPeticionario.data])

    const handleSelectOptionDataSensor = (data) => {

        handleFormChangeEditar({
            ...formTestRadon,
            direccion_envio_codigo_postal: data?.['postal_code']?.[0]?.long_name || '',
            direccion_envio_direccion: data?.['route']?.[0]?.long_name || '',
            direccion_envio_numero: data?.['street_number']?.[0]?.long_name || ''
        });
    }

    useEffect(() => {
        if (formTestRadon.direccion_envio_provincia_id) {
            fetchMunicipioEdificio({
                params: {
                    provincia_id: formTestRadon.direccion_envio_provincia_id,
                },
            });
        }
    }, [formTestRadon.direccion_envio_provincia_id])

    useEffect(() => {

        if (formTestRadon.direccion_envio_direccion) {
            setdireccionType(true);
        }

    }, [formTestRadon.direccion_envio_direccion])

    

    return (
        <>
            <fieldset className="border p-2">
                <legend className="w-auto text-left">{locale('TEST_RADON_DETALLE_DETECTORES')}</legend>
                <div className="row d-flex align-items-start">
                    <Form.Group className="mb-2 col-lg-3">
                        <ListGroup>
                            {/*"Dades peticionari", */}
                            {[locale('TEST_RADON_DIRECCION_DATOS_PETICIONARIO'), locale('DATOS_VIVIENDA_EDIFICIO'), locale('NUEVA_DIRECCION_ENVIO')].map((item, index) => (
                                <ButtonSpinner
                                    disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                    key={index}
                                    variant="outline-success" type="button" className={`mb-3 text-start ${index === 0 ? 'd-none' : 'd-inline-block'}`}
                                    handleSubmit={(e) => handleVeryTypeAddress(index)} fetchDataLoading={[fetchDataPeticionario.loading && index === indexAdress]}
                                    value={item} icon={<FontAwesomeIcon icon={faSync} className="me-1" />}
                                />

                            ))}
                        </ListGroup>
                    </Form.Group>
                    {
                        // direccionType &&
                        (
                            <>
                                <div className="col-lg-9">
                                    <div className="row">
                                        <Form.Group className="mb-3 col-lg-4 position-relative">
                                            <InputAutocomplete
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                title={locale('TEST_RADON_DIRECCION')}
                                                name="direccion_envio_direccion"
                                                value={formTestRadon.direccion_envio_direccion}
                                                handleInputForm={handleInputFormTestRadon}
                                                fetchSentData={fetchSentData}
                                                obtenerData={handleSelectOptionDataSensor}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-3">
                                            <InputText
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                label={locale('TEST_RADON_NUMERO')}
                                                className=""
                                                name="direccion_envio_numero"
                                                value={formTestRadon.direccion_envio_numero}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="direccion_envio_numero"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-3">
                                            <InputText
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                label={locale('TEST_RADON_PISO')}
                                                className=""
                                                name="direccion_envio_piso"
                                                value={formTestRadon.direccion_envio_piso}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="direccion_envio_piso"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-2">
                                            <InputText
                                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                label={locale('TEST_RADON_PUERTA')}
                                                className=""
                                                name="direccion_envio_puerta"
                                                value={formTestRadon.direccion_envio_puerta}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="puerta"
                                                fetchData={fetchSentData}
                                            />
                                        </Form.Group>

                                        <div className="col-12"></div>

                                        <Form.Group className="mb-3 col-sm-4 col-lg-4">
                                            <InputText
                                                label={locale('TEST_RADON_CODIGO_POSTAL') + ': *'}
                                                className=""
                                                name="direccion_envio_codigo_postal"
                                                value={formTestRadon.direccion_envio_codigo_postal}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="codigo_postal"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-sm-4 col-lg-4">
                                            <InputSelect
                                                label={locale('PROVINCIA') + ': *'}
                                                className=""
                                                name="direccion_envio_provincia_id"
                                                value={formTestRadon.direccion_envio_provincia_id}
                                                values={
                                                    fetchProvinciasData.data?.data?.map((item, index) => {
                                                        return {
                                                            id: item.id_provincia,
                                                            nombre: item.provincia
                                                        }
                                                    })
                                                }

                                                onChange={handleInputFormTestRadon}
                                                placeholder="codigo_postal"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-sm-4 col-lg-4">
                                            <InputSelect
                                                label={locale('MUNICIPIO') + ': *'}
                                                className=""
                                                name="direccion_envio_municipio_id"
                                                value={formTestRadon.direccion_envio_municipio_id}
                                                values={fetchMunicipioEdificioData?.data?.data}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="localitat"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>


                                        <Form.Group className="mb-3 col-sm-12 col-lg-12">
                                            <InputText
                                                label={locale('ATENCION_DE')}
                                                as="textarea"
                                                rows={5}
                                                className=""
                                                name="direccion_envio_atencion_de"
                                                value={formTestRadon.direccion_envio_atencion_de}
                                                onChange={handleInputFormTestRadon}
                                                placeholder="direccion_envio_atencion_de"
                                                fetchData={fetchSentData}
                                                validation={validDireccionEnvio}
                                            />
                                        </Form.Group>

                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </fieldset>
        </>
    );

}