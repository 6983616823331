import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { ModalPlantaRasant } from "./ModalPlantaRasant";
import { useModalData } from "hooks/useModalData";
import { useFetch } from "hooks/useFetch";
import { Loader } from "components/Loader/Loader";
import { ModalEliminarPlanta } from './ModalEliminarPlanta';
import { ModalSensores } from './ModalSensores'
import { useLang } from 'language';

export const PlantasRasants = ({ formTestRadon, parametros, setTotaSensores }) => {
    const locale = useLang();
    const [dataModal_Planta, handleOpenModal_Planta, handleCloseModal_Planta] = useModalData()
    const [dataModal_Sensor, handleOpenModal_Sensor, handleCloseModal_Sensor] = useModalData()
    const [fetchPlantas, fetchPlantasData] = useFetch('GET', `/clientes/solicitudes_radon_planta`);
    const [fetchResumen, fetchResumenData] = useFetch('GET', `/clientes/solicitudes_radon/sensores_resumen`);
    const [showModalEliminarPlanta, setShowModalEliminarPlanta] = useState(false);
    const [plantaId, setPlantaId] = useState('');

    const consolidadoHttp = _ => {
        fetchPlantas({
            params: {
                solicitud_id: formTestRadon.id
            }
        })

        fetchResumen({
            id: formTestRadon.id
        });
    }

    const updateTotalSensores = () => {
        fetchResumen({
            id: formTestRadon.id
        });
    }

    const reloadHttp = () => {
        handleCloseModal_Planta()
        consolidadoHttp()
    }

    const handleCloseModalEliminarPlanta = () => setShowModalEliminarPlanta(false);

    const handleShowModalEliminarPlanta = (id) => {
        setShowModalEliminarPlanta(true)
        setPlantaId(id);
    }

    useEffect(() => {
        consolidadoHttp()
    }, [])

    useEffect(() => {
        if (fetchResumenData.data?.status === 'Success') {
            setTotaSensores(fetchResumenData.data.data.total_sensores);
        }
    }, [fetchResumenData])

    const totales = () => {
        // if (fetchResumenData.data?.data?.total_extra > 0) {
        //     return (
        //         <p><strong className="fw-500">{locale('TEST_RADON_TOTAL_SENSORES')}</strong>: {fetchResumenData.data?.data?.total} + {fetchResumenData.data?.data?.total_extra || 0} {locale('TEST_RADON_TOTAL_EXTRA_SENSORES')}</p>
        //     )
        // } else {
        //     return (
        //         <p><strong className="fw-500">{locale('TEST_RADON_TOTAL_SENSORES')}</strong>: {fetchResumenData.data?.data?.total}</p>
        //     )
        // }
        return (
            <p className="mb-3"><strong className="fw-500">{locale('TEST_RADON_TOTAL_SENSORES')}</strong>: {fetchResumenData.data?.data?.total_sensores} </p>
        )
    }

    const renderTitle = () => {

        if (formTestRadon.edificio_tipo_edificio === 'unifamiliar') {
            return 'Planta';
        } else if (formTestRadon.edificio_tipo_edificio === 'plurifamiliar') {
            return 'Unitat d’ús';
        } else if (formTestRadon.edificio_tipo_edificio === 'otros') {
            return 'Planta';
        }
    }

    return (
        <>

            <fieldset className="border p-2">
                <legend className="w-auto text-left">{locale('TEST_RADON_DETERMINACION')}</legend>

                <div className="col-12 text-end mb-3">
                    <Button disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false} variant="outline-primary-radon" size='sm' onClick={() => {
                        handleOpenModal_Planta({
                            action: 'CREATE',
                        })
                    }}>
                        <FontAwesomeIcon icon={faPlus} className='me-1' /> {locale('TEST_RADON_AGREGAR_PLANTA')}
                    </Button>
                </div>

                {
                    fetchPlantasData.loading || fetchResumenData.loading ?
                        <Loader />
                        :
                        <>

                            <Table responsive className='tableMain'>
                                <thead>
                                    <tr>
                                        <th className="text-center">{renderTitle()}</th>
                                        {formTestRadon.edificio_tipo_edificio !== 'plurifamiliar' &&
                                            <th className="text-center">{locale('TEST_RADON_DISPONE_LOCAL')}</th>
                                        }
                                        {formTestRadon.edificio_tipo_edificio !== 'plurifamiliar' &&
                                            <th className="text-center">{locale('TEST_RADON_LOCALE_PLANTA')}</th>
                                        }
                                        <th className="text-center">{locale('TEST_RADON_METRO_PLANTA')}</th>
                                        <th className="text-center">{locale('TEST_RADON_ALCALA_PLANTA')}</th>
                                        <th className="text-center">{locale('TEST_RADON_TIPOLOGIA_PLANTA')}</th>
                                        <th className="text-center">{locale('TEST_RADON_NUMERO_DETECTOR')}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {
                                        fetchPlantasData.data?.data?.data?.map(item => {

                                            return (
                                                <tr key={item.id}>
                                                    <td valign="middle">{item.nombre}</td>
                                                    {formTestRadon.edificio_tipo_edificio !== 'plurifamiliar' &&
                                                        <td valign="middle">{item.dispone_local}</td>
                                                    }
                                                    {formTestRadon.edificio_tipo_edificio !== 'plurifamiliar' &&
                                                        <td valign="middle" className="text-center">{item.dispone_local === 'Si' ? item.local_habitable : item.local_uso}</td>
                                                    }
                                                    <td valign="middle" className="text-center">{item.m_planta_habitable}</td>
                                                    <td valign="middle">{item.altura_planta_m}</td>
                                                    <td valign="middle" className="text-center">{item.tipo_planta_nombre}</td>
                                                    <td valign="middle" className="text-center">{item.total_sensores}</td>
                                                    <td valign="middle">

                                                        <Button variant="danger" size='sm' className='ms-3 my-0'
                                                            onClick={
                                                                () => {
                                                                    handleShowModalEliminarPlanta(item.id)
                                                                }
                                                            }
                                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>

                                                    <td valign="middle">


                                                        <Button variant="secondary" size='sm' className=''
                                                            onClick={
                                                                () => handleOpenModal_Planta({
                                                                    action: 'EDIT',
                                                                    id: item.id
                                                                })
                                                            }
                                                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        fetchPlantasData.data?.data?.data?.length === 0 &&
                                        <>
                                            <tr className='text-center'>
                                                <td className='py-5 ' colSpan={7}>
                                                    <h5 className='fw-normal'>{locale('TEST_RADON_NO_RESULTADO')}</h5>
                                                </td>
                                            </tr>
                                        </>
                                    }

                                </tbody>
                            </Table>
                        </>
                }

                {fetchResumenData.loading ?
                    <p><strong className="fw-500">{locale('TEST_RADON_LOADING')}</strong></p>
                    :
                    (
                        totales()
                    )
                }

                {
                    formTestRadon.tipo_solicitud === 'profesional' &&
                    <>
                        <Button disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false} variant="success" className="mb-5" size='sm' onClick={() => {
                            handleOpenModal_Sensor({
                                action: 'EXTRA',
                            })
                        }}>
                            <FontAwesomeIcon icon={faPlus} className='me-1' /> {locale('P_QUIERES_ANADIR_DETECTOR')}
                        </Button>
                    </>

                }

            </fieldset>
            {dataModal_Planta.value &&
                <ModalPlantaRasant
                    data={dataModal_Planta}
                    handleClose={handleCloseModal_Planta}
                    parametros={parametros}
                    dataradon={formTestRadon}
                    reload={reloadHttp}
                />
            }

            <ModalEliminarPlanta
                showModalEliminar={showModalEliminarPlanta}
                handleCloseModal={handleCloseModalEliminarPlanta}
                actualizarLista={reloadHttp}
                planta_id={plantaId} />

            <ModalSensores
                data={dataModal_Sensor}
                handleClose={handleCloseModal_Sensor}
                dataradon={formTestRadon}
                reload={updateTotalSensores}
                setTotaSensores={setTotaSensores}
            />
        </>
    )
};
