import { useLang } from "language"

const TipoViasServices = {

    GET: function () {

        const Lang = useLang()

        return {
            [Lang('ACCESO')]: Lang('ACCESO'),
            [Lang('APARTAMENTOS')]: Lang('APARTAMENTOS'),
            [Lang('AUTOPISTA')]: Lang('AUTOPISTA'),
            [Lang('AUTOVIA')]: Lang('AUTOVIA'),
            [Lang('AVENIDA')]: Lang('AVENIDA'),
            [Lang('BAJADA')]: Lang('BAJADA'),
            [Lang('BARRANCO')]: Lang('BARRANCO'),
            [Lang('BARRIO')]: Lang('BARRIO'),
            [Lang('BLOQUE')]: Lang('BLOQUE'),
            [Lang('CALZADA')]: Lang('CALZADA'),
            [Lang('CAMINO')]: Lang('CAMINO'),
            [Lang('CALLE')]: Lang('CALLE'),
            [Lang('CALLEJON')]: Lang('CALLEJON'),
            [Lang('CARRETERA')]: Lang('CARRETERA'),
            [Lang('CASAS')]: Lang('CASAS'),
            [Lang('CIRCUNVALACION')]: Lang('CIRCUNVALACION'),
            [Lang('COLONIA')]: Lang('COLONIA'),
            [Lang('COMPLEJO')]: Lang('COMPLEJO'),
            [Lang('CORREDOR')]: Lang('CORREDOR'),
            [Lang('CORRIOL')]: Lang('CORRIOL'),
            [Lang('COSTA')]: Lang('COSTA'),
            [Lang('ATAJO')]: Lang('ATAJO'),
            [Lang('EJE')]: Lang('EJE'),
            [Lang('ESCALERA')]: Lang('ESCALERA'),
            [Lang('GLORIETA')]: Lang('GLORIETA'),
            [Lang('GRAN_VIA')]: Lang('GRAN_VIA'),
            [Lang('GRUPO')]: Lang('GRUPO'),
            [Lang('ISLA')]: Lang('ISLA'),
            [Lang('JARDINES')]: Lang('JARDINES'),
            [Lang('LUGAR')]: Lang('LUGAR'),
            [Lang('MIRADOR')]: Lang('MIRADOR'),
            [Lang('MOLL')]: Lang('MOLL'),
            [Lang('MURALLA')]: Lang('MURALLA'),
            [Lang('PARAJE')]: Lang('PARAJE'),
            [Lang('PARQUE')]: Lang('PARQUE'),
            [Lang('PARTIDA')]: Lang('PARTIDA'),
            [Lang('PASADILLO')]: Lang('PASADILLO'),
            [Lang('PASAJE')]: Lang('PASAJE'),
            [Lang('PASEO')]: Lang('PASEO'),
            [Lang('PISTA')]: Lang('PISTA'),
            [Lang('PLAZA')]: Lang('PLAZA'),
            [Lang('PLACETA')]: Lang('PLACETA'),
            [Lang('PLAYA')]: Lang('PLAYA'),
            [Lang('POLIGONO')]: Lang('POLIGONO'),
            [Lang('POLIGONO_INDUSTRIAL')]: Lang('POLIGONO_INDUSTRIAL'),
            [Lang('PUENTE')]: Lang('PUENTE'),
            [Lang('PUERTO')]: Lang('PUERTO'),
            [Lang('PORTAL')]: Lang('PORTAL'),
            [Lang('PROLONGACION')]: Lang('PROLONGACION'),
            [Lang('SUBIDA')]: Lang('SUBIDA'),
            [Lang('RINCON')]: Lang('RINCON'),
            [Lang('RAMBLA')]: Lang('RAMBLA'),
            [Lang('RAVAL')]: Lang('RAVAL'),
            [Lang('RIAL')]: Lang('RIAL'),
            [Lang('RIERA')]: Lang('RIERA'),
            [Lang('RONDA')]: Lang('RONDA'),
            [Lang('ROTONDA')]: Lang('ROTONDA'),
            [Lang('SECTOR')]: Lang('SECTOR'),
            [Lang('TRAVESIA')]: Lang('TRAVESIA'),
            [Lang('TRAVESERA')]: Lang('TRAVESERA'),
            [Lang('URBANIZACION')]: Lang('URBANIZACION'),
            [Lang('VECINDARIO')]: Lang('VECINDARIO'),
            [Lang('VIA')]: Lang('VIA'),
            [Lang('VIADUCTO')]: Lang('VIADUCTO'),
            [Lang('VIAL')]: Lang('VIAL'),
            [Lang('ZONA')]: Lang('ZONA'),
        }
    }
}

export {
    TipoViasServices,
}