import React, { useEffect, useState } from 'react';
import './Wizard.css'

const Wizard = ({ children }) => {

    return (
        <>
            <section className='Wizard'>
                {children}
            </section>
        </>
    )
};

const HeaderWizard = ({ listWizard, indexFocusWizard, setIndexFocusWizard, enableBack }) => {

    const [maximumIndex, setMaximumIndex] = useState(indexFocusWizard);

    useEffect(() => {

        if (maximumIndex < indexFocusWizard) {

            setMaximumIndex(indexFocusWizard)
        }

    }, [indexFocusWizard]);

    const handleIgualIndex = (index) => {

        let indexTemp = enableBack ? maximumIndex : indexFocusWizard

        return index === indexTemp ? 'HeaderWizard-item_focus' : ''
    }

    const handleMayorIndex = (index) => {

        if (enableBack) {

            if (index < maximumIndex) {

                if (index < indexFocusWizard) {
                    
                    return 'HeaderWizard-item_complete'
                }

                return 'HeaderWizard-item_focus'
            }

            return ''

        } else {

            return index < indexFocusWizard ? 'HeaderWizard-item_complete' : ''
        }
    }

    const handleDisabledIndex = (index) => {

        let indexTemp = enableBack ? maximumIndex : indexFocusWizard

        return index > indexTemp ? 'disabledCursor' : ''
    }

    return (
        <>
            <section className={`HeaderWizard ${listWizard?.length >= 4 ? 'HeaderWizard--shrink' : ''} ${!setIndexFocusWizard ? 'disabledCursor' : ''}`}>
                {
                    listWizard?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`HeaderWizard-item ${handleIgualIndex(index)} ${handleMayorIndex(index)}`}
                            >
                                <div
                                    className={`HeaderWizard-item-info ${handleDisabledIndex(index)}`}
                                    onClick={() => setIndexFocusWizard ? setIndexFocusWizard(index) : null}
                                >
                                    <div className='HeaderWizard-item-number'>{index + 1}</div>
                                    <div className='HeaderWizard-item-name'>{item}</div>
                                </div>
                                <div className="HeaderWizard-item-line"></div>
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

const BodyWizard = ({ children, indexFocusWizard, setIndexFocusWizard }) => {

    return (
        <>
            <section className='BodyWizard'>
                {
                    children?.map((item, index) => {
                        if (index !== indexFocusWizard) {
                            return null
                        }
                        return (
                            <div key={index}>
                                {item}
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

Wizard.Header = HeaderWizard
Wizard.Body = BodyWizard

export default Wizard;