import React, { useContext, useEffect } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useLang } from 'language';
import './Home.css'
import { inIframe, isBetweenDates, removeParam } from 'helpers/helpers';
import { AuthContext } from '../../auth/AuthContext';

export const HeaderHome = ({ handleOpenModal_Login, handleOpenModal_Registro, handleOpenModal_Info,
	handleOpenModal_Radon, openModal }) => {

	const locale = useLang();

	const { user: { lang } } = useContext(AuthContext)

	const handleRedirect = () => {

		if (inIframe()) {

			window.top.location.href = `${process.env.PUBLIC_URL}?locale=${lang}&login=true`

		} else {

			handleOpenModal_Login({
				action: 'EXTRA',
			})
		}
	}

	useEffect(() => {

		if (openModal) {

			handleOpenModal_Login({
				action: 'EXTRA',
			})

			window.history.pushState({}, '', removeParam("login", window.location.href))
		}

	}, [openModal]);


	return (
		<header className="header d-flex justify-content-center align-items-center"
			style={{
				backgroundImage: `url("edificio.jpg")`,
			}}
		>
			{/* <div style={{backgroundColor: "rgb(125, 163, 208, .8)"}}> */}
			<Container className="d-flex h-100">
				<Row className="justify-content-center align-items-center">

					<Col md={12}>
						<Row className="mt-5 mb-5 text-white">

							{
								isBetweenDates('2024-07-29', '2024-08-23') &&
								<div className="col-12 text-dark mb-4">
									<div className="section-border py-2 my-0">
										<small>{locale('P_ALERTA_HOME')}</small>
									</div>
								</div>
							}

							<Col md={8} className="justify-content-center border-end-0">
								<Container>
									<h1 className="title-home animate__animated animate__bounce">{locale('HOME_HEADER_TITULO')}</h1>
									<h4 className="mb-3 animate__animated animate__fadeIn title-home">{locale('HOME_HEADER_SUB_TITULO')}</h4>
									<p className="mb-3 animate__animated animate__fadeIn" style={{ fontSize: '17px' }}>
										{locale('HOME_HEADER_TEXTO_1')}
									</p>
									<p className="mb-3 animate__animated animate__fadeIn" style={{ fontSize: '17px', }}>

										{locale('HOME_HEADER_TEXTO_')} <span className="text-white fw-bold">{locale('HOME_HEADER_TEXTO_SPAN1')}</span> {locale('HOME_HEADER_TEXTO__')} <span className="text-white fw-bold">{locale('HOME_HEADER_TEXTO_SPAN2')}</span> {locale('HOME_HEADER_TEXTO___')}

										<a className="ms-1 text-decoration-none small text-white" style={{ cursor: 'pointer' }} onClick={() => {
											handleOpenModal_Radon({
												action: 'EXTRA',
											})
										}}>[{locale('HOME_BTN_SABER_MAS')}]</a>
									</p>

								</Container>

							</Col>
							<Col md={4}>
								{/* Add your content here */}
								<Container className="text-center mb-5 mt-4">
									<h5 className="mb-3 title-home animate__animated animate__fadeIn ">
										{locale('HOME_HEADER_INFO_USUARIO')} <a className="text-decoration-none small d-block text-white fw-normal mt-2" style={{ cursor: 'pointer' }}
											onClick={() => {
												handleOpenModal_Info({
													action: 'EXTRA',
												})
											}}
										>[{locale('HOME_BTN_SABER_MAS')}]</a></h5>
									<Row className="d-flex justify-content-center align-items-center">
										<Col xl={8} className="justify-content-center text-center border-top border-white">
											<div className="d-grid gap-2 mt-3 p-2">
												<Button variant="primary home-btn" className="mb-1" onClick={() => {
													handleRedirect()
												}}>
													<FontAwesomeIcon icon={faSignInAlt} /> {locale('HOME_BTN_LOGIN')}
												</Button>

												<Button variant="primary" className="home-btn" onClick={() => {
													handleOpenModal_Registro({
														action: 'EXTRA',
													})
												}}>
													<FontAwesomeIcon icon={faUserPlus} /> {locale('HOME_BTN_REGISTRO')}
												</Button>
											</div>
										</Col>

									</Row>
								</Container>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			{/* </div>    */}
		</header>
	);
}