import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { InputSelect } from "components/Inputs/InputSelect";
import { InputText } from "components/Inputs/InputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faSearch } from "@fortawesome/free-solid-svg-icons";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { InputAutocomplete } from "components/Inputs/InputAutocomplete";
import { useFetch } from "hooks/useFetch";
import { useModalData } from "hooks/useModalData";
import ModalZonas from './Zonas/ModalZonas'
import { useLang } from 'language';

export const DatosEdificio = ({ formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData,
    parametros, fetchSentData, handleFormChangeEditar, fetchProvinciasData }) => {

    const locale = useLang();

    const [fetchMunicipioEdificio, fetchMunicipioEdificioData] = useFetch("GET", `/parametros/municipios`);

    const [fetchZonasEdificio, fetchZonasEdificioData, setZonasEdificioData] = useFetch("GET", `/parametros/zonas`);

    const [zonaNoDefinida, setZonaNoDefinida] = useState(null);
    const [provinciaId, setProvinciaId] = useState(null);
    const [dataModal_Zonas, handleOpenModal_Zonas, handleCloseModal_Zonas] = useModalData()

    const handleSelectOptionDataEdificio = (data) => {

        handleFormChangeEditar({
            ...formTestRadon,
            edificio_codigo_postal: data?.["postal_code"]?.[0]?.long_name || "",
            edificio_codigo_direccion: data?.["route"]?.[0]?.long_name || "",
            edificio_numero: data?.["street_number"]?.[0]?.long_name || "",
        });
    };

    const handleZona = (data) => {
        setZonaNoDefinida(null);

        handleFormChangeEditar({
            ...formTestRadon,
            edificio_zona: data.zona,
        });

        fetchZonasEdificio({
            id: data.municipio_id
        })
    }

    const handleZonaNoDefinida = () => {
        setZonaNoDefinida(true);

        handleFormChangeEditar({
            ...formTestRadon,
            edificio_zona: 'Zona no definida',
        });
    }

    useEffect(() => {
        if (formTestRadon.edificio_provincia_id) {
            setZonaNoDefinida(null);
            fetchMunicipioEdificio({
                params: {
                    provincia_id: formTestRadon.edificio_provincia_id,
                },
            });
        }
    }, [formTestRadon.edificio_provincia_id])

    /*useEffect(() => {
        if (formTestRadon.edificio_codigo_postal.length === 5) {
            setZonaNoDefinida(null);
            fetchMunicipioEdificio({
                params: {
                    codigo_postal: formTestRadon.edificio_codigo_postal,
                },
            });
        } else {
            handleFormChangeEditar({
                ...formTestRadon,
                edificio_municipio_id: "",
                edificio_zona: "",
            });
        }
    }, [formTestRadon.edificio_codigo_postal]);*/

    useEffect(() => {
        if (formTestRadon.edificio_municipio_id) {
            fetchZonasEdificio({
                id: formTestRadon.edificio_municipio_id,
            });
        }
        setProvinciaId(null);
        setZonaNoDefinida(null);
    }, [formTestRadon.edificio_municipio_id]);

    useEffect(() => {
        if (fetchZonasEdificioData?.data?.data) {
            if (fetchZonasEdificioData.data.data.zonas.length == 0) {
                setProvinciaId(fetchZonasEdificioData.data.data.provincia_id);
            } else {
                setProvinciaId(null);
            }
        }
    }, [fetchZonasEdificioData?.data?.data]);

    useEffect(() => {
        setZonaNoDefinida(false);
        if (formTestRadon.edificio_zona === 'Zona no definida') {
            setZonaNoDefinida(true);
        }
    }, [formTestRadon.edificio_zona])

    // useEffect(() => {
    //     fetchProvincias();
    // }, [])

    const dataExtra = () => {
        return (
            <>
                <Form.Group className="mb-3 col-md-6 col-lg-3">
                    <InputText
                        disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                        label={locale('TEST_RADON_ANIO_CONTRUCCION')}
                        className=""
                        type="number"
                        name="edificio_anio_construccion"
                        value={formTestRadon.edificio_anio_construccion}
                        onChange={handleInputFormTestRadon}
                        placeholder="fecha_construccion"
                        fetchData={fetchSentData}
                        min={0}
                    />
                </Form.Group>

                <Form.Group className="mb-3 col-md-6 col-lg-3">
                    <InputSelect
                        disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                        label={locale('TEST_RADON_REFORMA_PREVIAS')}
                        className=""
                        name="edificio_reforma_previas"
                        values={[
                            {
                                id: "Si",
                                nombre: "Si",
                            },
                            {
                                id: "No",
                                nombre: "No",
                            },
                            {
                                id: "NS/NC",
                                nombre: "NS/NC"
                            }
                        ]}
                        value={formTestRadon.edificio_reforma_previas}
                        onChange={handleInputFormTestRadon}
                        placeholder="edificio_reforma_previas"
                        fetchData={fetchSentData}
                    />
                </Form.Group>

                {formTestRadon.edificio_reforma_previas === "Si" && (
                    <Form.Group className="mb-3 col-md-6 col-lg-3">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_REFORMA_INDICA')}
                            className=""
                            type="text"
                            name="edificio_reforma_indica"
                            value={formTestRadon.edificio_reforma_indica}
                            onChange={handleInputFormTestRadon}
                            placeholder="edificio_reforma_indica"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>
                )}
            </>
        );
    }

    const renderTipoTest = () => {
        if (formTestRadon.tipo_solicitud === 'profesional' && formTestRadon.tipo_intervencion === "edificio_existente") {
            return dataExtra()
        } else if (formTestRadon.tipo_solicitud === 'personal') {
            return dataExtra()
        }
    }

    return (
        <>
            <fieldset className="border p-2">
                <legend className="w-auto text-left">{locale('TEST_RADON_UBICACION_EDIFICIO')}</legend>
                <div className="row">
                    <Form.Group className="mb-3 col-lg-3 position-relative">
                        <InputAutocomplete
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            title={locale('TEST_RADON_DIRECCION')}
                            name="edificio_direccion"
                            value={formTestRadon.edificio_direccion}
                            handleInputForm={handleInputFormTestRadon}
                            fetchSentData={fetchSentData}
                            obtenerData={handleSelectOptionDataEdificio}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-6 col-lg-2">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_NUMERO')}
                            className=""
                            name="edificio_numero"
                            value={formTestRadon.edificio_numero}
                            onChange={handleInputFormTestRadon}
                            placeholder="via_numero"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-6 col-lg-2">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_PISO')}
                            className=''
                            name='edificio_piso'
                            value={formTestRadon.edificio_piso}
                            onChange={handleInputFormTestRadon}
                            placeholder="piso"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-6 col-lg-2">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_PUERTA')}
                            className=""
                            name="edificio_puerta"
                            value={formTestRadon.edificio_puerta}
                            onChange={handleInputFormTestRadon}
                            placeholder="puerta"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-6 col-lg-3 col-xl-3">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_CODIGO_POSTAL') + ': *'}
                            className=""
                            name="edificio_codigo_postal"
                            value={formTestRadon.edificio_codigo_postal}
                            onChange={handleInputFormTestRadon}
                            placeholder="codigo_postal"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-6 col-lg-3">
                        <InputSelect
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('PROVINCIA') + ': *'}
                            className=''
                            name='edificio_provincia_id'
                            value={formTestRadon.edificio_provincia_id}
                            values={
                                fetchProvinciasData.data?.data?.map((item, index) => {
                                    return {
                                        id: item.id_provincia,
                                        nombre: item.provincia
                                    }
                                })
                            }
                            onChange={handleInputFormTestRadon}
                            placeholder="provincia_id"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-6 col-lg-3">
                        <InputSelect
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('TEST_RADON_MUNICIPIO')}
                            className=""
                            name="edificio_municipio_id"
                            value={formTestRadon.edificio_municipio_id}
                            values={fetchMunicipioEdificioData?.data?.data}
                            onChange={handleInputFormTestRadon}
                            placeholder="municipio"
                            fetchData={fetchSentData}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 col-sm-6 col-lg-3">
                        {zonaNoDefinida ? (
                            <InputText 
                                disabled 
                                label="Zona *" 
                                className="" 
                                name="edificio.zona" 
                                value={formTestRadon.edificio_zona} 
                                onChange={handleInputFormTestRadon} 
                                placeholder="Zona" 
                                fetchData={fetchSentData} 
                            />
                        ) : (
                            <InputSelect
                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                label={locale('TEST_RADON_ZONA') + ':'}
                                className=""
                                name="edificio_zona"
                                value={formTestRadon.edificio_zona}
                                values={fetchZonasEdificioData?.data?.data?.zonas?.map((item, index) => {
                                    return {
                                        id: item.zona,
                                        nombre: item.zona,
                                    };
                                })}
                                onChange={handleInputFormTestRadon}
                                placeholder="Zona"
                                fetchData={fetchSentData}
                            />
                        )}
                    </Form.Group>
                    {/* {provinciaId && (
                        <Form.Group className="mb-3 col-sm-6 col-lg-3">
                            <ButtonSpinner
                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                variant="primary-radon"
                                type="button"
                                className="ms-3 mt-2 px-3"
                                handleSubmit={() => {
                                    handleOpenModal_Zonas({
                                        open: "show",
                                    });
                                }}
                                value="Localizar"
                                icon={<FontAwesomeIcon icon={faSearch} />}
                            />
                        </Form.Group>
                    )} */}

                    <Form.Group className="mb-3 col-md-6 col-lg-3">
                        <InputText
                            disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                            label={locale('SUPERFICIE_UTIL')}
                            className=""
                            name="edificio_superficie_util"
                            value={formTestRadon.edificio_superficie_util}
                            onChange={handleInputFormTestRadon}
                            placeholder="edificio_superficie_util"
                            fetchData={fetchSentData}
                            min={0}
                        />
                    </Form.Group>

                    {renderTipoTest()}
                </div>
            </fieldset>

            {
                dataModal_Zonas.value &&
                <ModalZonas
                    municipioId={formTestRadon.edificio_municipio_id}
                    provinciaId={provinciaId}
                    data={dataModal_Zonas}
                    handleClose={handleCloseModal_Zonas}
                    handleUpdateZona={handleZona}
                    handleZonaNoDefinida={handleZonaNoDefinida}
                />
            }

        </>
    );
};
