import React from "react";
import { Accordion } from "react-bootstrap";

export const CondicionesContratacionCa = ({ to }) => {
    return (
        <>
            <h5 className="text-center mb-4">
                Condicions generals de contractació productes
            </h5>

            <Accordion alwaysOpen>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1. Àmbit d' aplicació i objecte
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                1.1. Objecte
                            </strong>
                        </p>
                        <p>
                            Els presents termes i condicions regularan la relació entre del Col·legi de l'Arquitectura Tècnica de Barcelona (Cateb), titular d'aquesta Web, i els Clients que comprin o contractin els productes i serveis oferts en www.testrado.cat.

                        </p>
                        <p>
                            Aquestes condicions estableixen les normes de contractació dels productes i serveis disponibles en www.testrado.cat, havent de ser llegides i acceptades pel Client prèviament a la contractació.
                        </p>

                        <p>
                            <strong>
                                1.2. Destinataris dels nostres productes i serveis
                            </strong>
                        </p>
                        <p>

                            Els nostres productes i serveis són d'ús professional, i estan destinats a professionals, empreses i/o agents relacionats amb les activitats d'arquitectura i de construcció.

                        </p>

                        <p>
                            També hi ha productes  per a la ciutadania, en les condicions establertes a cada producte.
                        </p>

                        <p>
                            En cas que el comprador reuneixi les condicions legals establertes per tenir la condició de consumidor i usuari, resultarà d'aplicació el Reial Decret Legislatiu 1/2007, de 16 de novembre, pel qual s'aprova el text refós de la Llei General per a la Defensa dels Consumidors i Usuaris i altres lleis complementàries. La definició de consumidor i usuari es troba en la clàusula 1.2. de les presents condicions.
                        </p>

                        <p>
                            <strong>
                                1.3. Modificació de les condicions.
                            </strong>
                        </p>

                        <p>
                            El CATEB es reserva el dret a modificar, totalment o parcialment, aquestes condicions generals, essent d' aplicació les noves que acordi des del moment de la seva publicació a la pàgina. En qualsevol cas, les esmentades modificacions no tindran efectes retroactius sobre els productes i serveis prèviament adquirits.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            2.- Idioma
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Tota la informació del web www.testrado.cat, en les transaccions comercials o telefòniques, atenció al públic i els serveis oferts seran en català.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            3. Procés de contractació
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                3.1. Alta com a usuari

                            </strong>
                        </p>
                        <p>
                            Per realitzar qualsevol compra o contractació al nostre lloc web el Client haurà de registrar-se prèviament creant un compte d'usuari.
                        </p>
                        <p>
                            Per a l'alta com a usuari se sol·licitaran dades personals que es consideren necessàries i mínimes perquè CATEB pugui tramitar les sol·licituds de compra i/o contractació, respectant en tot moment les disposicions establertes en el Reglament UE 2016/679 i en la Llei Orgànica 3/20218, de 5 de desembre, de Protecció de Dades Personals. Per a més informació sobre el tractament que realitza CATEB sobre les teves dades personals, pots consultar la nostra Política de Privacitat .
                        </p>

                        <p>
                            <strong>
                                3.2. Acceptació de les condicions
                            </strong>
                        </p>

                        <p>
                            La compra dels productes o serveis oferts al web es realitzarà emplenant els formularis i procediments electrònics previstos al web. El procés s'iniciarà amb l'elecció del producte o servei i el seguiment dels passos indicats a la web, que suposen l'acceptació de les condicions generals i particulars de la compra dels productes de la web.
                        </p>

                        <p>

                            La confirmació de la contractació dels serveis es realitzarà a través de l'activació del botó «Accepto les condicions de contractació», que anirà acompanyada d'una concisa informació sobre el tractament de les dades personals que durà a terme CATEB. El botó d' acceptació de les condicions apareixerà una vegada l' usuari hagi seleccionat els serveis en els quals estigui interessat i amb anterioritat al pagament o confirmació de la seva intenció de contractar.

                        </p>

                        <p>
                            L'usuari haurà de llegir-se les presents condicions abans d' acceptar-les, quedant vinculat al seu compliment des del moment de l' acceptació.
                        </p>

                        <p>
                            Les presents condicions estaran disponibles per a l'usuari de manera permanent a la nostra pàgina web.
                        </p>
                        <p>
                            Una vegada realitzada la compra, en un termini no superior a 24 hores, el client rebrà un justificant de la seva comanda.
                        </p>
                        <p>
                            La compra dels productes i serveis impliquen l' autorització per part del client de l' emissió i l' enviament de la factura en suport electrònic.
                        </p>

                        <p>
                            <strong>
                                3.3. Requisits de contractació
                            </strong>
                        </p>

                        <p>

                            Per realitzar qualsevol compra al nostre lloc web www.testrado.cat caldrà que el Client sigui major de 18 anys.

                        </p>
                        <p>
                            La compra i contractació al nostre lloc web requerirà la prèvia alta com a usuari, conforme s'indica a l'apartat 3.1. d' aquestes condicions. El Client haurà d' introduir les seves credencials d' accés com a mètode d' identificació en les compres i contractacions que realitzi. Les credencials d' accés estaran constituïdes per un document identificatiu i contrasenya escollida pel Client.
                        </p>

                        <p>
                            Una vegada realitzada la comanda i confirmada la recepció de pagament per part de CATEB, el Client rebrà un correu electrònic on se li confirmarà la recepció de la comanda, començant en aquell moment el termini de lliurament en cas que resultés aplicable. Si en un termini de 24 hores no ha rebut l'email de confirmació, el Client haurà de posar-se en contacte immediatament amb CATEB trucant al telèfon de contacte 93 240 20 60 o enviant un email a consultoriatecnica@cateb.cat.
                        </p>
                        <p>
                            Si durant el procés de compra de qualsevol dels nostres productes o serveis ha introduït erròniament alguna dada, s'haurà de posar en contacte immediatament amb nosaltres a través del telèfon de contacte 93 240 20 60 o enviant un email a consultoriatecnica@cateb.cat.
                        </p>

                        <p>
                            <strong>
                                3.4. Manifestacions formals del Client
                            </strong>
                        </p>

                        <p>
                            Mitjançant l'adquisició dels serveis l'Usuari i/o Client, declara:
                        </p>
                        <p>
                            – Que és una persona major d'edat i amb capacitat per contractar.
                        </p>
                        <p>
                            – Que ha llegit, comprès i accepta les presents Condicions Generals de Contractació i la resta de normativa aplicable a la contractació de CATEB.
                        </p>


                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Informació sobre els Productes i Serveis digitals
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Les característiques dels productes, descripció tècnica i funcionalitats es troben a disposició dels clients en www.testrado.cat
                        </p>
                        <p>
                            CATEB es reserva el dret a realitzar modificacions en el seu catàleg de productes respectant en tot moment les normes que resultin d' aplicació.
                        </p>
                        <p>
                            CATEB posa a disposició de l'Usuari i/o Client els següents productes i serveis:
                        </p>
                        <ul>
                            <li>
                                Test Radó Bàsic
                            </li>
                            <li>
                                Test Radó Professional.
                            </li>
                        </ul>

                        <p>
                            A la nostra web l'Usuari i/o Client podrà trobar les característiques i condicions de cada producte i/o servei en les quals se l'informarà de les modalitats d'accés, cost, durada, continguts, requisits tècnics o compatibilitat amb altres programaris i més informació que pot resultar d'interès per a l'Usuari i/o Client.
                        </p>

                        <p>
                            És possible que alguns de les nostres productes requereixin autenticació per utilitzar-los, corresponent-se amb les credencials de l' alta com a usuari. Addicionalment, algunes dels nostres productes demanen la introducció d'altres dades personals -pròpies o de tercers- per prestar les funcionalitats requerides. Vostè haurà de tenir permís d'aquests tercers per introduir les seves dades.
                        </p>

                        <p>
                            <strong>
                                4.1. Requisits tècnics dels productes i serveis digitals
                            </strong>
                        </p>

                        <p>
                            El Client haurà de disposar d'un programa informàtic compatible amb la lectura i descàrrega de documents amb extensió .pdf (Adobe reader, Adobe professional o similars), disposant de la informació necessària en les condicions particulars i descripció de cada producte.
                        </p>

                        <p>
                            El Client serà responsable de disposar dels requisits tècnics aquí expressats, no constituint causa de falta de conformitat.
                        </p>

                        <p>
                            <strong>
                                4.2. Actualitzacions dels productes digitals de pagament
                            </strong>
                        </p>

                        <p>
                            CATEB podrà realitzar actualitzacions de conformitat dels seus productes digitals, incloses les de seguretat, per mantenir la conformitat dels productes, i les de contingut, incloent-hi els nous continguts normatius publicats. Les actualitzacions de conformitat i de continguts romandran vigents sempre que l' aplicació informàtica estigui publicada i en funcionament.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Condicions econòmiques
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                5.1. Preus i despeses
                            </strong>
                        </p>

                        <p>
                            El preu (Preu de Venda al Públic o PVP) es troba disponible en la descripció de cada producte, està expressat en euros i inclou l'IVA i/o qualsevol altre impost o taxa que resultés d'aplicació.
                        </p>

                        <p>
                            <strong>
                                5.2. Formes de pagament
                            </strong>
                        </p>

                        <p>
                            Les formes de pagament admeses són les següents:
                        </p>

                        <p>
                            (1) Targeta bancària. El càrrec es realitza en temps real, a través de la passarel·la de pagament segura Redsys i un cop s'hagi comprovat que les dades comunicades són correctes. Amb l' objectiu de donar la màxima seguretat al sistema de pagament CATEB utilitza sistemes de pagament segur. En aquest sentit, les dades confidencials són transmeses directament i de forma encriptada (SSL) a l'entitat financera corresponent. El sistema d'encriptació SSL que utilitzem (TLS 1.1 i TLS 1.2) confereix total seguretat a la transmissió de dades a través de la xarxa. Les seves dades gaudeixen de total confidencialitat i protecció. Les dades sobre les targetes de crèdit no queden registrades en cap base de dades nostra. Estan únicament utilitzats en el TPV (Terminal Punt de Venda) virtual de la nostra entitat financera, a través de la seva Passarel·la de Pagament Segur (Redsys). Les targetes de crèdit estaran subjectes a comprovacions i autoritzacions per part de l' entitat emissora d' aquestes, però si aquesta entitat no autoritzés el pagament, el contracte no quedarà formalitzat fins que CATEB rebi el pagament.

                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6. Garantia Legal
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            De conformitat amb allò disposat en el RDL 1/2007 de 16 de novembre, pel qual s'aprova el "Text Refós dels Consumidors i Usuaris i altres lleis complementàries", el client, disposarà d'un període de dos anys de garantia en els productes per manifestar la seva falta de conformitat, excepte quan la falta de conformitat sigui incompatible amb la naturalesa del producte o l'índole de la falta de conformitat.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7. Desistiment, devolucions i incidències
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El client disposarà de la facultat de desistiment de la seva compra, notant-ho al CATEB en el termini de 14 dies naturals des la compra, en les condicions previstes a les condicions particulars de cada producte, excepte en els casos previstos al "RDL 1/2007, de 16 de novembre de la Llei General per a la Defensa dels Consumidores i Usuaris i altres lleis complementàries". <a className="btn-link color-primary" href="/files/desistimiento/Formulari de desistiment CATALA rev 1 Laura.docx" target="_blank" rel="noopener noreferrer">Model de formulari de desistiment</a>.

                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Responsabilitats
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>
                                8.1. Responsabilitat del Client i/o Usuari
                            </strong>
                        </p>

                        <p>


                            1. El Client haurà de complir amb les condicions de contractació establertes per a cada producte.


                        </p>
                        <p>
                            2. El Client garanteix la veracitat i exactitud de les dades facilitades en emplenar els formularis d'alta com a usuari i de contractació, evitant la creació de perfils o contractacions que ocasionin perjudicis a CATEB com a conseqüència de la incorrecció d'aquests.
                        </p>

                        <p>
                            3. El Client ha d'abonar el preu indicat per avançat utilitzant les formes de pagament indicades en les presents condicions.
                        </p>

                        <p>
                            4. El Client ha de fer un ús diligent dels productes i serveis oferts per CATEB obligant-se a usar els productes i serveis oferts al lloc web de forma lícita i sense contravenir la legislació vigent ni lesionar els drets i interessos de terceres persones. Queda expressament prohibit realitzar còpies de manera total o parcial de les publicacions adquirides, així com qualsevol altra conducta que lesioni els drets de propietat intel·lectual de CATEB o tercers.
                        </p>

                        <p>
                            5. Serà responsable de complir amb els requisits mínims tècnics de compatibilitat dels productes i serveis.
                        </p>

                        <p>
                            6. També serà responsable de respectar les condicions, forma o execució dels productes o serveis detallats en les condicions particulars aplicables, si s'escau.
                        </p>

                        <p>

                            7. L'Usuari també és responsable de fer un bon ús del seu perfil d'Usuari, comprometent-se a fer un ús diligent i conservar les credencials d'accés convenientment perquè no quedin a disposició de tercers. En cas de pèrdua, robatori o possible accés d' un tercer no autoritzat, el Client haurà de comunicar-ho immediatament a CATEB a fi de bloquejar immediatament el perfil d' usuari compromès.

                        </p>

                        <p>
                            8. El Client indemnitzarà al CATEB per les despeses que hagués d'assumir a conseqüència de qualsevol actuació la responsabilitat de la qual fos atribuïble al Client, inclosos els honoraris i despeses dels advocats, procuradors, pèrits, taxes judicials i qualssevol altres processals, fins i tot en el supòsit d'una resolució judicial no definitiva.
                        </p>

                        <p>
                            L'incompliment de qualsevol d'aquestes condicions podrà donar lloc a la retirada o cancel·lació immediata dels serveis per part de CATEB sense que això doni dret a l'Usuari o Client a sol·licitar cap indemnització.
                        </p>

                        <p>
                            <strong>

                                8.2. Responsabilitats de CATEB
                            </strong>
                        </p>

                        <p>

                            (1) Prestar el servei en la forma i segons les característiques ofertes en el nostre lloc web i el que disposa aquestes condicions de contractació en atenció als productes o serveis contractats pel Client.

                        </p>

                        <p>
                            (2) Protegir la confidencialitat de les dades dels seus Usuaris i/o Clients segons el que disposa aquest text i resta de polítiques legals aplicables.

                        </p>
                        <p>
                            (3) Complir amb el que disposen les condicions particulars de cada producte o servei contractat.

                        </p>

                        <p>
                            <strong>
                                8.3. Límits a la responsabilitat de CATEB
                            </strong>
                        </p>

                        <p>


                            A continuació, es detallen els supòsits en els quals CATEB no assumirà responsabilitat, constituint una llista no tancada, per la qual cosa qualsevol altra situació similar a les indicades i que no estigui contemplada, també serà causa d' exoneració de la responsabilitat de CATEB:

                        </p>

                        <p>
                            (1) Mal ús o abús dels productes o serveis oferts al nostre lloc web, així com els possibles danys o perjudicis causats a tercers.
                        </p>

                        <p>
                            (2) Dels danys o errors produïts per negligència o mala fe de l'Usuari i/o Client.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Propietat intel·lectual i industrial
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El titular de tot el programari disponible i de les seves actualitzacions de millora, rendiment o seguretat és CATEB, els seus llicenciataris, empreses associades o altres proveïdors que hagin pogut proporcionar materials per al desenvolupament i disseny dels productes i serveis. De manera que CATEB i els seus llicenciataris posseeixen tots els títols i drets de propietat intel·lectual sobre les aplicacions.
                        </p>

                        <p>
                            Es prohibeix expressament, llevat d' autorització per escrit i expressa de CATEB, la reproducció, transformació, distribució, comunicació pública, posada a disposició al públic, venda o altres usos diferents als expressament autoritzats per CATEB en les presents condicions, en les polítiques i altres avisos publicats a la seva pàgina web, així com a les condicions particulars, si s' escau,  ja sigui amb finalitats comercials o no, quan comportin l' explotació dels continguts, productes o serveis oferts CATEB les finalitats dels quals siguin diferents a les previstes en aquestes condicions. Els drets atorgats a l' usuari en les presents condicions són atorgats únicament per a ús personal.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            10. Atenció al Client i Reclamacions
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Els Clients podran contactar i presentar les seves reclamacions trucant al telèfon de contacte 93 240 20 60 o enviant un email a info@cateb.cat. L' horari d' atenció al client serà de Dilluns a Divendres de 9: 00 a 14:00.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            11. Fulls de Reclamació
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Es posen fulls de reclamació a disposició del consumidor a: <a href="https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf" target="_blank" rel="noopener noreferrer">https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf</a>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                    <Accordion.Header>
                        <strong>
                            12. Legislació Aplicable i Jurisdicció competent
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Aquest contracte es regeix per la Llei Espanyola. Per als casos que la normativa prevegi la possibilitat que les parts se sotmetin a un fur, se sotmeten als Jutges i Tribunals de la Ciutat de Barcelona (Espanya), amb renúncia expressa de qualsevol altre al qual pogués correspondre'ls.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

            <h5 className="text-center mb-4 mt-5">
                Condicions particulars de contractació del Test Radó
            </h5>

            <Accordion defaultActiveKey={to} className="mb-4">

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1. Objecte del contracte
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            L'objecte d'aquest contracte és la contractació d’un servei "on line" entre el Cateb i els clients d’un test de mesurament de la concentració de Radó en espais interiors durant el període de contractació del servei.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>
                            2. Característiques del producte i funcionament.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Amb el mètode de mesurament “Test Radó” el client obtindrà els nivells de concentració de Radó en un espai interior durant el període d’exposició dels detectors que el Cateb facilitarà per dur a terme els mesuraments.
                        </p>

                        <p>
                            El procediment de la recollida de les dades per a la sol·licitud del servei es realitzarà a través d’una plataforma d’accés a través de wwwtesrado.cat, on prèviament l’usuari haurà d’estar enregistrat per poder contractar el seu servei.
                        </p>
                        <p>
                            Per poder tramitar la sol·licitud i contractar el servei, el client serà l’encarregat de facilitar les dades de l’edifici a mesurar per tal d’estimar el nombre de detectors mínims necessaris col·locar els amidaments seguint les instruccions de col·locació i posada en marxa dels detectors.
                        </p>

                        <p>
                            NOTA: El mesurament es realitzarà mitjançant detectors passius de traces alfa. Aquests aparells tenen dimensions molt reduïdes i autonomia durant tot el període d’exposició, sense necessitat de fons d’alimentació (endolls).
                        </p>

                        <p>
                            Estimat el nombre de detectors i fet el pagament, s’enviaran els detectors per missatgeria a l’adreça indicada pel sol·licitant.
                        </p>

                        <p>
                            Un cop es rebin els detectors, caldrà descarregar les instruccions de col·locació, que estaran disponibles dins la seva sol·licitud o a la web www.testrado.cat.
                        </p>

                        <p>
                            Els detectors es mantindran actius durant el període de mesurament contractat.
                        </p>

                        <p>
                            Finalitzat el procés, caldrà que el client faci el retorn dels detectors,  seguint les instruccions següents facilitades.
                        </p>

                        <p>
                            Perquè la prova sigui fiable heu de retornar tants detectors com consten a la sol·licitud, per tant cal que comproveu que rebeu el mateix número de detectors que hi ha a la sol·licitud. En cas contrari, heu de comunicar la incidència abans de la procedir a la col·locació dels detectors per realitzar el mesurament. S’entén que no hi ha hagut cap incidència si l’usuari/client confirma que ha rebut els detectors.
                        </p>

                        <p>
                            <strong>
                                Devolució dels detectors
                            </strong>
                        </p>

                        <p>
                            Finalitzat el període de mesurament, el client és l’encarregat de gestionar i abonar les despeses de transport del detector amb el transportista de la seva elecció.
                        </p>

                        <p>
                            La devolució s’ha de fer al nostre centre de recepció situat a la següent adreça postal.
                        </p>

                        <p>
                            C/ Bon Pastor, num 5, planta 5ª
                        </p>

                        <p>
                            CP: 08021 Barcelona
                        </p>

                        <p>
                            <strong>
                                Instruccions de devolució:
                            </strong>
                        </p>

                        <ul>
                            <li>
                                Per a garantir que els resultats son correctes, tots els detectors entregats, s’han de retornar sense cap mena d’embolcall . En cap s’han de dipositar dins d’una bossa de plàstic precintada per a enviar-los al laboratori.
                            </li>
                            <li>
                                Durant el període de mesurament, la carcassa de plàstic del detector absorbeix quantitats importants de radó. Si el detector s’envia embolicat i precintat en una bossa de plàstic, l’aire contingut a l’interior de la bossa pot ser absorbit pel detector. En conseqüència, es pot obtenir un resultat entre un 10 i un 20% diferent respecte als nivells reals de radó. Aquesta diferencia és més important quan més curta sigui la mesura i menor sigui el volum d’aire de l’interior de la bossa de plàstic.
                            </li>
                        </ul>

                        <p>
                            NOTA: Per a que el resultat sigui fiable, cal retornar tants detectors com consten a la solꞏlicitud.
                        </p>

                        <p>

                            Faci el seguiment de l’estat de la seva sol·licitud fins a descarregar el resultat del test un cop finalitzat el procés d’anàlisis del laboratori.

                        </p>

                        <p>
                            Un cop retornats els detectors, el resultat de l’anàlisi estarà disponible en pdf dins de la sol·licitud del client a la plataforma de gestió de www.testrado.cat
                        </p>

                        <p>
                            El resultat obtingut serà la mitja de concentració de radó (Bq/m3) durant el període d’exposició dels detectors.
                        </p>

                        <p>
                            <strong>
                                Modalitats de Test Radó
                            </strong>
                        </p>

                        <p>
                            El Test Radó es presenta en dues modalitats.
                        </p>

                        <p>
                            <strong>
                                A.- El Test Radó Bàsic.
                            </strong>
                        </p>

                        <p>
                            Pensat per a qualsevol persona que vulgui conèixer els nivells de Radó als quals està exposat i, pot ser adquirit en dos formats:
                        </p>

                        <p>
                            <strong>Test de mesura llarga (recomanada)</strong>:  es podrà obtenir una mesura fiable dels nivells de concentració de radó a l’edifici, durant el període d’exposició de 3 mesos.
                        </p>

                        <p>
                            <strong>Test de mesura curta</strong>: per obtenir una aproximació dels valors de concentració de radó a l’edifici, obtinguts durant un període de d’exposició d’entre 5-10 dies.
                        </p>

                        <p>
                            <strong>
                                B.- El Test Radó Professional-Justificació CTE
                            </strong>
                        </p>

                        <p>
                            Aquest producte està pensat per a professionals del sector, que com a projectista, direcció facultativa o entitat de control volen mesurar la concentració de radó segons les exigències del CTE DB HS6.
                        </p>

                        <p>
                            Aquest test només està disponible per a mesuraments de 3 mesos, per tant només és possible la sol·licitud de Mesura Llarga.
                        </p>

                        <p>
                            Una vegada seleccionat el producte s’hauran d’introduir les dades que s’indiquen a la plataforma de gestió del Test Radó per formalitzar la compra del producte.
                        </p>

                        <p>
                            El servei inclou:
                        </p>

                        <p>
                            -Accés en qualsevol moment del procés a la plataforma de gestió de la sol·licitud de Test Radó.
                        </p>
                        <p>
                            -Enviament de detectors (en un període aproximat de 48 hores). El número de detectors vindrà determinat en funció de les zones de mostreig de l’edifici i que s’indicaran a la plataforma de gestió del Test.
                        </p>

                        <p>
                            -Instruccions del col·locació i devolució dels detectors.
                        </p>

                        <p>
                            -Informació per al registre, complementació de dades i consulta del resultat de l’anàlisi. Servei de suport a través de formulari de consulta i/o telèfon.
                        </p>

                        <p>
                            -Resultat del Test Radó en Pdf amb l’anàlisi dels valors de la concentració de radó mesurats, durant el període d’exposició dels detectors, indicat per l’usuari a la seva sol·licitud.
                        </p>

                        <p>
                            El retorn dels sensors a l’adreça de devolució, es farà finalitzat el procés d’exposició per assegurar la fiabilitat dels resultats.
                        </p>

                        <p>
                            En cas contrari, el Cateb no es responsabilitzarà si l’entitat de mesurament no emet els resultats.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>
                            3. Preu.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Els preus dels productes Test Radó estan informats a la web abans d'acceptar la compra i porten l'IVA inclòs.
                        </p>
                        <p>
                            L’enviament dels detectors a les illes canàries té un cost addicional, el qual s’indica abans d’acceptar la compra.
                        </p>

                        <p>
                            En tot cas, les despeses de retorn dels detectors al Cateb per la seva lectura i redacció d’informe, seran a càrrec del client.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Procés de compra
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            Per adquirir els productes oferts a la web serà requisit previ registrar-se, seguint els passos establerts a la web i acceptant les condicions generals i particulars d'aquest contracte.
                        </p>

                        <p>
                            Un cop registrat es podrà realitzar la selecció del servei que es vol contractar cal introduir les dades necessàries per realitzar tot el. Un cop confirmat el pagament haurà iniciat la contractació del servei.

                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Forma de pagament
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            La forma de pagament del Test Radó, es podrá fer:
                        </p>

                        <p>

                            (1) Targeta de crèdit o dèbit. Com a modalitat de pagament s' ofereix pagament mitjançant targeta de crèdit o dèbit. Per efectuar el pagament el client serà dirigit a través d'una connexió a la passarel·la de pagament on haurà d'introduir les dades per realitzar la compra.

                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6.- Termini d’execució del contracte.
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            El termini d’execució del contracte és de 12 mesos i 3 setmanes des de la l’entrega dels detectors.
                        </p>

                        <p>
                            Un cop rebuts els detectors, el client disposa de 12 mesos per tornar-los al Cateb.
                        </p>
                        <p>
                            El Cateb enviarà al client el resultat obtinguts dels detectors, en un termini màxim de 3 setmanes des de que rebi els detectors.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7. Atenció postvenda
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>

                            Tots els dubtes, consultes, informació, incidències o qualsevol tipus de reclamació podran realitzar-se mitjançant l' adreça electrònica consultoriatecnica@cateb.cat.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Desistiment
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Es podrà desistir de la compra durant 14 dies naturals des el moment de la compra. En tot cas, no es podrà exercir aquest dret si s´ha manipulat el embolcall dels detectors, atès i que el mesurament s’haurà iniciat.
                        </p>

                        <p>
                            El desistiment de la compra comportarà la devolució de l' import abonat, tret les despeses de devolució dels detectors. En cas de que aquests no siguin retornats no es tornarà cap import.
                        </p>

                        <p>
                            Per exercir el dret de desistiment s'haurà d'emetre un correu electrònic consultoriatecnica@cateb.cat, amb l'assumpte "DESISTIMENT", manifestant de forma expressa que desitja desistir del contracte.
                        </p>
                        <p>
                            Una vegada exercit el seu dret de devolució, CATEB li reemborsarà el preu abonat i, si s' escau, les despeses d' enviament que hagi abonat, llevat que hagi triat expressament una modalitat de lliurament diferent a la menys costosa oferta per nosaltres. L' import li serà reemborsat en un termini màxim de 14 dies naturals des que hàgim rebut el producte utilitzant el mateix mitjà que es va utilitzar per al pagament. Si després de la recepció del producte detectem una disminució del valor com a resultat d' una manipulació diferent a la necessària per verificar les seves característiques i funcionalitats, aquesta disminució no se li reemborsarà.
                        </p>

                        <p>
                            Els Clients que no reuneixin la condició de consumidor i usuari, no tindran dret a desistir sobre cap producte o servei ofert a la nostra web.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Responsabilitats
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El client, manifesta que és major de 18 anys, té capacitat i reuneix les condicions definides en cadascun dels perfils descrits en la clàusula 2 de les condicions particulars.
                        </p>

                        <p>
                            El client s' obliga a realitzar un ús lícit dels serveis, contravenir la legislació vigent, ni lesionar els drets i interessos de terceres persones.
                        </p>

                        <p>
                            El client garanteix la veracitat i exactitud de les dades facilitades en emplenar els formularis de contractació evitant produir perjudicis al Cateb amb motiu de la incorrecció d' aquests.
                        </p>
                        <p>
                            El Cateb no té cap responsabilitat sobre la incorrecta utilització dels sensors, ni la pressa incorrecta del mesurament per part del client.
                        </p>

                        <p>
                            Ens cas de pèrdua o que es malmetin els sensors, el client no tindrà dret a demanar el retorn de l’import o una compensació per no poder finalitzar l’objecte del contracte. A més, el client haurà d’abonar les despeses que el fabricant dels detectors li reclami al Cateb.
                        </p>

                        <p>
                            L'incompliment de qualsevol de les Condicions de Contractació podrà donar lloc a la retirada o cancel·lació dels serveis per part del Cateb, sense necessitat de preavís al client i sense que això doni dret a indemnització.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10" id={to}>
                    <Accordion.Header>
                        <strong>
                            10- Protecció de dades
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb) tracta les dades personals que ens faciliten i aquelles que siguin obtingudes de la seva relació amb nosaltres, i seran incorporades a un fitxer o fitxers, responsabilitat del COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb). Les seves dades seran dedicades exclusivament a les finalitats relacionades amb la compra i la gestió de Test Radó.  En aquest sentit, es comunica que es realitzaran anàlisis de perfils i d' ús a partir de l' historial de serveis i activitats de l' interessat, per tal de millorar i dotar de valor afegit els serveis i activitats a disposició dels usuaris.
                        </p>
                        <p>
                            Les dades es conservaran durant tot el període en què es mantingui la relació amb nosaltres, així com durant el període de prescripció de les accions de responsabilitat per a l'exercici i/o defensa d'eventuals reclamacions.
                        </p>

                        <p>
                            Les dades tractades estan protegides amb els mitjans i sistemes tècnics necessaris per preservar la seva confidencialitat i evitar la pèrdua, alteració i accés no autoritzat als mateixos, d' acord amb els nivells de seguretat legalment demandats.
                        </p>

                        <p>
                            Drets dels afectats: en cas necessari, vostè pot exercir els drets d'accés, rectificació, supressió, limitació, portabilitat i oposició al tractament de les dades, dirigint-nos per escrit o per correu electrònic i acompanyant còpia del document oficial que l'identifiqui, al COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb), amb domicili al carrer Bon Pastor, nº 5, Cp 08021, Barcelona, o enviant un e-mail a info@cateb.cat, indicant en el sobre o en l'assumpte del missatge: Avís legal i de privacitat. Vostè també té dret a formular reclamació davant l'autoritat de control i a revocar el consentiment atorgat.
                        </p>

                        <p>
                            Altres dades d' interès:
                        </p>

                        <p>
                            Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb)
                        </p>

                        <p>
                            CIF	Q0875009C
                        </p>

                        <p>
                            Direcció	C. Bon Pastor, 5. 08021 Barcelona. Espanya
                        </p>

                        <p>
                            Contacte	Tel: 93 240 20 60; info@cateb.cat.
                        </p>

                        <p>
                            Delegat de Protecció de Dades: Institut Qualitas d' Assessoria BCN, S.L.
                        </p>

                        <p>
                            Contacte del Delegat de Protecció de Dades: dpd@cateb.cat.
                        </p>

                        <p>
                            Legitimació: la base legal és el consentiment de l' interessat.
                        </p>

                        <p>
                            Destinataris: Les seves dades podran ser comunicades als laboratoris encarregats de detectar els nivells de radó i redacció de l’informe corresponent, així com podran ser comunicades a tercers per al compliment de les obligacions legals i/o contractuals que puguin existir, per exemple, a l'Administració Pública, col·legi professional o consell de col·legis professionals que puguin ser competents en cada cas. En aquest sentit, l' informem que, en el cas de facilitar dades bancàries per al pagament dels productes adquirits, es procedirà a la seva comunicació a la nostra entitat financera a l' exclusiu efecte de gestionar el seu cobrament.
                        </p>

                        <p>
                            <strong>
                                DADES DE TERCERES PERSONES.
                            </strong>
                        </p>

                        <p>
                            Si vostè ens facilita dades de tercers, o ens les facilita en el futur, amb caràcter previ, vostè haurà d'haver informat i obtingut el consentiment del tercer, en relació amb tot l'exposat anteriorment.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </>
    )
};
