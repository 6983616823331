import { useLang } from "language";

const PLANTA = (data) => {
	return {
	  solicitud_radon_id: data?.solicitud_radon_id || '',
	  nombre: data?.nombre || '',
	  tipo_planta: data?.tipo_planta || '',
	  dispone_local: data?.dispone_local || '',
	  m_planta_habitable: data?.m_planta_habitable || '',
	  altura_planta_m: data?.altura_planta_m || '',
      tipo_zona_muestreo: data?.tipo_zona_muestreo || '',
      local_uso: data?.local_uso || '',
      local_habitable: data?.local_habitable || '',
	};
} 

const RULES = () => {

    const Lng = useLang()

	return {
        nombre: {
            required: {
                isOk: true,
                msgError: [Lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
        tipo_planta: {
            required: {
                isOk: true,
                msgError: [Lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
        /*dispone_local: {
            required: {
                isOk: true,
                msgError: [Lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },*/
        m_planta_habitable:{
            required: {
                isOk: true,
                msgError: [Lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
        altura_planta_m:{
            required: {
                isOk: true,
                msgError: [Lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        }
    };
}

export {PLANTA, RULES};