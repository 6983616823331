import React, {useEffect, useState} from 'react'
import { Form } from "react-bootstrap";
import { InputSelect } from "components/Inputs/InputSelect";
import { InputText } from "components/Inputs/InputText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSync} from '@fortawesome/free-solid-svg-icons'	
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { useFetch } from "hooks/useFetch";
import { useLang } from 'language';
import ValidateSpanishID from 'helpers/validateSpanishID';
import { toast } from 'react-toastify';

export const DatosPromotor = (props) => {
	const locale = useLang();
	const { formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData, parametros, fetchSentData, handleFormChangeEditar} = props;
	const [fetchPeticionario, fetchDataPeticionario] = useFetch('GET', `/clientes/solicitudes_radon/datos_peticionario`);
	
    const verificarDocumento = (event) => {
    	const inputValue = event.target.value.toUpperCase().replace(/\s/, '');
	   	
	    handleInputFormTestRadon({target:{
	    	name: 'nif_cif',
	    	value: inputValue
	    }})
    }

    const analziarDocumento = (e) => {
    	const documento = e.target.value;
    	const validationResult = ValidateSpanishID(documento);

    	if(!validationResult.valid){

    		handleInputFormTestRadon({target:{
		    	name: 'nif_cif',
		    	value: ''
		    }})

		    toast.error(locale('DOCUMENTO_INVALIDO'));
    	}else{
    		toast.success(locale('DOCUMENTO_CORRECTO'));
    	}
    }


	const copiarDatosPeticionario = (e) => {
		if(e){
			e.preventDefault()
		}
		fetchPeticionario();
	}

	useEffect(() => {
		if(fetchDataPeticionario.data?.data){
			const datos = fetchDataPeticionario.data.data;
			handleFormChangeEditar({
                ...formTestRadon,
                tipo_persona: datos.datos_fact_es_empresa ? 'empresa' : 'persona',
                nif_cif: datos.usuario_documento ||  '',
                nombre: datos.usuario_nombre ||  '',
                apellido: datos.usuario_apellidos ||  '',
                razon_social: datos.datos_fact_nombre ||  ''
            });		
		}
	}, [fetchDataPeticionario.data])

	return(
		<>
			<fieldset className="border p-2">
				<legend className="w-auto text-left">{locale('TEST_RADON_DATOS_PROPIETARIO')}</legend>
	          	<div className="row">	
	          		  <div className="col-lg-12 mb-3 text-end">
	          		  	 <ButtonSpinner
	          		  	 	disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
	                        variant="outline-primary-radon" type="button" className="ms-3 px-3" size='sm'
	                        handleSubmit={(e) => copiarDatosPeticionario(e)} fetchDataLoading={[fetchDataPeticionario.loading]}
	                        value={locale('COPIAR_DATOS_PETICIONARIO')} icon={<FontAwesomeIcon icon={faSync} className="me-1" />}
	                      />
	          		  </div>

	                  <Form.Group className="col-md-6 col-lg-3 mb-3">
	                    <InputSelect
	                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}	
		                  label={locale('TEST_RADON_TIPO_PERSONA')}
		                  className=''
		                  name='tipo_persona'
		                  values={
	                        parametros.data?.data?.tipo_persona_juridica?.map((item, index) => {
	                            return {
	                               id: item.param_id,
	                               nombre: item.texto
	                            }
	                        })
	                      }
		                  value={formTestRadon.tipo_persona}
		                  onChange={handleInputFormTestRadon}
		                  placeholder="tipo_persona"
		                  fetchData={fetchSentData}
		                />
	                  </Form.Group>

	                  {
		                formTestRadon.tipo_persona === 'empresa' && <>
		                  <Form.Group className="mb-3 col-md-6 col-lg-4">
		                    <InputText
		                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}	
		                      label='CIF *'
		                      className=''
		                      name='nif_cif'
		                      value={formTestRadon.nif_cif}
		                      onChange={(e) => {
		                      	//handleInputFormTestRadon(e)
		                      	verificarDocumento(e)
		                      }}
		                      onBlur={analziarDocumento}
		                      placeholder="otros"
		                      fetchData={fetchSentData}
		                    />
		                  </Form.Group>

		                  <Form.Group className="mb-3 col-md-6 col-lg-4">
		                    <InputText
		                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
		                      label={locale('TEST_RADON_RAZON_SOCIAL')}
		                      className=''
		                      name='razon_social'
		                      value={formTestRadon.razon_social}
		                      onChange={handleInputFormTestRadon}
		                      placeholder="razon_social"
		                      fetchData={fetchSentData}
		                    />
		                  </Form.Group>
		                </>
		              }

		              {
		                formTestRadon.tipo_persona === 'persona' && <>
		                  <Form.Group className="mb-3 col-md-6 col-lg-3">
		                    <InputText
		                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
		                      label='NIF / NIE *'
		                      className=''
		                      name='nif_cif'
		                      value={formTestRadon.nif_cif}
		                      onChange={(e) => {
		                      	//handleInputFormTestRadon(e)
		                      	verificarDocumento(e)
		                      }}
		                      onBlur={analziarDocumento}
		                      placeholder="nif_cif"
		                      fetchData={fetchSentData}
		                    />
		                  </Form.Group>

		                  <Form.Group className="mb-3 col-md-6 col-lg-3">
		                    <InputText
		                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
		                      label={locale('TEST_RADON_NOMBRE')}
		                      className=''
		                      name='nombre'
		                      value={formTestRadon.nombre}
		                      onChange={handleInputFormTestRadon}
		                      placeholder="nom"
		                      fetchData={fetchSentData}
		                    />
		                  </Form.Group>

		                  <Form.Group className="mb-3 col-md-6 col-lg-3">
		                    <InputText
		                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}	
		                      label={locale('TEST_RADON_APELLIDO')}
		                      className=''
		                      name='apellido'
		                      value={formTestRadon.apellido}
		                      onChange={handleInputFormTestRadon}
		                      placeholder="apellido"
		                      fetchData={fetchSentData}
		                    />
		                  </Form.Group>
		                </>
		              }
		         </div>   
	          </fieldset>
		</>
	);

}