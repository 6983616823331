import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFetch } from "hooks/useFetch";
import { useForm } from "hooks/useForm";
import { useValidator } from "hooks/useValidator";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { MsgInputServer } from "components/MsgInput/MsgInputServer";
import { InputText } from "components/Inputs/InputText";
import { useLang } from "language";
import Wizard from "components/Wizard/Wizard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSave, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { InputSelect } from 'components/Inputs/InputSelect';
import { ListAutocomplete } from 'components/ListAutocomplete/ListAutocomplete';
import { getSelectByKeys } from 'helpers/helpers';
import { TipoViasServices } from 'controllers/services/statics.service';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import _, { cloneDeep } from 'lodash';
import ValidateSpanishID from 'helpers/validateSpanishID';

export const ModalRegistro = (props) => {
    const locale = useLang();

    const { data, handleClose } = props;

    const [fetchComprobarNif, fetchComprobarNifData] = useFetch('POST', `/auth/comprobar_nif`)
    const [fetchParametros, fetchParametrosData] = useFetch('GET', `/parametros`)
    const [fetchRegisterExterno, fetchRegisterExternoData] = useFetch('POST', `/auth/register`)

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);

    const staticTipoViasServices = TipoViasServices.GET()

    const listWizard = [
        locale('HOME_BTN_REGISTRAR_TAB1'), 
        locale('HOME_BTN_REGISTRAR_TAB2'), 
    ];

    const [formComprobarNif, handleInputChangeComprobarNif] = useForm({
        nif: '',
    })

    const [valuesValidNif, handleVerifiedValuesNif] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DOCUMENTO')]
            }
        },
    })

    const handleSubmitComprobarNif =  (e) => {

        if(e){
            e.preventDefault();
        }

        if (handleVerifiedValuesNif(formComprobarNif)) {
            fetchComprobarNif({
                body: formComprobarNif
            })
        }
    }

    useEffect(() => {
        if(fetchComprobarNifData.data?.success === 'Success'){
            toast.success(fetchComprobarNifData.data?.message);

             handleFormChangeRegisterExterno({
                    ...formRegisterExterno,
                    documento: formComprobarNif.nif,
                })

            setIndexFocusWizard(1);
        }

    },[fetchComprobarNifData.data])

    /***********************/

    const [formRegisterExterno, handleInputChangeRegisterExterno, handleFormChangeRegisterExterno, handleResetRegisterExterno] = useForm({
        nombre: '',
        apellidos: '',
        telefono: '',
        documento: '',
        email: '',
        password: '',
        password_confirmation: '',
        direccion: '',
        provincia: '',
        poblacion: '',
        codigo_postal: '',
        pais: '',
        //tipo_documento: '',
        manifesto: '',
        calle: '',
    })


    /*Validación rules*/

    const [valuesValidRegisterExterno, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        nombre: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_NOMBRE')]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_APELLIDOS')]
            }
        },
        telefono: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_TELEFONO')]
            }
        },
        documento: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DOCUMENTO')]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CORREO')]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CONTRASENIA')]
            }
        },
        password_confirmation: {
            required: {
                isOk: true,
                msgError: [locale('V_CONFIRMACION_OBLIGATORIO')]
            }
        },
        calle: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DIRECCION')]
            }
        },
        provincia: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_PROVINCIA')]
            }
        },
        poblacion: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_POBLACION')]
            }
        },
        codigo_postal: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CODIGO_POSTAL')]
            }
        },
        pais: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_PAIS')]
            }
        },
        numero: {
            required: {
                isOk: true,
                msgError: [locale('EL_CAMPO_ES_OBLIGATORIO')]
            }
        }

    })

    /*Fin de la validación rules*/

     const handleSubmitFormRegister = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formRegisterExterno)) {

            const bodyTemp = {
                ...formRegisterExterno
            }

            if (!bodyTemp.comarca) delete bodyTemp.comarca

            fetchRegisterExterno({
                body: {
                    ...bodyTemp,
                    manifesto: formRegisterExterno.manifesto === 'true' ? true : false
                }
            })
        }
    }

     useEffect(() => {
        if(fetchRegisterExternoData.data?.status === 'Success'){
            toast.success(fetchRegisterExternoData.data?.message);
            handleClose();
        }
    },[fetchRegisterExternoData.data])

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)
    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formRegisterExterno.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    codigo_postal: formRegisterExterno.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                provincia: '',
                poblacion: '',
                pais: '',
            })
        }

    }, [formRegisterExterno.codigo_postal])

    useEffect(() => {

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data.status === 'Success') {

                const poblaciones = fetchPoblacionesData.data.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeRegisterExterno({
                    ...formRegisterExterno,
                    provincia: '',
                    poblacion: '',
                    pais: ''
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formRegisterExterno.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data]

            const resp = poblacionesTemp.find(item => item.poblacion === formRegisterExterno.poblacion)

            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                provincia: resp?.provincia,
                pais: resp?.pais,
            })
        }

    }, [formRegisterExterno.poblacion, fetchPoblacionesData.data])

     // -------------------- Autocompletado de Google

    const getPlaceDetails = (placeId) => {

        return new Promise(function (resolve, reject) {

            placesService.getDetails({
                placeId,
            },
                (place) => {
                    resolve(place);
                }
            );
        });
    };

    const handleSelectOption = async (place) => {

        const placeDetails = await getPlaceDetails(place.place_id)

        console.log('placeDetails', placeDetails);

        const location = _.groupBy(placeDetails?.address_components, 'types')

        handleFormChangeRegisterExterno({
            ...formRegisterExterno,
            codigo_postal: location?.['postal_code']?.[0]?.long_name || '',
            numero: location?.['street_number']?.[0]?.long_name || '',
            calle: location?.['route']?.[0]?.long_name || '',
            lat: String(placeDetails?.geometry?.location?.lat() || ''),
            lng: String(placeDetails?.geometry?.location?.lng() || ''),
        })
        handleResetPredictions()

    }

    const handleResetPredictions = () => {

        getPlacePredictions({ input: '' });
    }

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_GEO_API_KEY,
        options: {
            types: ['address'],
        }
    });

    const [isHoverAutocomplete, setisHoverAutocomplete] = useState(false);

    // -------------------- End Autocompletado de Google
    /*************************/

    const verificarDocumento = (event) => {
        const inputValue = event.target.value.toUpperCase().replace(/\s/, '');
        handleInputChangeComprobarNif({target:{
            name: 'nif',
            value: inputValue
        }})
    }

    const analizarDocumento = (e) => {
        const documento = e.target.value;
        const validationResult = ValidateSpanishID(documento);

        if(!validationResult.valid || validationResult.type === 'cif'){
            handleInputChangeComprobarNif({target:{
                name: 'nif',
                value: ''
            }})
            toast.error(locale('DOCUMENTO_INVALIDO'));
        }else{
            toast.success(locale('DOCUMENTO_CORRECTO'));
        }
    }

    return (
        <Modal show={data.value} onHide={handleClose} size="lg" bsPrefix="modal" className="animate__animated animate__fadeIn">
            <Modal.Header closeButton>
                <Modal.Title className="h5">{locale('HOME_REGISTER_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Wizard>
                    <Wizard.Header listWizard={listWizard} indexFocusWizard={indexFocusWizard} />
                    <Wizard.Body indexFocusWizard={indexFocusWizard}>
                        <section className="bg-white">
                            <Form className="row mt-5 d-flex justify-content-center align-items-center">
                                
                                <Form.Group className="col-12 col-md-6 col-lg-6 mb-4">
                                    <InputText
                                        label='NIF/NIE'
                                        className=""
                                        name="nif"
                                        value={formComprobarNif.nif}
                                        onChange={(e) => {
                                            //handleInputFormTestRadon(e)
                                            verificarDocumento(e)
                                        }}
                                        onBlur={analizarDocumento}
                                        placeholder="nif"
                                        validation={valuesValidNif}
                                        fetchData={fetchComprobarNifData}
                                    />
                                </Form.Group>

                                <div className="text-center">
                                    <small className="text-success">{locale('HOME_REGISTER_ES_COLEGIADO')}</small>
                                </div>

                                <div className="col-12 mt-2">
                                    <div className="d-flex flex-nowrap justify-content-between align-items-center">
                                        <div className='m-0'>
                                            <Button variant="default" onClick={handleClose}>
                                                {locale('HOME_BTN_CERRAR')}
                                            </Button>
                                        </div>
                                        <div className="m-0">
                                            <ButtonSpinner
                                                variant="primary"
                                                type="submit"
                                                className="ms-3 px-3"
                                                handleSubmit={handleSubmitComprobarNif}
                                                fetchDataLoading={[fetchComprobarNifData.loading]}
                                                value={locale('HOME_BTN_CONTINUAR')}
                                                iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </section>

                        <section className="bg-white">
                            
                        <div className="container animate__faster animate__animated animate__fadeIn">
                            <div className="row pt-3 pb-4 px-3">
                                <div className="col-12">
                                    <h5 className='mb-4'>{locale('HOME_REGISTER_DATOS_PERSONALES')}</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_DOCUMENTO')}
                                        disabled={true}
                                        className=''
                                        name='documento'
                                        value={formRegisterExterno.documento}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="documento"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

  
                                <Form.Group className="mb-3 col-lg-4">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_NOMBRE')}
                                        className=''
                                        name='nombre'
                                        value={formRegisterExterno.nombre}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="nombre"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-4">
                                    <InputText
                                       label={locale('HOME_REGISTER_INPUT_APELLIDO')}
                                        className=''
                                        name='apellidos'
                                        value={formRegisterExterno.apellidos}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="apellidos"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <div className="mb-4 d-flex align-items-center">
                                        <h5 className='me-2 mb-0'>{locale('HOME_REGISTER_DATOS_DIRECCION')}</h5>

                                    </div>
                                </div>

                                <Form.Group className="mb-4 col-md-6 col-lg-6">
                                    <InputSelect
                                        label={locale('TIPO_VIA') + ' *'}
                                        className=''
                                        name='tipovia'
                                        values={getSelectByKeys(staticTipoViasServices)}
                                        value={formRegisterExterno.tipovia}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="tipovia"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6 position-relative">

                                    <div className="form-floating">
                                        {/* <Autocomplete
                                                apiKey={process.env.REACT_APP_GOOGLE_GEO_API_KEY}
                                                onPlaceSelected={(place) => console.log('temp', formRegisterExterno)}
                                                options={
                                                    {
                                                        types: ['address'],
                                                    }
                                                }
                                                className='form-control'
                                                value={formRegisterExterno.calle}
                                                name='calle'
                                                onChange={handleInputChangeRegisterExterno}
                                                autoComplete='one-time-code'
                                            />
                                        <label>Adreça*</label> */}

                                        <InputText
                                            label={locale('HOME_REGISTER_INPUT_DIRECCION')}
                                            className=''
                                            name='calle'
                                            value={formRegisterExterno.calle}
                                            onChange={(evt) => {
                                                getPlacePredictions({ input: evt.target.value });
                                                handleInputChangeRegisterExterno(evt);
                                            }}
                                            placeholder="calle"
                                            // validation={valuesValidRegisterExterno}
                                            fetchData={fetchRegisterExternoData}
                                            onBlur={(e) => { isHoverAutocomplete ? e.preventDefault() : handleResetPredictions() }}
                                        />
                                        <ListAutocomplete
                                            list={placePredictions}
                                            handleClick={handleSelectOption}
                                            nameTitle='description'
                                            onMouseOver={() => setisHoverAutocomplete(true)}
                                            onMouseOut={() => setisHoverAutocomplete(false)}
                                        />

                                        {/* <input ref={ref} style={{ width: "90%" }} defaultValue="Amsterdam" onChange={handleInputChangeRegisterExterno}/> */}

                                    </div>
                                </Form.Group>


                                <div className="col-12"></div>

                                <Form.Group className="mb-4 col-md-6 col-lg-3">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_NUMERO')}
                                        className=''
                                        name='numero'
                                        value={formRegisterExterno.numero}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="numero"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-3">
                                    <InputText
                                        className=''
                                        label={locale('HOME_REGISTER_INPUT_PISO') + ' *'}
                                        value={formRegisterExterno.piso}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="piso"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-3">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_PUERTA')}
                                        className=''
                                        name='puerta'
                                        value={formRegisterExterno.puerta}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="puerta"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-3">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_ESCALERA')}
                                        className=''
                                        name='escalera'
                                        value={formRegisterExterno.escalera}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="escalera"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                {/* <Form.Group className="mb-3 col-sm-6 col-lg-3">
                                    <InputSelect
                                        label='País*'
                                        className=''
                                        name='pais'
                                        values={fetchParametrosData.data?.data?.paises}
                                        value={formRegisterExterno.pais}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="pais"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group> */}

                                <Form.Group className="mb-3 col-sm-6 col-lg-3">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_COD_POSTAL')}
                                        className=''
                                        name='codigo_postal'
                                        value={formRegisterExterno.codigo_postal}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="codigo_postal"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                    <InputSelect
                                        label={locale('HOME_REGISTER_INPUT_POBLACION')}
                                        className=''
                                        name='poblacion'
                                        // values={[
                                        //     { id: 'espana', nombre: 'España' },
                                        // ]}
                                        values={poblaciones}
                                        value={formRegisterExterno.poblacion}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="poblacion"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                        disabled={!fetchPoblacionesData.data || formRegisterExterno.codigo_postal?.length !== 5}
                                    />
                                </Form.Group>

                                {/*
                                <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                    <InputText
                                        label='Comarca*'
                                        className=''
                                        name='comarca'
                                        value={formRegisterExterno.comarca}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="comarca"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>
                                */}

                                <div className="col-12"></div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_TELEFONO')}
                                        className=''
                                        name='telefono'
                                        value={formRegisterExterno.telefono}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="telefono"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_EMAIL')}
                                        className=''
                                        name='email'
                                        value={formRegisterExterno.email}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="email"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <h5 className='mb-4 mt-3'>{locale('HOME_REGISTER_DATOS_ACCESO')}</h5>
                                </div>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_PASSWORD')}
                                        className=''
                                        name='password'
                                        type='password'
                                        value={formRegisterExterno.password}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="password"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label={locale('HOME_REGISTER_INPUT_CCPASSWORD')}
                                        className=''
                                        type='password'
                                        name='password_confirmation'
                                        value={formRegisterExterno.password_confirmation}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="password_confirmation"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12 mt-2 text-center">
                                    <MsgInputServer obj={fetchRegisterExternoData.data} className='mt-4' />
                                </div>

                            </div>
                        </div>

                        <div className="col-12 mt-2">
                                <div className="d-flex flex-nowrap justify-content-between align-items-center">
                                     <div className='m-0'>
                                        <ButtonSpinner
                                            variant="outline-primary-radon" type="submit" className="px-3"
                                            handleSubmit={() => setIndexFocusWizard(0)} fetchDataLoading={[false]}
                                            value={locale('HOME_BTN_ATRAS')} icon={<FontAwesomeIcon icon={faArrowLeft} className="me-1" />}
                                        />

                                    </div>
                                    <div className="m-0">
                                        <ButtonSpinner
                                            variant="primary"
                                            type="submit"
                                            className="ms-3 px-3"
                                            handleSubmit={handleSubmitFormRegister}
                                            fetchDataLoading={[fetchRegisterExternoData.loading]}
                                            value={locale('HOME_BTN_REGISTRAR')}
                                            iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                        />
                                    </div>
                                </div>
                            </div>

                        </section>
                    </Wizard.Body>
                </Wizard>
            </Modal.Body>
        </Modal>
    );
};
