import React from "react";
import { Accordion } from "react-bootstrap";

export const AvisoLegalEs = () => {
    return (
        <>
            <h5 className="text-center mb-4">
                Aviso legal y condiciones de uso de la web
            </h5>
            <Accordion alwaysOpen>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <strong>
                            1. Aviso legal
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El prestador de Servicios y titular de este sitio web es el COL·LEGI D'ARQUITECTURA TÈCNICA DE BARCELONA (CATEB en adelante), amb NIF Q0875009C, domicilio en C. Bon Pastor 5 – CP: 08021 Barcelona, encontrándose inscrita en el registro de Col·legis Professionals de la Generalitat de Catalunya con número de inscripción ATiAP. Si quieres contactar con nosotros puedes hacerlo mediante el teléfono o escribiendo al correo electrónico: consultoriatecnica@cateb.cat.

                        </p>
                        <p>
                            Este sitio web tiene por objeto promocionar y ofrecer servicios relacionados con el ámbito de actuación de Cateb a través de la puesta a disposición de aplicaciones informáticas, servicios, programas e información a través de nuestra aula virtual. El idioma de las presentes condiciones es en catalán, por la cual cosa, en caso de discrepancia en la interpretación de las condiciones, el castellano, será el idioma que prevalezca. Cualquier traducción que se realice sobre éstas, no afectará la prevalencia indicada.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <strong>2. Funcionamiento de nuestro sitio web</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>2.1.¿Cómo es nuestro sitio web?</strong>
                        </p>
                        <p>
                            Se podrá navegar por nuestro sitio web sin necesidad de estar registrado como usuario. Podrá conocer los productos y servicios que ofrecemos y contactar con nosotros.
                        </p>
                        <p>
                            La contratación de alguno de nuestros productos o servicios puede requerir un registro previo como usuario en el que se le solicitará un documento identificativo, un correo electrónico y una contraseña como credencial de acceso a su perfil de usuario. El registro le permitirá acceder a determinadas aplicaciones informáticas de pago que precisen la identificación de un usuario para el correcto funcionamiento.
                        </p>
                        <p>
                            En estos casos, para registrarse se necesitará, con carácter previo, que se lea, comprenda y acepte las presentes condiciones y la Política de Privacidad.
                        </p>
                        <p>
                            Las contrataciones de productos o servicios se regirán por las estipulaciones recogidas en las Condiciones generales y particulares de Contratación, que deberán ser leídas, comprendidas y aceptadas antes de realizar la contratación.
                        </p>
                        <p>
                            En el resto de los casos, el usuario podrá navegar y utilizar libremente los recursos y contenidos ofrecidos en nuestro sitio web, respetando y cumpliendo con las obligaciones y limitaciones establecidas en las presentes condiciones.
                        </p>
                        <p>
                            Estas condiciones serán de aplicación junto con el resto de las condiciones que pudieran resultar de aplicación como la Política de Privacidad.
                        </p>

                        <p>
                            <strong>2.2.Tipo de usuarios de nuestro sitio web</strong>
                        </p>
                        <p>
                            Usuarios.
                        </p>
                        <p>
                            Serán aquellos usuarios que accedan y hagan uso de nuestro sitio web sin realizar compras ni registrarse.
                        </p>
                        <p>
                            Usuarios registrados.
                        </p>
                        <p>
                            Serán aquellos usuarios que se hayan dado de alta en nuestro sitio web mediante la creación de un perfil de usuario.
                        </p>
                        <p>
                            Podrán acceder a registrarse en la web www.testradon.es, los miembros del Consejo General de la Arquitectura Técnica de España (CGATE en adelante), así como cualquier usuario que quiera solicitar la contratación de nuestro producto básico.
                        </p>
                        <p>
                            El Registro es el paso previo para adquirir los productos ofertados en la web, en las condiciones que regulan cada productos que deban ser aceptadas de forma expresa en el momento de su adquisición.
                        </p>
                        <p>
                            Este registro no otorga ningún derecho sobre la web ni sobre los productos, excepto que se adquieran alguno de ellos de forma expresa.
                        </p>
                        <p>
                            El registro es personal y no se puede dar de alta a terceras personas, ni compartir las claves de acceso a los productos.
                        </p>
                        <p>
                            El registro, sin compra, podrá ser eliminado por el Cateb, en cualquier momento sin previo aviso.
                        </p>
                        <p>
                            Usuarios/Clientes.
                        </p>
                        <p>
                            Lo constituirán las personas que hayan realizado alguna compra o hayan estado dados de alta en nuestro sitio web, habiéndose leído, comprendido y aceptado previamente las presentes condiciones, las Condiciones generales de contratación y la Política de privacidad.
                        </p>
                        <p>
                            <strong>2.3. Seguridad de nuestro sitio web </strong>
                        </p>
                        <p>
                            Nuestra web utiliza el protocolo seguro de transferencia de hipertexto (Hypertext Transfer Protocol Secure, HTTPS) garantizando la confidencialidad de las comunicaciones.
                        </p>
                        <p>
                            El protocolo HTTPS provee protección y garantía del envío de información a través de internet, de manera que los datos y la información enviada desde nuestra web al servidor no puede ser leída ni interceptada por terceros, ya que la información viaja cifrada.
                        </p>



                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <strong>3. Condiciones de uso de nuestro sitio web para todos los usuarios web</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>3.1. Obligaciones del Usuario</strong>
                        </p>
                        <p>
                            El usuario asume las obligaciones de:
                        </p>
                        <p>
                            (1) Realizar un uso responsable y razonable de nuestra web de acuerdo con estas condiciones de uso y la legalidad vigente;
                        </p>

                        <p>
                            (2) No realizar actividades contrarias a la legislación española, especialmente en lo relativo a actuaciones constitutivas de delito según el Código Penal. En concreto, el usuario no deberá acceder a información de terceros que no haya estado autorizada, no suplantará la identidad de terceros y será el responsable de proporcionar datos exactos y veraces.
                        </p>
                        <p>
                            <strong>3.2. Usos permitidos</strong>
                        </p>
                        <p>

                            El Usuario podrá hacer un uso responsable de nuestro sitio web conforme a los usos y costumbres, buena fe, moral y orden público. Toda aquella conducta ajena a estos principios estará prohibida y perseguida conforme la normativa vigente.

                        </p>

                        <p>
                            <strong>3.3. Usos prohibidos</strong>
                        </p>

                        <p>
                            Queda prohibido el acceso o la utilización de los productos ofrecidos en nuestra web con finalidades ilegales o no autorizadas. En concreto, a título enunciativo pero no limitativo, queda prohibido:
                        </p>
                        <p>
                            – Usar el servicio con finalidades ilícitas o realizar actividades contrarias a la buena fe y al orden público.
                        </p>
                        <p>
                            – Difundir contenido o propaganda de carácter racista, xenófobo, pornográfico ilegal, de apología al terrorismo o tentativo contra los derechos humanos.
                        </p>
                        <p>
                            – Usar cualquiera de los servicios del sitio web de manera que se puedan dañar, sobrecargar o perjudicar el servicio.
                        </p>
                        <p>
                            – Utilizar o introducir en el servicio malware que puedan producir daños o alteraciones en nuestros sistemas, en los contenidos o en el sistema informático de otros usuarios y/o de sus terminales móviles.
                        </p>
                        <p>
                            – El uso de recursos técnicos, lógicos o tecnológicos en virtud de los cuales algún usuario o no del servicio, pueda beneficiarse directamente o indirectamente, con o sin ánimo de lucro, de los perfiles y de los contenidos del sitio web.
                        </p>
                        <p>
                            En definitiva, quedan prohibidos los usos de la WEB que sean contrarios a la  normativa de Propiedad Intelectual e Industrial, Protección de Datos, Ley de Competencia Desleal, Código Civil y Mercantil, así como cualquier otra que pudiera resultar aplicable.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <strong>
                            4. Régimen de responsabilidades
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>4.1. Responsabilidad del Usuario</strong>
                        </p>
                        <p>
                            El Usuario será responsable de:
                        </p>
                        <p>

                            (1) Los malos usos realizados sobre el contenido o servicios disponibles a través de nuestro sitio web de acuerdo con lo que se indica en las presentes condiciones.

                        </p>
                        <p>
                            (2) De los daños y perjuicios causados a Cateb, incluidos los daños emergentes y lucro cesante, así como de los gastos judiciales y honorarios de abogados y procuradores a los cuales se ven sometidos como consecuencia de la actuación dolosa o negligente del usuario.
                        </p>
                        <p>
                            <strong>4.2. Limitación de la responsabilidad del titular</strong>
                        </p>
                        <p>

                            CATEB no será responsable de:

                        </p>
                        <p>
                            – La inexistencia de posibles errores o faltas de actuación en el sitio web, reservándose el derecho a efectuar en cualquier momento y sin necesidad de aviso previo, modificaciones y actualizaciones sobre la información contenida en su portal, configuración o presentación.
                        </p>
                        <p>
                            – Los fallos e incidencias que pudieran producirse en las comunicaciones, borrado o transmisiones incompletas, dado que pueden producirse fallos de la red u otro tipo de situaciones de las cuales CATEB no es directamente responsable y no podrá asumir responsabilidad por ello ni garantizar que los servicios del sitio web estén constantemente operativos, quedando eximida de cualquier daño que esto pueda provocar.
                        </p>

                        <p>
                            – Los errores o daños producidos por la negligencia o mala fe en el uso del servicio por parte del Usuario y/o Cliente.
                        </p>
                        <p>
                            – La producción de cualquier tipo de daño que el Cliente o terceros puedan ocasionar en el sitio Web.
                        </p>
                        <p>
                            – Sin perjuicio de lo que se dispone en materia de protección de datos personales, el CATEB no garantiza la privacidad y seguridad de la utilización de la Web y de los Servicios y, en particular, que terceros no autorizados no puedan tener conocimiento del tipo, las condiciones, las características y las circunstancias del uso que los Usuarios hagan de la Web y de los Servicios.
                        </p>

                        <p>
                            CATEB se reserva el derecho a suspender el acceso sin previo aviso cuando se detecte cualquier situación en la que el Usuario y/o Cliente no haya actuado diligentemente y/o se haya aprovechado de las funcionalidades de la página web. La suspensión del servicio podrá tener carácter temporal o definitivo y CATEB no será responsable de los perjuicios que la suspensión pueda ocasionar la usuario/cliente.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <strong>
                            5. Política de contenidos
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>5.1. Contenides de nuestro sitio web </strong>
                        </p>
                        <p>

                            Nuestro sitio web ofrece un amplio contenido técnico con recursos de diversa índole. Todo el contenido es propiedad de CATEB o de sus licenciatarios, por la cual cosa el usuario no tendrá ningún derecho de titularidad sobre los mismos.

                        </p>
                        <p>
                            CATEB realiza trabajos constantes para mantener actualizados los contenidos, pero no garantiza que puedan existir errores o fallos, no asumiendo ninguna responsabilidad por ello.
                        </p>
                        <p>
                            <strong>5.2. Limitaciones al uso de los contenidos</strong>
                        </p>

                        <p>

                            – Los contenidos solo podrán ser utilizados con finalidades personales, quedando expresamente prohibido el uso con finalidades comerciales o lucrativas, resultando de aplicación las estipulaciones contenidas en la cláusula 6.1. de las presentes condiciones.

                        </p>
                        <p>
                            – El usuario deberá de respetar y cumplir con las obligaciones y limitaciones establecidas en la cláusula 6 de las presentes condiciones en relación con el uso que haga de los diferentes tipos de contenidos disponibles en nuestro sitio web.
                        </p>




                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <strong>
                            6. Derechos de Propiedad Intelectual
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Todos los derechos sobre los contenidos publicados y ofrecidos por CATEB en el sitio web están protegidos por los derechos de propiedad intelectual e industrial. CATEB ha desarrollado y dispone de las licencias y autorizaciones necesarias sobre los contenidos del sitio web, los recursos, las herramientas, así como el código de programación y diseño de los productos y servicios digitales ofrecidos.
                        </p>
                        <p>
                            Los artículos, contenidos, imágenes o logotipos del Colegio son titularidad del CATEB o de las empresas licenciatarias que han permitido su uso y publicación expresa.
                        </p>
                        <p>
                            Se prohíbe expresamente, salvo autorización por escrito y expresa de CATEB, la reproducción, transformación, distribución, comunicación pública, puesta a disposición al público, venta u otros usos diferentes a los expresamente autorizados por CATEB en las presentes condiciones, en las políticas y otros avisos publicados en su página web, así como a las condiciones particulares, si procede, ya sea con finalidades comerciales o no, cuando comporten la explotación de los contenidos, productos o servicios ofrecidos por CATEB, la finalidad de las cuales sean distintas a las previstas en estas condiciones.
                        </p>
                        <p>
                            <strong>6.1. Derechos de propiedad intelectual</strong>
                        </p>
                        <p>

                            El Usuario reconoce expresamente, y a todos los efectos, que la información, firmas, imágenes, diseños, logotipos, animaciones, vídeos, textos, así como los otros elementos y contenidos, incluidos los servicios y aplicaciones informáticas de nuestro sitio web, están sujetas a derechos de propiedad industrial e intelectual de CATEB y/o de terceros titulares que hayan autorizado debidamente su inclusión en la web, manifestándose expresamente que no se concederá ningún tipo de derecho sobre ningún contenido de cualquier tipo, excepto de los que expresamente se reconozcan.

                        </p>
                        <p>
                            Consecuentemente, todos los derechos están reservados, quedando expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con finalidades comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del titular.
                        </p>

                        <p>
                            Los usuarios:
                        </p>

                        <p>
                            (1) deberán abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que esté instalado en las páginas del titular.
                        </p>
                        <p>
                            (2) podrán visualizar los elementos del sitio web, imprimirlos, realizar copias o descargas de contenidos siempre que estas acciones estén dirigidas exclusivamente para su uso personal y privado.
                        </p>
                        <p>
                            (3) No podrán realizar enlaces a este sitio web sin el consentimiento de su titular y, si procede, solo se permitirán enlaces a la página principal.
                        </p>
                        <p>
                            (4) No se permite el enlace a "páginas finales", actos de "frame" o cualquier otra manipulación similar a esta página sin consentimiento expreso y por escrito por parte de CATEB, excepto se conceda autorización expresa.
                        </p>
                        <p>
                            En caso que el titular detecte un uso ilícito de cualquiera de los contenidos disponible en el sitio web, emprenderá las acciones judiciales correspondientes de conformidad con el artículo 140 de la Ley de Propiedad Intelectual y el artículo 270 del Código Penal en caso que los actos sean delictivos.
                        </p>



                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <strong>
                            7.- Precios de los productos y servicios
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            El uso de la Web es gratuito, pero si se quiere adquirir alguno de los productos, el precio vendrá marcado en cada uno de ellos.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        <strong>
                            8. Duración y finalización
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            La prestación del servicio de la WEB i de otros Servicios tiene en principio una duración indefinida. No obstante, el Cateb está autorizado para finalizar o suspender la prestación del servicio Web y/o de los Servicios en cualquier momento, sin perjuicio de lo que se haya dispuesto en lo relativo al tema de las condiciones particulares correspondientes. Cuando esto sea razonablemente posible, el Cateb advertirá previa a la finalización o suspensión de la prestación del servicio de la Web y de otros Servicios.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        <strong>
                            9. Política de cookies
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>9.1.- Definición y función de las cookies</strong>
                        </p>

                        <p>
                            ¿Qué son las cookies? Una cookie o galleta es un archivo que se descarga en su dispositivo cuando accede a determinadas páginas web. Las cookies permiten en una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo, dependiendo de la información que contengan y de la forma en que utilice su equipo, se pueden utilizar para reconocer al usuario.
                        </p>

                        <p>
                            <strong>9.2- ¿Qué tipo de cookies utiliza esta página web?</strong>
                        </p>

                        <p>
                            • Cookies técnicas y de personalización: necesarias o convenientes para la prestación de determinados servicios. Las primeras permiten al usuario la navegación a través de la página web y la utilización de los diferentes servicios que hay y las segundas permiten acceder al usuario al servicio con algunas características de carácter general predefinidas. Se utilizan cookies para el idioma, para el gestor de contenidos y para el mantenimiento de sesión cuando sea necesario. Si se desactivan estas Cookies se podría no recibir correctamente los contenidos del sitio web.
                        </p>

                        <p>
                            • Cookies analíticas, ya sea porque son tratadas por nosotros o por terceros, permiten hacer el seguimiento y análisis estadística del comportamiento del conjunto de los usuarios. Si se desactivas estas galletas, el sitio web podría seguir funcionando, sin perjuicio que la información captada por estas cookies sobre el uso de nuestra web y sobre su contenido permitan mejorar los servicios.
                        </p>

                        <p>
                            • Cookies de redes sociales externas, que se utilizan para que los visitantes puedan interactuara con el contenido de diferentes plataformas sociales (Facebook, YouTube, Twitter, LinkedIn, etc.)
                        </p>

                        <p>
                            • Cookies de publicidad: este tipo de cookies permiten ampliar la información de los anuncios mostrados a cada usuario anónimo. Pueden ser tratadas por nosotros o por terceros y nos permiten gestionar de forma más eficaz posible la oferta de espacios publicitarios que hay en la web, adecuando el contenido de cada anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web por el cual podremos analizar sus hábitos de navegación y podremos mostrarle publicidad relacionada con su perfil de navegación. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con estas, o de los patrones de navegación y/o comportamientos del usuario, ya que ayudan a conformar un perfil de interés publicitario.
                        </p>

                        <p>
                            <strong>9.3. Forma de desactivar o eliminar las cookies</strong>
                        </p>

                        <p>
                            Usted puede permitir bloquear o eliminar las cookies instaladas en su dispositivo mediante la configuración de las opciones del navegador en vuestro dispositivo.
                        </p>

                        <p>
                            •	Configuración de Internet Explorer
                        </p>
                        <p>
                            •	Configuración de Mozilla Firefox
                        </p>
                        <p>
                            •	Configuración de Google Chrome
                        </p>
                        <p>
                            •	Configuración de Safari
                        </p>

                        <p>
                            En caso que no acepte las cookies es posible que la página web no funcione correctamente.
                        </p>

                        <p>
                            <strong>9.4. Información sobre la identificación de quien utiliza las cookies</strong>
                        </p>

                        <p>

                            La información obtenida por las cookies es tratada por el COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA en la que se ha contratado la prestación del servicio de analítica con Google Analytics donde siguiendo el enlace podrá acceder a sus condiciones legales.

                        </p>

                        <p>

                            También puede ser que haya complementos de otros proveedores en los que siguiendo los siguientes vínculos podrá comprobar las condiciones legales respecte de las cookies de cada uno de ellos:

                        </p>

                        <p>
                            Más información:
                        </p>
                        <p>
                            YouTube
                        </p>
                        <p>
                            Twitter
                        </p>
                        <p>
                            LinkedIn
                        </p>

                        <p>
                            <strong>9.5. Actualización de la política de cookies</strong>
                        </p>

                        <p>
                            Es posible que para nuevas interpretaciones realizadas por el organismo regulador competente, jueces o Tribunales, esta política de cookies pueda sufrir alguna modificación por la cual se ruega al usuario que comprueba de forma regular su contenido.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <strong>
                            10.- JURISDICCIÓN Y COMPETENCIA
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            En caso que se produzca cualquier discrepancia cuando en la aplicación o interpretación de sus presentes condiciones, resultará aplicable el Derecho Español y serán competentes para conocer la controversia los Juzgados y Tribunales de Barcelona capital (España).
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        <strong>
                            11. PROTECCIÓN DE DATOS
                        </strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Introducción: Con la información que le proporcionaremos a continuación el COLEGIO DE LA ARQUITECTURA TÉCNICA DE BARCELONA (en lo sucesivo: el COLEGIO), quiere que usted conozca cuál es la política de privacidad aplicada respecto a sus datos personales.
                        </p>
                        <p>
                            Cumplimiento de la normativa de protección de datos: el COLEGIO cumple todos los requisitos establecidos por la normativa vigente en materia de protección de datos personales, y todos los datos bajo nuestra responsabilidad, son tratadas de acuerdo con las exigencias legales.
                        </p>
                        <p>
                            Medidas de Seguridad: se guardarán las debidas medidas de seguridad tanto técnicas como organizativas, que garanticen la confidencialidad de los datos.
                        </p>
                        <p>
                            Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA.
                        </p>
                        <p>
                            CIF	Q0875009C
                        </p>
                        <p>
                            Dirección:	C. Bon Pastor, 5. 08021 Barcelona. España
                        </p>
                        <p>
                            Contacto	Tel: 93 240 20 60;  info@cateb.cat.
                        </p>
                        <p>
                            Delegado de Protección de Datos: Institut Qualitas d’Assessoria BCN SL
                        </p>
                        <p>
                            Contacto del Delegado de Protección de Datos: dpd@cateb.cat
                        </p>
                        <p>
                            Finalidades: gestión de la relación entre el responsable i el interesado en lo relativo a la gestión de la información referente a los servicios ofrecidos en esta web.
                        </p>

                        <p>
                            Se comunica que se realizarán análisis de perfiles y de uso a partir del historial de servicios y actividades del interesado con la finalidad de mejorar y dotar de valor añadido a los servicios y actividades a disposición de los usuarios.
                        </p>
                        <p>
                            Legitimación: consentimiento del interesado.
                        </p>

                        <p>
                            Destinatarios: sus datos solo podrán ser comunicados a terceros para el cumplimiento de las obligaciones legales y contractuales que puedan existir o bien con el consentimiento expreso del interesado.
                        </p>

                        <p>
                            Derechos de los afectados: en caso necesario, puede ejercer sus derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición en el tratamiento de los datos, dirigiéndose por escrito o por correo electrónico y acompañante copia de documento oficial que lo identifique en el COLEGIO DE LA ARQUITECTURA TÉCNICA DE BARCELONA, con domicilio en la calle Bon Pastor, n.º 5, CP 08021, Barcelona, o enviando un e-mail a info@apabcn.cat, indicando en el sobre o en el asunto del mensaje: Aviso legal y de privacidad. También tiene derecho a formular una reclamación delante de la autoridad de control. Usted puede revocar el consentimiento en cualquier momento.
                        </p>

                        <p>
                            Datos de terceros: si usted nos facilita datos personales de un tercero, deberá, con carácter previo, informarlo y pedido su consentimiento sobre los términos aquí expuestos.
                        </p>

                        <p>
                            Los datos se conservarán durante todo el período de tiempo en que se mantenga su relación con nosotros y posteriormente durante todo el período de prescripción las acciones de responsabilidad.
                        </p>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

        </>
    )
};
