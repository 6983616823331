import queryString from 'query-string';

export function getQueryStringSearchCollegiats(objParams) {
  return `?${queryString.stringify({
    colegio_id: objParams.colegio_id ? objParams.colegio_id : undefined,
    page: objParams.page !== '' ? objParams.page : undefined
  })}`
}

export function getMsgInputServerColor(objParams, name) {
  return objParams?.data?.status === 'Error' && objParams?.data?.data?.[name]
}

export function getMsgInputColor(objParams, name) {
  return objParams[name]?.required && !objParams[name]?.required?.isOk
}

export function getValuesParams(objValuesSearch) {
  let urlParams = {}

  for (const key of Object.keys(objValuesSearch)) {
    if (objValuesSearch[key] !== '') {
      urlParams[key] = objValuesSearch[key]
    }
  }

  return urlParams
}

export function getURLQuery(objUrlParams) {
  return Object.keys(objUrlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(objUrlParams[k])).join('&');
}

export function isNoVerificada(item) {
  return item.estado === 'Pendent de revisar' || item.estado === 'Buit'
}

export function isCheckTrue(item) {

  if (item === null || item === '' || item === 'false' || item === false)
    return false
  return true
}

export function getSelectByKeys(staticService) {

  const res = Object.keys(staticService).map((key) => {
    return {
      id: key,
      nombre: staticService[key]
    }
  })

  return res
}

export function isStatusSuccess(status) {

  return String(status)?.[0] === '2'
}

export function isStatusError(status) {

  return String(status)?.[0] === '4' || String(status)?.[0] === '5'
}

export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  // console.log('result', result);
  return result;
}

export function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getDataFile(fetchData, handleReset, nombre = 'default', extension = 'pdf') {

  if (fetchData.data) {
    // 2. Create blob link to download
    const docData = fetchData.data

    // console.log('docData', docData);

    const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || `${nombre}.${extension}`
    const blobData = docData?.data

    const instanceBlob = new Blob([blobData])

    const date = new Date()

    const url = window.URL.createObjectURL(instanceBlob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
    // 3. Append to html page
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    if (handleReset) {

      handleReset()
    }

  }
}

export function isBetweenDates(fechaInicio, fechaFin) {

  const fechaActual = new Date();

  const inicio = new Date(fechaInicio);
  let fin = new Date(fechaFin);

  fin.setDate(fin.getDate() + 1);

  return fechaActual >= inicio && fechaActual <= fin;
}