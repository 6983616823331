import { faBars, faEnvelope, faSave, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Form, Modal, Nav, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { images } from '../../helpers/images';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { types } from '../../types/types';
import { useLang } from '../../language/';

import './NavtopInner.css'
import { InputText } from '../Inputs/InputText';
import { ModalStatus } from '../ModalStatus/ModalStatus';
import { DropdownLanguage } from '../Layouts/DropdownLanguage/DropdownLanguage';
import { useModalData } from 'hooks/useModalData';
import { ModalContacto } from 'pages/Home/ModalContacto';
import { inIframe } from 'helpers/helpers';

export const NavtopInner = ({ setShow, show }) => {

    const { user: { nombre, apellidos, lang }, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const Lang = useLang();

    // const [fetchLogOut, fetchLogOutData] = useFetch('DELETE', `/auth/logout`)

    const handleLogout = () => {

        // fetchLogOut()

        history.replace('/login');

        dispatch({
            type: types.logout,
            lang
        });

    }

    const [showModalChangePassword, setShowModalChangePassword] = useState(false);

    const handleClose = () => setShowModalChangePassword(false);
    const handleShow = () => setShowModalChangePassword(true);

    useEffect(() => {
        if (!showModalChangePassword) {

            resetFormValuesPassword()

            resetValuesValidPassword()
        }
    }, [showModalChangePassword])

    const [fetchPassword, fetchPasswordData] = useFetch('POST', `/clientes/usuarios/me/cambiar_password`)

    const [formValuesPassword, handleInputChangePassword, resetFormValuesPassword] = useForm({
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    })

    const [valuesValidPassword, handleVerifiedValuesPassword, resetValuesValidPassword] = useValidator({
        old_password: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        },
        new_password: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        },
        new_password_confirmation: {
            required: {
                isOk: true,
                msgError: [Lang('V_OBLIGATORIO_CAMPO')]
            }
        }
    })

    const handleSubmitCreateUser = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesPassword(formValuesPassword)) {

            fetchPassword({
                body: {
                    ...formValuesPassword
                }
            })
        }
    }

    const [showStatusVerify, setShowStatusVerify] = useState(false);

    useEffect(() => {

        if (fetchPasswordData.data?.status === 'Success') {

            console.log('success')
            handleClose()
            setShowStatusVerify(true)
        }

    }, [fetchPasswordData])

    const [dataModal_Contacto, handleOpenModal_Contacto, handleCloseModal_Contacto] = useModalData()


    return (
        <>
            <section className="NavtopInner d-flex justify-content-between align-items-center">
                <div className="fw-bold">
                    <h5 className="my-0">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faBars} className='me-3 d-md-none' id='icon-bars' onClick={() => setShow(!show)} />


                            {lang === 'CA' &&
                                <img src="/logo_test_rado.png" width="170" height="35" className="mx-auto d-block me-2" alt="Logo" />
                            }

                            {lang === 'ES' &&
                                <img src="/logo_test rado_CAST.png" width="170" height="35" className="mx-auto d-block me-2" alt="Logo" />
                            }

                            {/*
                            <img src={images('./logo.png').default ? images('./logo.png').default : images('./logo.png')} className="mx-auto d-block me-2" alt="logo" id="logo-inner" />
                            */}
                            {/* <strong className="fw-500 d-none d-md-block">CAATEEB</strong> */}
                        </div>
                    </h5>

                </div>
                <div className="d-flex align-items-center" id=''>

                    <Nav.Link onClick={() => {
                        handleOpenModal_Contacto({
                            action: 'EXTRA',
                        })
                    }} className="text-dark">
                        <span className='d-inline-block'>{Lang('CONTACTAR')}</span>
                    </Nav.Link>

                    {
                        process.env.REACT_APP_MULTILANGUAGE === 'true' && inIframe() &&
                        <DropdownLanguage
                            className='me-2'
                        />
                    }

                    <Dropdown align="end">
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faUserCircle} className='me-2' />
                            <span className="me-2 d-none d-lg-inline-block">
                                {nombre} {apellidos}
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='text-end'>
                            <Dropdown.Item onClick={handleShow}>{Lang('CAMBIAR_CONTRASENIA')}</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>{Lang('SALIR')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <Modal show={showModalChangePassword} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className='h5'>{Lang('CAMBIAR_CONTRASENIA')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form className='row'>
                            <Form.Group className="col-12 col-lg-6 mb-3">
                                <InputText
                                    label={Lang('CONTRASENIA_ANTIGUA') + ': *'}
                                    className=''
                                    name='old_password'
                                    type="password"
                                    value={formValuesPassword.old_password}
                                    onChange={handleInputChangePassword}
                                    placeholder="old_password"
                                    validation={valuesValidPassword}
                                    fetchData={fetchPasswordData}
                                />
                            </Form.Group>

                            <div className="col-12"></div>

                            <Form.Group className="col-12 col-lg-6 mb-3">
                                <InputText
                                    label={Lang('CONTRASENIA_NUEVA') + ': *'}
                                    className=''
                                    name='new_password'
                                    type="password"
                                    value={formValuesPassword.new_password}
                                    onChange={handleInputChangePassword}
                                    placeholder="new_password"
                                    validation={valuesValidPassword}
                                    fetchData={fetchPasswordData}
                                />
                            </Form.Group>

                            <Form.Group className="col-12 col-lg-6 mb-3">
                                <InputText
                                    label={Lang('CONFIRMACION_CONTRASENIA_NUEVA') + ': *'}
                                    className=''
                                    name='new_password_confirmation'
                                    type="password"
                                    value={formValuesPassword.new_password_confirmation}
                                    onChange={handleInputChangePassword}
                                    placeholder="new_password_confirmation"
                                    validation={valuesValidPassword}
                                    fetchData={fetchPasswordData}
                                />
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            {Lang('CANCELAR')}
                        </Button>
                        <Button variant="primary" onClick={handleSubmitCreateUser} className='ms-2' disabled={fetchPasswordData.loading}>
                            {
                                fetchPasswordData.loading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className='me-2'
                                    />
                                    : ''
                            }
                            <FontAwesomeIcon icon={faSave} className='me-1' /> {Lang('CAMBIAR')}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </section>
            <ModalStatus setShowStatus={setShowStatusVerify} showStatus={showStatusVerify}>
                <div className="py-3 text-center">
                    <h5 className='mb-0'>{Lang('P_CAMBIADO_CONTRASENIA_CORRECTAMENTE')}</h5>
                </div>
            </ModalStatus>

            {dataModal_Contacto.value &&
                <ModalContacto
                    data={dataModal_Contacto}
                    handleClose={handleCloseModal_Contacto}
                />
            }
        </>

    )
}
