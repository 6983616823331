import React from "react";
import { Form } from "react-bootstrap";
import { InputSelect } from "components/Inputs/InputSelect";
import { useLang } from 'language';

export const TipoTest = ({ formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData,
    parametros, fetchSentData }) => {

    const locale = useLang();

    const tipoTest = {
        profesional: locale('TEST_RADON_PROD_TITULO'),
        personal: locale('TEST_RADON_BASIC_TITULO'),
    };

    return (
        <>
            <fieldset className="border p-2">
                <legend className="w-auto text-left">{tipoTest[formTestRadon.tipo_solicitud]}</legend>
                <div className="row">
                    {formTestRadon.tipo_solicitud === "profesional" && (
                        <>
                            <Form.Group className="mb-2 col-md-6 col-lg-6">
                                <InputSelect
                                    label={locale('TEST_RADON_TIPO_INTERVENCION')}
                                    className=""
                                    disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                    size="sm"
                                    name="tipo_intervencion"
                                    values={
                                        parametros.data?.data?.tipo_intervencion.map((item, index) => {
                                            return {
                                                id: item.param_id,
                                                nombre: item.texto
                                            }
                                        })
                                    }
                                    value={formTestRadon.tipo_intervencion}
                                    onChange={handleInputFormTestRadon}
                                    placeholder="tipo_edificio_id"
                                    fetchData={fetchSentData}
                                //validation={valuesValidNext}
                                />
                            </Form.Group>

                            {formTestRadon.tipo_intervencion === "edificio_existente" && (
                                <Form.Group className="mb-2 col-md-6 col-lg-6">
                                    <InputSelect
                                        label={locale('TEST_RADON_TIPO_AMPLIACION')}
                                        disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                        className=""
                                        name="tipo_ampliacion"
                                        values={
                                            parametros.data?.data?.tipo_ampliacion.map((item, index) => {
                                                return {
                                                    id: item.param_id,
                                                    nombre: item.texto
                                                }
                                            })
                                        }
                                        value={formTestRadon.tipo_ampliacion}
                                        onChange={handleInputFormTestRadon}
                                        placeholder="tipo_edificio_id"
                                        fetchData={fetchSentData}
                                    //validation={valuesValidNext}
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}
                    {formTestRadon.tipo_solicitud === "personal" && (
                        <Form.Group className="mb-2 col-md-6 col-lg-6">
                            <InputSelect
                                label={locale('TEST_RADON_TIPO_MEDICION')}
                                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                                className=""
                                name="tipo_medicio"
                                values={
                                    parametros.data?.data?.tipo_medicion.map((item, index) => {
                                        return {
                                            id: item.param_id,
                                            nombre: item.texto
                                        }
                                    })
                                }
                                value={formTestRadon.tipo_medicio}
                                onChange={handleInputFormTestRadon}
                                placeholder="tipo_medicio"
                                fetchData={fetchSentData}
                            //validation={valuesValidNext}
                            />
                        </Form.Group>
                    )}
                </div>
            </fieldset>
        </>
    );
};
