import React, { useEffect, useRef } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch'
import { useForm } from 'hooks/useForm'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useLang } from 'language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Loader } from 'components/Loader/Loader'
import { ListFiles } from 'components/ListFiles/ListFiles'
import { InputFile } from 'components/Inputs/InputFile'
import { InputText } from 'components/Inputs/InputText'
import { ModalStatusServer } from 'components/ModalStatus/ModalStatusServer'
import { useValidator } from 'hooks/useValidator'

export const ModalDocumento = ({ data, handleClose, fetchSuccess }) => {

    const lng = useLang();

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        solicitud_radon_id: '',
        plano: '',
        // adjunto: '',
        name: '',
    })

    const inputFileFichero = useRef(null)


    const [fetchDocumentoGET, fetchDocumentoGETData] = useFetch('GET', `/plano`)

    const [fetchDocumentoPOST, fetchDocumentoPOSTData] = useFetch('POST', `/plano`, 'json', 'multipart');

    const [fetchDocumentoDELETE, fetchDocumentoDELETEData] = useFetch('DELETE', `/plano`)

    const [validDocumento, handleValidateDocumento] = useValidator({
        name: {
            required: {
                isOk: true,
                msgError: [lng('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
    })

    useEffect(() => {

        console.log(fetchDocumentoGETData.data);

        if (fetchDocumentoGETData.data?.status === 'Success') {
            // if (fetchDocumentoGETData.data?.plano) {

            handleFormDocumento({
                ...fetchDocumentoGETData.data?.data
            })
        }

    }, [fetchDocumentoGETData]);


    const handleSubmitAgregarDocumento = (e) => {

        e.preventDefault()

        if (handleValidateDocumento(formDocumento)) {

            let bodyTemp = {
                ...formDocumento,
                solicitud_radon_id: data.idSolicitud,
                plano: inputFileFichero.current?.files[0],
            }

            console.log(bodyTemp);

            let formData = new FormData();

            for (const key in bodyTemp) {
                formData.append(key, bodyTemp[key])
            }

            fetchDocumentoPOST({
                body: formData
            })
        }

    }

    useEffect(() => {
        if (fetchDocumentoPOSTData.data?.status === 'Success') {

            resetFormDocumento()
            handleClose()
            fetchSuccess()
        }
    }, [fetchDocumentoPOSTData.data])

    useEffect(() => {

        // console.log('data', data);

        if (data?.id) {

            fetchDocumentoGET({
                id: data?.id
            })

        } else {

            resetFormDocumento()
        }
    }, [data.value])


    const handleDeleteAdjunto = () => {

        fetchDocumentoDELETE({
            id: data?.id
        })
    }


    useEffect(() => {
        if (fetchDocumentoDELETEData.data?.status === 'Success') {
            handleClose()
            fetchSuccess()
        }
    }, [fetchDocumentoDELETEData.data])

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='md'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>
                        {lng('PLANO')}
                    </Modal.Title>
                </Modal.Header>

                {
                    fetchDocumentoGETData.loading ?
                        <Loader />
                        :
                        <>
                            <Modal.Body>
                                <div className="container">
                                    <Form onSubmit={handleSubmitAgregarDocumento} className='row'>

                                        <Form.Group className="col-12 col-md-12 col-lg-12 mb-3">
                                            <InputText
                                                label={lng('NOMBRE') + ': *'}
                                                className=''
                                                name='name'
                                                value={formDocumento.name}
                                                onChange={handleInputDocumento}
                                                placeholder={lng('NOMBRE') + ': *'}
                                                validation={validDocumento}
                                                fetchData={fetchDocumentoPOSTData}
                                                disabled={data?.action === 'EDIT'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-12">

                                            {
                                                formDocumento.path ?
                                                    <Form.Group className="mb-3 col-12">
                                                        <Form.Label className='fw-500'>{lng('FICHERO_ADJUNTO')}:</Form.Label>
                                                        <ListFiles
                                                            list={[
                                                                {
                                                                    titulo: lng('PLANO'),
                                                                    url: formDocumento.link,
                                                                }
                                                            ]}
                                                            isBlob={true}
                                                            withToken={true}
                                                        // onClickDelete={() => handleDeleteAdjunto()}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    <Form.Group className="mb-3 col-12">
                                                        <Form.Label className='fw-500'>{lng('FICHERO_ADJUNTO')}:</Form.Label>
                                                        <InputFile
                                                            name='file'
                                                            onChange={handleInputDocumento}
                                                            referencia={inputFileFichero}
                                                        />
                                                    </Form.Group>
                                            }
                                        </Form.Group>

                                    </Form>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='border-0 justify-content-between'>
                                <Button variant="default" onClick={handleClose}>
                                    {lng('TEST_RADON_MODAL_BTN_CERRAR')}
                                </Button>
                                {
                                    data?.id ?
                                        <ButtonSpinner
                                            variant="danger" type="submit" className="ms-2"
                                            handleSubmit={handleDeleteAdjunto} fetchDataLoading={[fetchDocumentoDELETEData.loading]}
                                            value={lng('ELIMINAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                        />
                                        :
                                        <ButtonSpinner
                                            variant="primary-radon" type="submit" className="ms-2"
                                            handleSubmit={handleSubmitAgregarDocumento} fetchDataLoading={[fetchDocumentoPOSTData.loading]}
                                            value={lng('AGREGAR_PLANO')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                        />
                                }
                            </Modal.Footer>

                        </>
                }
            </Modal>

            <ModalStatusServer fetchData={fetchDocumentoPOSTData} />

            <ModalStatusServer fetchData={fetchDocumentoDELETEData} />
        </>
    )
}
