import { faEdit, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Badge, Button, ListGroup } from 'react-bootstrap'
import { useLang } from '../../language'

import './ListFiles.css'
import { AuthContext } from '../../auth/AuthContext'
import { getDataFile } from 'helpers/helpers'

export const ListFiles = ({ list, onClickEdit, onClickDelete, isBlob = true, withToken = false, inline = false }) => {

    const Lang = useLang()

    // const { auth } = useSelector((state) => state.auth)
    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState({ data: null, loading: null, error: null, status: null });

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleFetchFile = (url) => {

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        let config = { responseType: 'blob' };

        if (withToken) {

            config = {
                ...config,
                headers: { Authorization: `Bearer ${token}` }
            }
        }

        axios.get(url, config).then((response) => {
            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    useEffect(() => {

        if (fetchDocumentoData.data) {

            getDataFile(
                fetchDocumentoData,
                setFetchDocumentoData({ data: null, loading: true, error: null, status: null }),
                'File',
                'pdf'
            )
        }
        
    }, [fetchDocumentoData])

    return (
        <ListGroup as="ol" className={`ListFiles ${inline ? 'ListFilesInline' : ''}`}>
            {
                list?.map((item, index) => {
                    return (
                        <ListGroup.Item
                            key={index}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className='flex-grow-1'>
                                <div className='d-flex'>
                                    {
                                        isBlob ?
                                            <div className="fw-bold cursor-pointer hover-text-decoration" onClick={() => handleFetchFile(item.url)}>
                                                <FontAwesomeIcon icon={faFile} className='me-2' />
                                                {item.titulo || Lang('FICHERO_ADJUNTO')}
                                            </div>
                                            :
                                            <a className="text-dark text-decoration-none fw-bold cursor-pointer hover-text-decoration" href={item.url} target='_blank' rel="noreferrer">
                                                <FontAwesomeIcon icon={faFile} className='me-2' />
                                                {item.titulo || Lang('FICHERO_ADJUNTO')}
                                            </a>
                                    }

                                    <small className='ms-auto'>
                                        {
                                            item.etiqueta && <Badge bg="warning" pill text="dark">
                                                {item.etiqueta}
                                            </Badge>
                                        }

                                    </small>
                                </div>

                                {item.descripcion && <small className='mt-2'>{item.descripcion}</small>}

                            </div>
                            <div className='ps-3'>
                                {
                                    onClickEdit && <Button variant='outline-secondary' size='sm' onClick={(e) => onClickEdit(null, item)}>
                                        <FontAwesomeIcon icon={faEdit} className='' />
                                    </Button>
                                }

                                {
                                    onClickDelete && <Button variant='outline-danger' size='sm' onClick={(e) => onClickDelete(null, item)}>
                                        <FontAwesomeIcon icon={faTrash} className='' />
                                    </Button>
                                }

                            </div>
                        </ListGroup.Item>
                    )
                })
            }


        </ListGroup>
    )
}
