import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useFetch } from 'hooks/useFetch'
import { useLang } from 'language'

export const ModalConfirmarEstado = ({ showModalConfirm, handleCloseModal, handleSubmitEstado, loadingData }) => {
    const locale = useLang();
    return (
        <>
            <Modal show={showModalConfirm} onHide={handleCloseModal} size='md' id='ModalEliminarPlanta'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h5 className='text-center'>{locale('TEST_RADON_ESTADO_TITULO')}</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModal}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary-aluminosis" type="submit" className="ms-2"
                        handleSubmit={handleSubmitEstado} fetchDataLoading={[loadingData]}
                        value={locale('TEST_RADON_ESTADO_CONFIRMAR')}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
