import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faFile, faFileAlt} from '@fortawesome/free-solid-svg-icons'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useLang } from 'language';

export const FormHome = (props) => {
	const {changeTab} = props;
    const locale = useLang();

	return(
		<div className="d-flex flex-column w-auto mt-4 mb-5 mx-4">
            <h5 className='mt-0 mb-4 p-3 pb-1 text-center h4'>
               {locale('TEST_RADON_HOME_TITULO')}
            </h5>
            <div className="row justify-content-center">
                

                <div className="col-md-6 col-lg-5 d-flex align-items-stretch">

                    {/*
                    <div className="section-border">
                        <span className="section-text-header-general">{locale('TEST_RADON_PROD_TITULO')}</span>
                        <div className="p-2">
                         <h5 className="text-center mb-2"><FontAwesomeIcon icon={faFileAlt} className='fa-2x mb-3' /></h5>
                         <p>
                           {locale('TEST_RADON_PROD_DESCRIPCION')}
                         </p>
                         <div className="text-center">
                             <OverlayTrigger overlay={<Tooltip id="info">{locale('TEST_RADON_PROD_NOTA')}</Tooltip>}>
                                <span className="d-inline-block text-center">
                                    <Button variant="outline-primary-radon" size='sm' className='ms-3 my-1' onClick={() => {changeTab('profesional')}}>
                                        {locale('TEST_RADON_HOME_SELECCIONAR')} <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                    </Button>
                                </span>
                            </OverlayTrigger>
                          </div>  
                         </div>
                    </div>
                    */}

                    
                    <div className="card shadow-sm p-3 p-lg-4 animate__animated animate__fadeInUp h-100 d-flex flex-column">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faFileAlt} className='fa-2x mb-3' />
                        </div>
                        <h4 className="h4 mb-3 text-center">
                            {locale('TEST_RADON_PROD_TITULO')}
                        </h4>
                        <p className='flex-grow-1'>
                            {locale('TEST_RADON_PROD_DESCRIPCION')}
                        </p>
                        <OverlayTrigger overlay={<Tooltip id="info">{locale('TEST_RADON_PROD_NOTA')}</Tooltip>}>
                            <span className="d-inline-block text-center">
                                <Button variant="outline-primary-radon" size='sm' className='ms-3 my-1' onClick={() => {changeTab('profesional')}}>
                                    {locale('TEST_RADON_HOME_SELECCIONAR')} <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>
                </div>

                <div className="col-md-6 col-lg-5 d-flex align-items-stretch">

                 {/*
                    <div className="section-border">
                        <span className="section-text-header-general">{locale('TEST_RADON_BASIC_TITULO')}</span>
                        <div className="p-2">
                         <h5 className="text-center mb-2"><FontAwesomeIcon icon={faFileAlt} className='fa-2x mb-3' /></h5>
                         <p>
                           {locale('TEST_RADON_BASIC_DESCRIPCION')}
                         </p>
                         <div className="text-center">
                             <OverlayTrigger overlay={<Tooltip id="info">{locale('TEST_RADON_PROD_NOTA')}</Tooltip>}>
                                <span className="d-inline-block text-center">
                                    <Button variant="outline-primary-radon" size='sm' className='ms-3 my-1' onClick={() => {changeTab('personal')}}>
                                        {locale('TEST_RADON_HOME_SELECCIONAR')} <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                    </Button>
                                </span>
                            </OverlayTrigger>
                          </div>  
                         </div>
                    </div>
                    */}

                 
                    <div className="card mb-3 shadow-sm p-3 p-lg-4 animate__animated animate__fadeInUp h-100 d-flex flex-column">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faFileAlt} className='fa-2x mb-3' />
                        </div>
                        <h4 className="h4 mb-3 text-center">
                            {locale('TEST_RADON_BASIC_TITULO')}
                        </h4>
                        <p className='flex-grow-1'>
                            {locale('TEST_RADON_BASIC_DESCRIPCION')}/<span className='text-lowercase'>{locale('EDIFICIO')}</span>
                        </p>
                        <OverlayTrigger overlay={<Tooltip id="info">{locale('TEST_RADON_BASIC_NOTA')}</Tooltip>}>
                            <span className="d-inline-block text-center">
                                <Button variant="outline-primary-radon" size='sm' className='ms-3 my-1' onClick={() => {changeTab('personal')}}>
                                    	{locale('TEST_RADON_HOME_SELECCIONAR')} <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>

                </div>
            </div>
        </div>
	);
}