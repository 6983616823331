import { useLang } from "language";
import React from "react";

export const ModalProfesionalForm = () => {

    return (
        <>
            <ModalProfesionalForm1 />
            <ModalProfesionalForm2 />
            <ModalProfesionalForm3 />
        </>
    )
};

export const ModalProfesionalForm1 = () => {

    const locale = useLang()

    return (
        <div className="row">
            <p>
                {locale('TEST_RADON_PROFESIONAL')}:
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_TEXT_SPAN2')}</span> {locale('P_MEDICION_DISPONIBLE_MESES')}.
            </p>
        </div>
    )
};

export const ModalProfesionalForm2 = () => {

    const locale = useLang()

    return (
        <div className="row d-flex justify-content-center align-items-center">
            <h4 className="text-center mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_TARIFA')}</h4>

            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center">
                    <span className="section-text-header-general">{locale('HOME_RADON_PROFESIONAL_BOX_TITLE')}</span>
                    <div className="p-2">
                        <p className="text-center">
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_PROFESIONAL_BOX_INFO1') }}></div>
                            <br />
                            <div className="Tarifas_content" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_PROFESIONAL_BOX_INFO2') }}></div>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <p className="mb-0 text-center">
                    <small>

                    {locale('CONSULTA_COLEGIOS_CONVENIO')} <span className="link-primary color-primary cursor-pointer text-lowercase">{locale('EN_ESTE_ENLACE')}</span> 
                    </small>
                </p>
            </div>
        </div>
    )
};

export const ModalProfesionalForm3 = () => {

    const locale = useLang()

    return (
        <div className="row">
            <h6 className="text-start mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_CONDICIONES')}:</h6>
            <div className="col-12 col-md-12">
                <ol class="my-list">
                    <li><small>{locale('HOME_RADON_PROFESIONAL_CONDICIONES_INFO1')}</small></li>
                    <li><small>{locale('HOME_RADON_PROFESIONAL_CONDICIONES_INFO2')}</small></li>
                </ol>
            </div>

            <h6 className="text-start mt-3 mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_SERVICIO')}</h6>
            <div className="col-12 col-md-12">
                <ol class="my-list">
                    <li><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO1')}</small></li>
                    <li><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO2')}</small></li>
                    <li><small>{locale('P_RESULTADO_TEST_2')}</small></li>
                    <li><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO5')}</small></li>
                </ol>
            </div>
        </div>
    )
};
